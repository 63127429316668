import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import apiUrl from '../../services/api';

// Async thunk for fetching IP data
export const fetchIPData = createAsyncThunk(
  'ipData/fetchIPData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('https://ipapi.co/json/');
      return response.data; // Return the data if the request is successful
    } catch (error) {
      // Handle error appropriately
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const postIpData=createAsyncThunk('ipData/postIPData',async(data,{rejectWithValue})=>{
    try {
        const response = await axios.post(apiUrl.postIpData, data);
        return response.data; // Response from the POST API
      } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
      }

})




// Create a slice
const ipDataSlice = createSlice({
  name: 'ipData',
  initialState: {
    data: null,
    loading: false,
    error: null,
    postResponse: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIPData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIPData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchIPData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(postIpData.pending, (state) => {
        state.postLoading = true;
        state.postError = null;
      })
      .addCase(postIpData.fulfilled, (state, action) => {
        state.postLoading = false;
        state.postResponse = action.payload;
      })
      .addCase(postIpData.rejected, (state, action) => {
        state.postLoading = false;
        state.postError = action.payload;
      });
  },
});

// Export actions and reducer
export const { clearError } = ipDataSlice.actions;
export default ipDataSlice.reducer;
