import HCaptcha from "@hcaptcha/react-hcaptcha";
import { GoogleLogin } from "@react-oauth/google";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import SecureLS from "secure-ls";
import OtpImg from "../../assets/dashboard/Otp image.png";
import logoVideo from "../../assets/gif/imas.gif";
import {
  googleLogin,
  login,
  loginUser,
  resendOtp,
  verifyOtp,
} from "../../redux/ReduxSlices/UserSlice";
import "../../styles/signup.css";
const ls = new SecureLS();

const LoginPage = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [otp, setOtp] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const savedCredentials = ls.get("credentials");
    if (savedCredentials) {
      setUserName(savedCredentials.userName);
      setPassword(savedCredentials.password);
      setRememberMe(true);
    }
  }, []);


  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!userName.trim()) {
  //     toast.error("Username is required");
  //     return;
  //   }
  //   if (!password.trim()) {
  //     toast.error("Password is required");
  //     return;
  //   }
  //   setToken(null);
  //   if (captchaRef.current) {
  //     captchaRef.current.execute();
  //   } else {
  //     console.error("CAPTCHA reference is null.");
  //   }
  // };



  // const handleLogin = async (captchaToken) => {
  //   if (captchaToken) {
  //     setToken(captchaToken);
  //     try {
  //       const userInfo = await dispatch(
  //         loginUser({ userName, password, captcha_token: token })
  //       ).unwrap();

  //       if (rememberMe) {
  //         ls.set("credentials", { userName, password });
  //       } else {
  //         ls.remove("credentials");
  //       }

  //       localStorage.setItem("accessToken", userInfo.access_token);
  //       localStorage.setItem("refreshToken", userInfo.token);
  //       dispatch(login());

  //       if (!userInfo.user?.otp_verification_status) {
  //         setModalVisible(true);
  //       } else {
  //         toast.success(userInfo.message || "Login successful!");
  //         navigate("/dashboard");
  //       }
  //     } catch (error) {
  //       toast.error(error.message || "Login failed. Please try again.");
  //     }

  //     setToken(null);
  //     if (captchaRef.current) {
  //       captchaRef.current.resetCaptcha();
  //     } else {
  //       console.error("CAPTCHA reference is not set.");
  //     }
  //   } else {
  //     toast.error("CAPTCHA verification failed. Please try again.");
  //   }
  // };


  const handleLogin = async (e) => {
    e.preventDefault();

    // Validate input fields
    if (userName.trim() === "" || password.trim() === "") {
      toast.error("Please fill in both username and password.");
      return;
    }

    try {
      // Dispatch the loginUser action and await the result
      const userInfo = await dispatch(
        loginUser({ userName, password })
      ).unwrap();

      // Handle "Remember Me" functionality
      if (rememberMe) {
        ls.set("credentials", { userName, password });
      } else {
        ls.remove("credentials");
      }

      // Store tokens in localStorage
      localStorage.setItem("accessToken", userInfo.access_token); // Use access token from userInfo
      localStorage.setItem("refreshToken", userInfo.token); // Optionally store refresh token
      dispatch(login()); // Update login state

      // Check user status
      if (!userInfo.user?.otp_verification_status) {
        setModalVisible(true); // Show OTP verification modal
      } else {
        toast.success(userInfo.message || "Login successful!");
        navigate("/dashboard"); // Navigate to the dashboard if user is verified
      }
    } catch (error) {
      console.error("Login failed:", error);
      // toast.error(error.message || "Login failed. Please try again.");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };
  const handleVerifyOtp = () => {
    if (!otp) {
      toast.error("Please enter the OTP.");
      return;
    }

    // Dispatch OTP verification with email and OTP value
    dispatch(verifyOtp({ email: userInfo?.user?.email, otp }))
      .unwrap()
      .then(() => {
        toast.success("OTP Verified Successfully!");
        setModalVisible(false); // Close the OTP modal
        navigate("/dashboard"); // Redirect to sign-in page after verification
      })
      .catch((error) => {
        const errorMessage = error || "OTP verification failed!";
        toast.error(errorMessage);
      });
  };

  const handleResendOtp = () => {
    dispatch(resendOtp(userInfo?.user?.email));
  };

  const handleLoginSuccess = (credentialResponse) => {
    const token = credentialResponse.credential; // Get the token from Google response

    dispatch(googleLogin(token))
      .unwrap()
      .then((response) => {
        // Perform any additional actions if needed after login success
        navigate("/dashboard");
      })
      .catch((error) => {
        // Handle errors appropriately
      });
  };

  const handleLoginFailure = () => {
    toast.error("Google login failed. Please try again.");
  };

  return (
    <div className="flex h-screen bg-black text-white">
      {/* Left Section */}
      <div className="flex-1 flex-col justify-center items-center px-8 hidden md:flex">
        <div className="text-center ">
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            transition={{ duration: 0.3 }}
            className="mb-6 "
          >
            <img src={logoVideo} alt="i made a song" className="w-full h-60" />
          </motion.div>
        </div>
      </div>
      <div className="flex-1 flex justify-center items-center mt-14 relative ">
        <div className="absolute top-5 left-10 w-[200px] h-[200px] sm:w-[250px] sm:h-[250px] md:w-[300px] md:h-[300px] lg:w-[300px] lg:h-[300px] xl:w-[300px] xl:h-[300px] custom-button rounded-full hidden sm:block md:hidden lg:block round-shape"></div>
        <div
          className="w-full max-w-md bg-opacity-80 border-teal-300 shadow-2xl p-8 m-2 rounded-lg h-[610px] z-50 sm:border"
          style={{
            zIndex: 2,
            transform: "translate(0%, 0%)",
            backdropFilter: "blur(10px)",
            boxShadow: "0 0 40px rgba(8, 7, 16, 0.6)",
          }}
        >
          <p className="text-3xl xl:text-3xl font-extrabold text-white text-center ">Login</p>
          <p className="mb-3"></p>
          {/* Form */}
          <form >
            <div className="mb-6">
              <label className="block text-sm font-medium">Username</label>
              <input
                type="text"
                required
                value={userName}

                placeholder="Username"
                className="w-full px-4 py-2 mt-2 bg-gray-800 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                onChange={(e) => setUserName(e.target.value)}
                onKeyDown={handleKeyDown}

              />
            </div>
            <div className="mb-6 relative">
              <label className="block text-sm font-medium">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                required
                value={password}
                placeholder="Password"
                className="w-full px-4 py-2 mt-2 bg-gray-800 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}

              />
              <button
                type="button"
                className="eye-button absolute inset-y-0 right-3 mt-6 flex items-center text-gray-500"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            <div className="flex items-center justify-between mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  id="rememberMe"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="mr-2"
                />
                Remember me
              </label>
              <p
                className="text-white text-sm text-end  cursor-pointer mt-2 underline"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password?
              </p>
            </div>
            {/* <div className="flex items-center justify-center mt-5">
              <HCaptcha
                sitekey={process.env.REACT_APP_HCAPTCHA_SITEKEY} // Replace with your sitekey
                onVerify={handleLogin }
                ref={captchaRef}
                size="invisible"
              />
            </div> */}
            <button className="w-full custom-button text-black font-bold py-2 rounded-md mb-2"
              onClick={handleLogin}
            >
              Login
            </button>
          </form>
          <span className="flex justify-center text-gray-400 mt-2 font-bold">
            OR
          </span>
          <div className="mt-4 w-[100%] flex justify-center">
            <GoogleLogin
              onSuccess={handleLoginSuccess}
              onError={handleLoginFailure}
            />
          </div>

          <div className="flex justify-center space-x-4 mt-4 font-medium">
            Don’t have an account?{" "}
            <Link className="text-cyan-400 hover:underline pl-2" to={"/signup"}>
              Signup
            </Link>
          </div>
          <p className="text-base text-center text-white my-4">
            {" "}
            For sign-in, payment details or card information are not required.
          </p>
          {/* Footer Links */}
          <div className="text-center text-sm text-white">
            <div className="flex justify-center space-x-4 mt-4">
              <Link
                to={"/terms-of-service"}
                className="text-cyan-400 hover:underline"
              >
                Terms of Service
              </Link>
              <Link
                to={"/privacy-policy"}
                className="text-cyan-400 hover:underline"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
        <div
          className="absolute  bg-teal-400 custom-button rounded-full
          round-shape-bottom
          hidden sm:block md:hidden lg:block
          bottom-30 right-20 w-[100px] h-[100px]
          sm:w-[120px] sm:h-[120px]
          md:w-[150px] md:h-[150px]
          lg:w-[180px] lg:h-[180px]
          2xl:bottom-0 2xl:right-38"
        ></div>
      </div>
      <ToastContainer />

      {modalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96 flex flex-col items-center">
            {/* OTP Image */}
            <img
              src={OtpImg} // replace with your actual image path
              alt="OTP Verification"
              className="w-36 h-36 mb-4 object-cover"
            />

            <h2 className="text-2xl font-semibold mb-4">OTP Verification</h2>
            <p className="text-gray-700 mb-4 text-center">
              Enter the OTP sent to your registered email id.
            </p>

            <input
              type="text"
              maxLength="6"
              value={otp}
              onChange={handleOtpChange}
              placeholder="Enter OTP"
              className="w-full px-6 py-3 mb-4 rounded-lg bg-gray-100 text-black border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
            />

            <div className="">
              <button
                class="text-blue-500 font-semibold hover:text-blue-700 mb-2 hover:underline focus:outline-none"
                onClick={handleResendOtp}
              >
                Resend OTP
              </button>
            </div>
            <button
              onClick={handleVerifyOtp}
              className="w-full py-2 rounded-lg upgrade-btn"
            >
              Verify OTP
            </button>

            <button
              onClick={() => setModalVisible(false)}
              className="w-full py-2 mt-3 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-all duration-200"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
