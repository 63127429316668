//  Api Service

// Production
// const baseUrl = "https://musicapifastapi.imadeasong.com";

// Stage
// const baseUrl = "https://prodfastapi.imadeasong.com";
const baseUrl = "https://prodfastapi.imadeasong.com";
// const baseUrl = "http://10.10.19.155:8000"

const BASE_URL_PROD = baseUrl;

const environments = {
  production: BASE_URL_PROD,
};

const activeEnvironment = environments.production;

const apiUrl = {
  // Login
  login: activeEnvironment + "/login",
  UserSignUp: activeEnvironment + "/signup",

  //Logout
  logout: activeEnvironment + "/logout",

  // User
  getUserDetails: activeEnvironment + "/get_user_details",
  userReview: activeEnvironment + "/update_user_status",
  forgotPassword: activeEnvironment + "/forgot_password",
  resetPassword: activeEnvironment + "/reset_password",

  // Credit
  creditBalance: activeEnvironment + "/credit_balance",
  deductCredit: activeEnvironment + "/deduct_credit",

  // Generate song
  generate: activeEnvironment + "/generate",

  //upload audios
  uploadAudio: activeEnvironment + "/upload_audio",

  //Get Collection as per user login
  getCollection: activeEnvironment + "/get_collection",
  generateCustomSong: activeEnvironment + "/custom_generate",
  generateLyrics: activeEnvironment + "/create_lyrics",

  //download audio
  downloadAudio: activeEnvironment + "/download_song",

  // get payment details
  getPaymentDetails: activeEnvironment + "/get-payment-details",
  sendPaymentDetails: activeEnvironment + "/payment",
  createOrder: activeEnvironment + "/create-order",

  //Free trial
  freeTrial: activeEnvironment + "/get_free_trial_count",

  //Deduct free trial
  deductFreeTrial: activeEnvironment + "/deduct_free_trial_count",

  //Verify Otp
  verifyOtp: activeEnvironment + "/verify-registered-otp",

  // Resend Otp
  resendOtp: activeEnvironment + "/resend-registered-otp",
  // Razorpay
  razorpayCheckout: "https://checkout.razorpay.com/v1/checkout.js",

  //Open Ai
  customAi: activeEnvironment + "/fetch_ai_response",

  //Contact Us
  contactUs: activeEnvironment + "/contact-us",

  // Delete Audio
  deleteAudio: activeEnvironment + "/delete_user_songs",

  //Google Login
  googleSocialLogin: activeEnvironment + "/google-login",

  //Audio Watermark id
  watermarkAudio: activeEnvironment + "/create_watermark_audio",

  //Get Stram Blob for Playing song
  getStreamBlob : activeEnvironment + "/get_stream_blob",

  //Generate Slide Show
  generateSlideShow: activeEnvironment + "/generate-slideshow",
  downloadSlideShow: activeEnvironment + "/download_video",

  //Get Payment Details
  getPaymentHistory: activeEnvironment + "/get_payment_history",

  // update user details
  updateUser : activeEnvironment + "/update_user",

postIpData:activeEnvironment+"/add-ip-address",
};

export default apiUrl;
