const languageOptions = {
  hindi: "Hindi",
  english: "English",
  // gujarati: "Gujarati",
  // marathi: "Marathi",
  // tamil: "Tamil",
  // bengali: "Bengali",

  // telugu: "telugu",
  //
  // Kannada: "kannada",
  // punjabi: "punjabi",
  // odia: "Odia",
  // malayalam: "Malayalam",
  // assamese: "Assamese",
  // bhojpuri: "Bhojpuri",
  // spanish: "Spanish",
  // french: "French",
  // german: "German",
  // italian: "Italian",
  // portuguese: "Portuguese",
  // mandarin_chinese: "Mandarin Chinese",
  // cantonese: "Cantonese",
  // japanese: "Japanese",
  // korean: "Korean",
  // russian: "Russian",
  // arabic: "Arabic",
  // turkish: "Turkish",
  // persian_farsi: "Persian (Farsi)",
  // greek: "Greek",
  // dutch: "Dutch",
  // swedish: "Swedish",
  // norwegian: "Norwegian",
  // danish: "Danish",
  // finnish: "Finnish",
  // polish: "Polish",
  // czech: "Czech",
  // hungarian: "Hungarian",
  // thai: "Thai",
  // malay: "Malay",
  // indonesian: "Indonesian",
  // swahili: "Swahili",
  // hebrew: "Hebrew",
  // amharic: "Amharic",
  // zulu: "Zulu",
  // urdu: "Urdu",
  // romanian: "Romanian",
  // ukrainian: "Ukrainian",
  // serbian: "Serbian",
  // croatian: "Croatian",
  // bosnian: "Bosnian",
  // albanian: "Albanian",
  // vietnamese: "Vietnamese",
  // filipino_tagalog: "Filipino/Tagalog",
  // icelandic: "Icelandic",
  // welsh: "Welsh",
  // gaelic_irish_scottish: "Gaelic (Irish and Scottish)",
  // mongolian: "Mongolian",
  // burmese: "Burmese",
  // sinhala: "Sinhala",
};

export const getLanguageOptions = () => {
  return Object.entries(languageOptions).map(([key, value]) => ({
    key,
    value,
  }));
};
