import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import loader from './assets/dashboard/loader.gif';
import ContactPage from './components/ContactPage';
import DefaultPageComponent from './components/DefaultPageComponent';
import Footer from './components/Footer';
import Navbar from './components/Header';
import BottomNavbar from './components/BottomNavbar'; // Import BottomNavbar
import ProtectedRoute from './layout/ProtectedRoute';
import LyricsGenerator from "./pages/custom/LyricsGenerator";
import DashboardPage from './pages/Dashboard/DashboardPage';
import FaqSection from './pages/Faq/FaqSection';
import ForgotPassword from './pages/forgetPassword/ForgotPassword';
import HomePage from './pages/Home/HomePage';
import LoginPage from './pages/Login/LoginPage';
import SignUpPage from './pages/Login/SignUpPage';
import PartnersPage from './pages/Partners/partners';
import PartnersThanksPage from './pages/Partners/partnersThanks';
import PaymentPopup from './pages/Payment/PaymentPopup';
import UserProfilePage from './pages/Profile/UserProfilePage';
import AboutUs from './pages/TermAndCondition/AboutUs';
import Cancellation from "./pages/TermAndCondition/Cancellation";
import PrivacyPolicy from './pages/TermAndCondition/PrivacyPolicy';
import TermsOfService from './pages/TermAndCondition/TermsOfService';
import UpgradePlan from './pages/upgrade_Plans/UpgradePlan';
import GetAllVoice from './pages/VoiceCreation/GetAllVoice';
import GetStartedPage from './pages/VoiceCreation/GetStartedPage';
import SongList from './pages/VoiceCreation/SongList';
import CookieBanner from '../src/components/CookieBanner.jsx';
import CookiePolicy from '../src/pages/TermAndCondition/CookiePolicy.jsx';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Simulate loading state
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Check for mobile device width
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const isPartnersPage = window.location.pathname === "/partners" || window.location.pathname === "/partners-thanks";

  if (isLoading) {
    return (
      <div className='flex items-center justify-center h-screen text-white'>
        <img src={loader} alt="loader" />
      </div>
    );
  }

  return (
    <>
      <Router>
        <CookieBanner />
        {!isPartnersPage && <Navbar />}
        <Routes>
          <Route path='/partners' element={<> <PartnersPage />  </>} />
          <Route path='/partners-thanks' element={<> <PartnersThanksPage />  </>} />
          <Route path='/lyrics' element={<><LyricsGenerator /></>} />
        </Routes>
        : (
        <Routes>
          {/* Index page  */}
          <Route path="/" element={<><HomePage />  <Footer /> </>} />
          {/* Authentication  */}
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/signin" element={<LoginPage />} />

          <Route path="/get-started" element={<ProtectedRoute><GetStartedPage /></ProtectedRoute>} />
          <Route path="/all-voices" element={<ProtectedRoute><GetAllVoice /></ProtectedRoute>} />
          <Route path="/song-list" element={<ProtectedRoute><SongList /></ProtectedRoute>} />

          {/* User Profile  */}
          <Route path="/user-profile" element={<UserProfilePage />} />

          {/* Default page  */}
          <Route path="/default-page" element={<> <DefaultPageComponent /> <Footer /></>} />

          {/* Main Dashboard */}
          <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />

          {/* Subscription */}
          <Route path="/upgrade-plan" element={<ProtectedRoute> <> <UpgradePlan /> <Footer /> </> </ProtectedRoute>} />

          {/* Subscription */}
          <Route path="/payment-checkout" element={<ProtectedRoute> <> <PaymentPopup /> <Footer /> </> </ProtectedRoute>} />

          {/* Privacy policy */}
          <Route path='/cancellation-policy' element={<> <Cancellation />  <Footer /> </>} />
          <Route path="/privacy-policy" element={<> <PrivacyPolicy /> <Footer /> </>} />
          <Route path="/terms-of-service" element={<><TermsOfService /> <Footer /></>} />
          <Route path="/about-us" element={<><AboutUs /> <Footer /></>} />
          <Route path="/cookie-policy" element={<><CookiePolicy /> <Footer /></>} />

          <Route path="/faq" element={<><FaqSection /> <Footer /></>} />

          <Route path='/contact' element={<><ContactPage /> <Footer /></>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
        )
        {isMobile && <BottomNavbar />} {/* Show BottomNavbar only on mobile */}
      </Router>
    </>
  );
};

export default App;
