import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserDefaultImage from "../../assets/image_icons/user.webp";
import { updateProfile, UserPaymentDetails, UserProfileDetails } from "../../redux/ReduxSlices/ProfileSlice";
import { useForm } from "react-hook-form";
import { countryData } from "../../utils/countryCode";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { toast, ToastContainer } from "react-toastify";


const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [countryName, setCountryName] = useState("");
  const [remainingCredits, setRemainingCredits] = useState(0);
  const [lastSubscription, setLastSubscription] = useState("-");
  const [freeTrail, setFreeTrail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allowEditDetails, setAllowEditDetails] = useState(false)
  const [isUpdateModal, setIsUpdateModal] = useState(false)

  const handleSaveChanges = () => {
    // Logic to save changes (e.g., make an API call)
    console.log("Changes saved!");
  };

  const { userDetails, loading, error, paymentDetails, paymentLoading } = useSelector((state) => state.user);
  const dateStr = userDetails.transaction_time;
  // Convert the string to a Date object
  const finalDateTime = dateStr === null ? "-" : moment(dateStr).format('DD-MM-YYYY LTS');

  const dateOfBirth = userDetails.birth_date;

  const formattedDate1 = dateOfBirth === null ? "-" : moment(dateOfBirth).format('DD-MM-YYYY');

  useEffect(() => {
    dispatch(UserProfileDetails())
      .unwrap()
      .catch(() => {
        navigate("/signin");
      });
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(UserPaymentDetails())
  }, [dispatch])

  useEffect(() => {
    // Set state from userDetails once fetched
    if (userDetails) {
      setEmail(userDetails.email);
      setCountryName(
        userDetails.country_name === "" ? "-" : userDetails.country_name
      );
      setRemainingCredits(userDetails.credit_point);
      setLastSubscription(
        finalDateTime === null ? "-" : finalDateTime
      );
      setFreeTrail(userDetails.free_trial_count);
    }
  }, [userDetails]);

  // if (loading)
  //   return (
  //     <div>
  //       <div className="flex items-center justify-center h-screen text-white">
  //         {/* <img src={loader} alt="loader" /> */}
  //       </div>
  //     </div>
  //   );

  // if (error) return <div>{error}</div>;
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      firstName: userDetails?.first_name,
      lastName: userDetails?.last_name,
      mobile: userDetails?.mobile === null ? "-" : userDetails.mobile,
      birthDate: userDetails?.birth_date === null ? "" : moment(userDetails.birth_date).format('DD-MM-YYYY'),
      gender: userDetails?.gender === null ? "-" : userDetails?.gender,
      countryCode: userDetails?.country_code
    },
  });

  register("birthDate", {
    required: "Birth date is required", validate: (value) => {
      const isValidDate = moment(value, "DD-MM-YYYY", true).isValid();
      return isValidDate || "Select valid birthdate";
    }
  });

  const onError = (errors) => {
    Object.values(errors).forEach((error) => {
      toast.error(error.message);
    });
  };

  const updateProfileInfo = (data) => {
    setIsUpdateModal(true)
  }

  const updateProfileDetails = async () => {
    const formDetails = getValues()
    const payload = {
      first_name: formDetails.firstName,
      last_name: formDetails.lastName,
      mobile: formDetails.mobile,
      country_code: formDetails.countryCode,
      birth_date: moment(formDetails.birthDate, "DD-MM-YYYY").format('YYYY-MM-DD'),
      gender: formDetails.gender
    }
    const response = await dispatch(updateProfile(payload))
    if (response) {
      setIsUpdateModal(false)
      setAllowEditDetails(false)
      await dispatch(UserProfileDetails())
      toast.success("Profile updated successfully.")
    }
  }
  const onChange = (date, dateString) => {
    setValue('birthDate', dateString);
  };

  return (
    <form onSubmit={handleSubmit(updateProfileInfo, onError)}>
      <div className="min-h-screen p-4 space-y-8">
        <div className="flex justify-between	items-center	">
          <p className="text-1xl font-semibold text-white">Profile Information</p>
          <div className="flex space-x-2">
            <p className="text-1xl font-semibold text-white">Edit Information</p>
            <svg class="feather feather-edit cursor-pointer" onClick={() => { setAllowEditDetails(true) }} fill="white" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
          </div>
        </div>
        {/* Account Section */}
        <div className="border-teal p-4 rounded-md shadow-lg">
          <div className="flex space-x-2 md:space-x-12 mt-2">
            {/* Left side: Account Information */}
            <div className="flex-1">
              <div>
                <h2 className="text-2xl font-semibold text-white mb-4">
                  Account Details
                </h2>
                <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-6">
                  <img
                    src={UserDefaultImage}
                    alt="Profile"
                    className="w-20 h-20 sm:w-24 sm:h-24 rounded-full object-cover border border-gray-700"
                  />
                  <div className="w-full">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      {/* First Name */}
                      <div className="relative w-full">
                        <input
                          type="text"
                          id="firstName"
                          // onChange={(e) => setFirstName(e.target.value)}
                          readOnly={!allowEditDetails}
                          placeholder=" "
                          autoComplete="off"
                          {...register('firstName', {
                            required: 'First Name is required', pattern: {
                              value: /^[A-Za-z]*$/,
                              message: 'First Name should contain only letters.',
                            },
                          })}
                          className={`w-full p-3 pt-4 rounded-md ${allowEditDetails ? `bg-gray-800` : `bg-black`} text-white focus:outline-none focus:ring-2 focus:ring-teal-900`}
                        />
                        <label
                          className={`absolute left-4 top-0 text-gray-400 transition-all ${watch('firstName') ? "text-xs -top-2" : "text-lg"
                            }`}
                        >
                          First Name
                        </label>
                      </div>

                      {/* Last Name */}
                      <div className="relative w-full">
                        <input
                          type="text"
                          id='lastName'
                          // onChange={(e) => setLastName(e.target.value)}
                          autoComplete="off"
                          readOnly={!allowEditDetails}
                          placeholder=" "
                          {...register('lastName', {
                            required: 'Last Name is required', pattern: {
                              value: /^[A-Za-z]*$/,
                              message: 'Last Name should contain only letters.',
                            },
                          })}
                          className={`w-full p-3 pt-4 rounded-md ${allowEditDetails ? `bg-gray-800` : `bg-black`} text-white focus:outline-none focus:ring-2 focus:ring-teal-900`}
                        />
                        <label
                          className={`absolute left-4 top-0 text-gray-400 transition-all ${watch('lastName') ? "text-xs -top-2" : "text-lg"
                            }`}
                        >
                          Last Name
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right side: Purchase Details */}
            <div className="flex-1">
              <h3 className="text-2xl font-semibold text-white mb-4">
                Purchase Details
              </h3>
              <div className="space-y-4">
                <div className="flex justify-between items-center w-full gap-5">
                  <div
                    className={`bg-black px-6 rounded-md text-white w-full py-4`}
                  >
                    <div className="relative w-full">
                      <p className="text-l font-semibold">
                        {remainingCredits} credits
                      </p>
                      <label
                        className={`absolute left-0 text-gray-400 transition-all text-xs -top-4 hidden sm:block`}
                      >
                        Remaining Credits
                      </label>
                    </div>
                  </div>
                  <div
                    className={`bg-black px-6 rounded-md text-white w-full py-4`}
                  >
                    <div className="relative w-full">
                      <p className="text-l font-semibold">
                        {freeTrail} Free trial
                      </p>
                      <label
                        className={`absolute left-0 text-gray-400 transition-all text-xs -top-4 hidden sm:block`}
                      >
                        Free trial credits
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  className={`bg-black px-6 rounded-md text-white py-4`}
                >
                  <div className="relative w-full">
                    <p className="text-l font-semibold">{lastSubscription}</p>
                    <label
                      className={`absolute left-0 text-gray-400 transition-all text-xs -top-4`}
                    >
                      Last Subscription
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contact Section */}
        <div className="border-teal p-4 rounded-xl shadow-lg ">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Contact Information
          </h2>
          <p className="text-gray-400">
            Manage your contact details for communication.
          </p>

          <div className="mt-6 space-y-4">
            <div className="flex space-x-4">
              <div className="flex items-center relative w-full">
                {allowEditDetails && <select
                  id="countryCode"
                  {...register('countryCode', { required: 'Country code is required' })}
                  // required
                  // id="country_code"
                  // onChange={handleCountryCodeChange}
                  autoComplete="off"
                  className={`p-4  w-1/2 font-medium rounded-l-md ${allowEditDetails ? `bg-gray-800` : `bg-black`} text-white  placeholder-gray-100 focus:outline-none focus:border-gray-400 focus:bg`}
                >
                  <option value="" disabled>
                    Select country code
                  </option>
                  {countryData.map((country, index) => (
                    <option key={index} value={country.code}>
                      {country.name} ({country.code})
                    </option>
                  ))}
                </select>}
                <input
                  type="text"
                  id='mobile'
                  {...register('mobile', {
                    required: 'Mobile is required', pattern: {
                      value: /^\d{10}$/, // Only digits and exactly 10 characters
                      message: 'Enter a valid 10-digit mobile number',
                    },
                  })}
                  readOnly={!allowEditDetails}
                  placeholder="-"
                  autoComplete="off"
                  className={`w-full p-4  ${allowEditDetails ? `bg-gray-800 rounded-r-md` : `bg-black rounded-md`} text-white focus:outline-none focus:ring-2 focus:ring-teal-900`}
                />
                <label
                  className={`absolute left-4 top-0 text-gray-400 transition-all text-xs -top-2`}
                >
                  Mobile
                </label>
              </div>

              <div className="relative w-full">
                <input
                  type="text"
                  value={countryName}
                  onChange={(e) => setCountryName(e.target.value)}
                  readOnly
                  placeholder="Country"
                  className="w-full p-4 rounded-md bg-black text-white focus:outline-none focus:ring-2 focus:ring-teal-900"
                />
                <label
                  className={`absolute left-4 top-0 text-gray-400 transition-all text-xs -top-2`}
                >
                  Country
                </label>
              </div>
            </div>

            <div className="relative w-full">
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly
                placeholder="Email Address"
                className="w-full p-4 rounded-md bg-black text-white focus:outline-none focus:ring-2 focus:ring-teal-900"
              />
              <label
                className={`absolute left-4 top-0 text-gray-400 transition-all text-xs -top-2`}
              >
                Email Address
              </label>
            </div>
          </div>
        </div>

        {/* Birthdate and Gender Section */}
        <div className="border-teal p-4 rounded-xl shadow-lg ">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Birthdate and Gender
          </h2>
          <p className="text-gray-400">
            Update your birthdate and gender information.
          </p>
          <div className="flex justify-center	items-center mt-6 space-x-4">
            <div className="relative w-full">
              {!allowEditDetails ? <input
                type="text"
                id="birthDate"
                // onChange={(e) => setBirthDate(e.target.value)}
                value={watch('birthDate') ? watch('birthDate') : "-"}
                readOnly
                className="w-full p-4 rounded-md bg-black text-white focus:outline-none focus:ring-2 focus:ring-teal-900"
              /> :
                <DatePicker className={`w-full p-4 rounded-md ${allowEditDetails ? `bg-gray-800` : `bg-black`}
          text-white focus:outline-none focus:ring-2 focus:ring-teal-900`}
                  // id="birthDate"
                  onChange={onChange}
                  // onChange={(date) => setValue('birthDate', moment(date).format('DD-MM-YYYY'))}
                  // onChange={onDateChange}
                  format="DD-MM-YYYY"
                  disabledDate={(current) => current && current > dayjs().endOf('day')}
                  // value={watch('birthDate') && dayjs(watch('birthDate')).isValid() ? dayjs(watch('birthDate')) : null}
                  value={watch('birthDate') ? dayjs(watch('birthDate'), "DD-MM-YYYY") : null}
                  popupClassName="custom-popup"
                  variant={"borderless"}
                  placeholder="Select date"
                />}
              <label
                className={`absolute left-4 top-0 text-gray-400 transition-all text-xs -top-2`}
              >
                Date of Birth
              </label>
            </div>

            <div className="relative w-full">
              {!allowEditDetails ? <input
                type="text"
                value={watch('gender')}
                readOnly
                // placeholder="Email Address"
                className="w-full p-4 rounded-md bg-black text-white focus:outline-none focus:ring-2 focus:ring-teal-900"
              /> : <select
                id="gender"
                {...register('gender', { required: 'Gender is required' })}
                // onChange={(e) => setGender(e.target.value)}
                disabled={!allowEditDetails}
                autoComplete="off"
                className={`w-full p-4 rounded-md ${allowEditDetails ? `bg-gray-800` : `bg-black`} text-white focus:outline-none focus:ring-2 focus:ring-teal-900`}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>}
              <label
                htmlFor="gender"
                className={`absolute left-4 top-0 text-gray-400 transition-all text-xs -top-2`}
              >
                Gender
              </label>
            </div>
          </div>
        </div>
        {/* payment history */}
        <div className="border-teal p-4 rounded-xl shadow-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold text-white mb-4">
              Payment History
            </h2>
            {paymentDetails?.length > 0 && (
              <button
                type="button"
                className="text-white underline px-5 py-1 rounded hover:bg-teal-800 "
                onClick={() => setIsModalOpen(true)}
              >
                View all
              </button>
            )}
          </div>

          {paymentDetails?.length > 0 ? (
            <div className="mt-4 space-y-4">
              {paymentDetails.slice(0, 3).map((details, i) => (
                <div
                  key={i}
                  className="bg-black text-white p-4 rounded-lg shadow-md flex flex-col md:flex-row md:items-center justify-between"
                >
                  <div className="flex items-start mb-4 md:mb-0">
                    <div className="text-md font-bold text-white mr-4">{i + 1}.</div>
                    <div>
                      <p className="text-sm text-gray-400">Subscription Plan:</p>
                      <p className="font-semibold">{details.subscription_type}</p>
                    </div>
                  </div>
                  <div className="text-start md:text-left">
                    <p className="text-sm text-gray-400">Amount Paid:</p>
                    <p className="font-semibold">Rs.{details.amount_paid}</p>
                  </div>
                  <div className="text-start md:text-left">
                    <p className="text-sm text-gray-400">Transaction Date:</p>
                    <p className="font-semibold">
                      {moment(details.transaction_time).format("DD-MM-YYYY")}
                    </p>
                  </div>
                  <div className="text-start md:text-left">
                    <p className="text-sm text-gray-400">Payment Method:</p>
                    <p className="font-semibold">{details.payment_method}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full p-4 rounded-md bg-gray-800 text-white text-center">
              <p>No payments details found</p>
            </div>
          )}
        </div>
        {/* Save Changes Button */}
        {allowEditDetails && <div className="text-center mt-8">
          <button
            type="submit"
            className="custom-button px-6 py-3 rounded-full text-lg"
          >
            Save Changes
          </button>
        </div>}

        {isModalOpen && (
          <div className="fixed inset-0 flex justify-center items-center z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.6)] overflow-auto">
            <div className="w-[90%] max-w-[1100px] shadow-2xl rounded-2xl p-6 relative bg-gradient-to-b from-teal-900 via-gray-900 to-black">
              {/* Header */}
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-white text-xl font-semibold">Payment History</h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4 cursor-pointer fill-white hover:fill-red-500"
                  onClick={() => setIsModalOpen(false)}
                  viewBox="0 0 320.591 320.591"
                >
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"></path>
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"></path>
                </svg>
              </div>

              {/* Modal Content */}
              <div className="my-4 max-h-[70vh] overflow-y-auto">
                <div className="w-full p-4 rounded-md text-white">
                  <table className="w-full table-auto bg-black text-white rounded-lg overflow-hidden shadow-md">
                    <thead className="bg-gray-700">
                      <tr>
                        <th className="border border-gray-600 p-3 text-left">Sr. No.</th>
                        <th className="border border-gray-600 p-3 text-left">Subscription Plan</th>
                        <th className="border border-gray-600 p-3 text-left">Amount Paid</th>
                        <th className="border border-gray-600 p-3 text-left">Transaction Date</th>
                        <th className="border border-gray-600 p-3 text-left">Payment Method</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentDetails.map((details, i) => (
                        <tr key={i} className="hover:bg-gray-600">
                          <td className="border border-gray-600 p-3">{i + 1}</td>
                          <td className="border border-gray-600 p-3">{details.subscription_type}</td>
                          <td className="border border-gray-600 p-3">Rs.{details.amount_paid}</td>
                          <td className="border border-gray-600 p-3">
                            {moment(details.transaction_time).format("DD-MM-YYYY")}
                          </td>
                          <td className="border border-gray-600 p-3">{details.payment_method}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        {isUpdateModal && (
          <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
            <div className="w-full max-w-md delete-popup shadow-lg rounded-lg p-6 relative">
              {/* Close Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 cursor-pointer shrink-0 fill-black   hover:fill-red-500 float-right"
                onClick={() => setIsUpdateModal(false)}
                viewBox="0 0 320.591 320.591"
              >
                <path
                  d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                  data-original="#000000"
                ></path>
                <path
                  d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                  data-original="#000000"
                ></path>
              </svg>

              {/* Modal Content */}
              <div className="my-8 text-center">

                <h4 className="text-black text-l font-semibold mt-4">
                  Are you sure you want to update?
                </h4>
              </div>

              {/* Action Buttons */}
              <div className="flex flex-row justify-evenly	items-center sapce-x-2">
                <button
                  type="submit"
                  className="px-4 py-2 rounded-lg text-white text-sm tracking-wide bg-red-500 hover:bg-red-600 active:bg-red-500"
                  onClick={() => updateProfileDetails()}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="px-4 py-2 rounded-lg text-white text-sm tracking-wide bg-red-500 hover:bg-red-600 active:bg-red-500"
                  onClick={() => { setIsUpdateModal(false) }}
                >
                  No
                </button>
                {/* <button
                  type="button"
                  className="px-4 py-2 rounded-lg text-gray-800 text-sm tracking-wide bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button> */}
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </form>
  );
};

export default Settings;
