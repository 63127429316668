
import React from "react";
import { Link } from "react-router-dom";
const PrivacyPolicy = () => {
  return (
    <div className="bg-black min-h-screen px-4 py-12 mt-8">
      <div className="max-w-6xl mx-auto mt-14">
        <h1 className="text-3xl md:text-6xl font-bold text-center text-white mb-12">
          Privacy Policy
        </h1>
        <p className="text-lg text-gray-200 mb-8">
          This Privacy Notice explains how information about you is collected,
          used, shared, stored and secured by Digikore Studios Limited and our
          affiliates and subsidiaries (collectively referred to as "Digikore
          Studios","we","our" and "us") when you use our websites,<Link to={"/"} className="text-blue-600"> https://www.imadeasong.com </Link>mobile applications, products, services and/or
          datasets (collectively, the "Services") or when you otherwise interact
          with us. This Privacy Notice also explains your choices regarding your
          information.{" "}
        </p>
        <h6 className="text-3xl text-start font-bold text-white mb-6">
          Overview
        </h6>
        <p className="text-lg text-gray-200 mb-8">
          Digikore Studios is committed to protecting your privacy and complying
          with all applicable laws in collecting and processing personal
          information.
        </p>
        <p className="text-lg text-gray-200 mb-8">
          This Privacy Notice provides information regarding the following:
        </p>
        <p className="text-lg text-gray-200 mb-8">
          <ul>
            <ol>• What information we collect </ol>
            <ol>• How we use your information </ol>
            <ol>• How we use cookies and similar technologies </ol>
            <ol>• How we share your information</ol>
            <ol>• How we store and secure your information </ol>
            <ol>• How long we retain your information</ol>

            <ol>
              • How you can opt out from receiving communications, or change or
              request deletion of your information{" "}
            </ol>
            <ol>• Payment processing </ol>
            <ol>• Rights of California residents </ol>
            <ol>• Rights of EEA residents </ol>
            <ol>• Other important privacy information </ol>
          </ul>
        </p>
        <p className="text-lg text-gray-200 mb-8">
          Our Services may contain links to other websites operated by third
          parties. Information collected by such third parties is governed by
          their privacy policies which we encourage you to review to learn about
          the privacy practices of those third parties.
        </p>

        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            What Information We Collect
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            We collect information about you when you provide it to us, when you
            use the Services, and/or when other sources provide it to us. We
            collect Contact Information, User Account Information, User Activity
            Information, and Content, as such terms are defined below. The
            specific types of information included in each of these categories
            are described in more detail below.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            1. Information You Provide to Us, including through the Services. We
            collect information about you when you input it into the Services
            (such as completing a web form, creating or accessing your account,
            or using our chat function) or otherwise provide it directly to us.
            For example, we collect information when you submit a request for a
            demonstration or customer support or otherwise communicate with us,
            or when we establish an account for you. The types of information we
            may collect include your contact information or your billing contact
            information (such as your name, physical address  or geographic
            location, email addresses and phone numbers), your employer or other
            company affiliation, your business title, and any other information
            you choose to provide (collectively, "Contact Information"). If we
            establish an account for you for use of paid and/or secured access
            portions of the Services, such as an account established in
            connection with a license to use software platforms and applications
            (collectively, the "Platform"), we also collect your username and
            password for said account, and information about your subscription
            tier and transactions (collectively, "User Account Information").
            Our payment processors also collect payment information to provide
            paid access to the Services, as described further under the heading
            "Payment Processing" below. Finally, if you use our Services to
            record or upload data, music, sound, photographs or other
            Submissions, including to generate and return Output (as such terms
            are defined in our Terms of Service, and collectively, "Content"),
            we collect the data contained and associated with such Content.
            Content may constitute or contain personal information, depending on
            the substance and how it is associated with you and your account.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            2. Information We Collect Automatically When You Use the Services.
            We collect information about you when you use our Services,
            including visiting and browsing our websites, using our mobile
            applications, and taking certain actions within the Services. For
            example, we collect and log information about the features you use,
            your access times, pages viewed, browser type, IP address, Internet
            service provider and/or mobile carrier, URLs of referring and exit pages
            , and information about the computer, phone, tablet or other
            devices you use to access the Services, including hardware model and
            operating system and version. In connection with your use of the
            Services, we log information related to your upload, download,
            processing, access and playback of media and the associated results
            generated by the Platform, frequently used search terms, creation of
            Collections and Watchlists, social interactions such as your “likes”
            and comments, and other actions taken. We collect the information
            about you when you use the Services as described in this section
            above (collectively, "User Activity Information") using various
            technologies, including cookies, as described under the heading "How
            We Use Cookies" below.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            3. Information We Collect From Other Sources. If you use the
            Services on behalf of a company or other organization (such as your
            employer), we may collect information about you from such company or
            organization , in order to establish an account for you and/or provide
            support or other Services to you. We may obtain information about
            you from third-party partners that market and promote our Services,
            generate leads for us, resell our Services, or provide other
            services to us. We also receive information about you from login
            integration partners when you access the Services through a third
            party account such as Discord or Google. We may also obtain
            information about you from other sources, including information from
            publicly and commercially available sources and other third parties,
            as permitted by law. The information we may collect from any of
            these other sources is generally Contact Information.
          </p>
        </div>

        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            How We Use Your Information
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            We use information about you to provide the Services to you, to
            communicate with you in connection with your use of the Services
            and/or requests for information, to market and promote products,
            services and other offerings, to maintain, monitor and secure the
            Services, for legal and compliance purposes, and to develop and
            improve our products and services, as follows:
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <ol>
              <li>
                1. To Provide Services to You. We use your information,
                including Contact Information, User Account Information, User
                Activity Information, and Content, to provide Services to you,
                including to authenticate you when you log in to the Platform
                (or other Services with secured access), to configure features
                of the Services for you, and to operate the Services.
              </li>
              <li>
                2. To Communicate with You. We use your Contact Information and
                User Account Information to communicate with you, including in
                providing support through our telephone, email and chat support
                channels, and in responding to your requests for information,
                questions and comments related to Digikore’s business, products
                and/or services. We may also send you technical notices,
                updates, service notifications, security alerts, support,
                billing and administrative messages in connection with your use
                of the Services.
              </li>
              <li>
                3. To Inform You of Offerings. We use your Contact Information
                and User Activity Information to communicate with you about
                products, services, offers, promotions, rewards, and events
                offered by Digikore and to provide news and information that we
                think will be of interest to you, and to understand how you
                interact with or respond to such communications and
                advertisements. You may opt out from receiving these types of
                communications at any time, as discussed under the heading “Opt
                Out From Receiving Communications” below, and you may restrict
                some of the User Activity Information we may collect by changing
                the settings on your browser, as discussed under the heading
                "Disabling Cookies and Similar Technologies" below.
              </li>
              <li>
                4. To Maintain, Monitor and Secure the Services. We may use
                certain User Account Information, User Activity Information, and
                Content to monitor and analyze trends, usage and activities in
                connection with our Services; to monitor, detect, investigate
                and/or prevent suspicious, fraudulent, unauthorized or illegal
                activity; and to secure the Services and communicate with you
                regarding any security incidents.
              </li>
              <li>
                5. For Legal and Compliance Purposes. We may use Contact
                Information, User Account Information, User Activity
                Information, and Content as reasonably necessary to (a) comply
                with any applicable legal obligation, including any applicable
                law, regulation, legal process or governmental request, and (b)
                enforce our agreements, policies and terms of service, to
                exercise or defend any legal claims, protect us against legal
                liability, or otherwise protect or defend our rights or
                property.
              </li>
              <li>
                6. To Develop and Improve our Products and Services. We may use
                certain User Activity Information, Submissions and other Content
                to improve our Services, product offerings and business,
                including to train and enhance the models that power our
                Services.
              </li>
            </ol>
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            How We Use Cookies and Similar Technologies
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            We use cookies and similar technologies such as pixels, web beacons,
            and software development kits to collect User Activity Information,
            including your browsing activities and preferences, to improve and
            customize your user experience and interactions with us. We or third
            parties may use cookies and similar technologies on the Services for
            the following purposes:
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <ol>
              <li>
                1. <span className="font-bold">Necessary purposes.</span> These
                are cookies and similar technologies that are required for the
                operation of certain Services, including, for example, cookies
                that enable you to log in to secure areas of the Services.{" "}
              </li>
              <li>
                2. <span className="font-bold">Functionality.</span> These
                cookies and similar technologies remember choices you make and
                are used to recognize you when you log in to the Services. This
                enables us to personalize the Services for you, greet you by
                name and remember your preferences (for example, your choice of
                language or region).{" "}
              </li>
              <li>
                3. <span className="font-bold">Analytics.</span> These cookies
                and similar technologies collect information about how you use
                the Services. They allow us to recognize and count the number of
                visitors and to see how visitors move around within the
                Services.
              </li>
              <li>
                4. <span className="font-bold">Targeting or Advertising.</span>{" "}
                These cookies and similar technologies collect information about
                your browsing activities in order to understand your interests
                for marketing purposes. We or third parties may place or
                recognize these on your browser when you visit certain websites
                for the purpose of serving you with advertisements regarding our
                products and services that may be of interest to you. These may
                also be used for frequency capping purposes (e.g., to ensure we
                do not display the same advertisement to you repeatedly) and to
                help us regulate the advertisements you receive and measure
                their effectiveness. We also use these technologies in our
                advertisements, email newsletters and/or other communications to
                collect information such as whether you clicked on an
                advertisement, opened our communication or clicked on any of the
                content contained therein. Analytical, targeting and advertising
                cookies, and the User Activity Information generated from these
                cookies, are created, stored and/or managed by third-party
                service providers that provide us with web traffic analytics,
                sales engagement and marketing automation services, such as
                Google Analytics
              </li>
            </ol>
          </p>
        </div>

        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Disabling Cookies and Similar Technologies
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            By using the Services and allowing cookies and similar technologies
            to be placed by us, you consent to our use of these technologies and
            the User Activity Information generated from them. You can withdraw
            or modify your consent by changing the settings on your browser.
            However, if you choose to disable this function, your experience
            using the Services may be diminished and some features may not work
            as intended. Although your browser may allow you to transmit a “Do
            Not Track” or “opt-out preference” signal, like many websites, our
            website is not designed to respond to such signals, and we do not
            use or disclose your information in any way that would legally
            require us to recognize opt-out preference signals.
          </p>
        </div>

        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            How We Share Your Information
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            We may share information about you as discussed below. We do not
            sell your information to third parties.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <ol>
              <li>
                1. With Other Users. In connection with your use of certain
                features of the Services, such as certain sharing and/or
                collaboration features, we may share information about you (such
                as your name and email address) and your Content with such other
                persons, as part of your use of the Services or as otherwise
                directed by you through your use of relevant features.{" "}
              </li>
              <li>
                2. With Service Providers. We work with third parties that
                provide to us engine , application development and/or
                processing services; information systems hosting,
                infrastructure, maintenance, backup, storage, security, support
                and other information technology-related services; advertising,
                marketing and related analytics services; promotion,
                distribution, resale, support, and other services related to our products; consulting and other technical and/or
                business services, which may require access to information about
                you to perform such services on our behalf. We only share your 	information with these service providers for our business purposes, pursuant to written contracts. Our contracts with these
                third-party service providers require protection of your
                information consistent with this Privacy Notice, and require
                that they retain, use and disclose your information only as
                necessary to provide the services in accordance with such
                contracts.{" "}
              </li>
              <li>
                3. With Your Consent. We share information about you with third
                parties when you give us consent or direct us to do so – for
                example, if you have agreed to be a reference for Digikore or
                have provided a testimonial regarding our Services, or use login
                integrations provided by third parties such as Discord and
                Google.{" "}
              </li>
              <li>
                4. For Legal and Compliance Purposes. We may share information
                about you with a third party if we believe that sharing such
                information is reasonably necessary to (a) comply with any
                applicable legal obligation, including applicable law,
                regulation, legal process or governmental request, including to
                meet national security requirements, (b) enforce our agreements,
                policies and terms of service, or to exercise or defend any
                legal claims, (c) protect the security or integrity of our
                products and services, and (d) protect Digikore, our customers,
                employees , or other persons from harm, legal liability or illegal
                activities. We may also share your information in connection
                with, or during negotiations of, any merger, sale of all or a
                portion of our business or assets, bankruptcy, or similar
                business transaction.{" "}
              </li>
            </ol>
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            How We Store and Secure Your Information
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            Digikore Studios has implemented and maintains technical and
            organizational security measures and other safeguards reasonably
            necessary to ensure the security, confidentiality and integrity of
            your information, including measures to protect against unauthorized
            access to, destruction, modification, disclosure or use of your
            information, as appropriate based on the nature of the information.
            We permit persons working for us to access and use your information
            only to serve our legitimate business purposes, in accordance with
            this Privacy Notice.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            Digikore Studios is based in India, and we generally store your
            information on servers located in India. We also conduct business in
            other countries, directly or through one of our subsidiaries or
            partners, and may transfer your information to and/or store it in
            such other locations as reasonably necessary to provide the Services
            or for other legitimate business purposes, in accordance with this
            Privacy Notice. While we follow generally accepted standards to
            protect your information, no method of storage or transmission is
            100% secure. You are solely responsible for protecting your
            password, limiting access to your devices and signing out of
            websites after your sessions.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            How Long We Retain Your Information
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            We retain your information for as long as is reasonably necessary
            for the purposes specified in this Privacy Notice. When determining
            the length of time to retain information, we consider various
            criteria, including whether we need the information to continue to
            administer your account, provide the Services, resolve a dispute,
            enforce our contractual agreements, prevent harm, promote safety,
            security and integrity, or protect ourselves, including our rights,
            property or products.
          </p>
        </div>

        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            How You Can Opt Out From Receiving Communications, Change Or Request
            Deletion Of Your Information
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            You may choose the types of communications you wish to receive from
            us, change your information or request that we delete certain
            information at any time, as follows:
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <ol>
              <li>
                1. Opt Out From Receiving Communications. If you do not wish to
                receive communications from Digikore that do not relate directly
                to your use of the Services (such as newsletters, event
                notifications or other marketing communications), you may opt
                out from receiving such communications at any time. We provide a
                link in all of our electronic marketing communications to allow
                you to easily "unsubscribe" to these communications. You may
                also notify us that you do not wish to receive further
                communications by contacting us at{" "}
                <a
                  className="text-blue-600"
                  href="mailto: support@imadeasong.com"
                >
                  support@imadeasong.com
                </a>
                . Please note that, in connection with your use of certain
                Services, including the Platform, we will still need to send you
                certain notifications that relate directly to your use of the
                Services, such as technical notices, updates, service
                notifications, security alerts, billing and administrative
                messages, and will need to communicate with you to provide you
                with support.
              </li>
              <li>
                2. Change Your Information. You may change or update your
                information with us at any time. To change or update your
                information, please contact us at{" "}
                <a
                  className="text-blue-600"
                  href="mailto: support@imadeasong.com"
                >
                  support@imadeasong.com
                </a>{" "}
                or contact your primary Digikore support representative.
                However, if your information has been provided to us by your
                employer or other company for which we have provisioned your
                account, updates to certain information on your account must be
                requested by such company.{" "}
              </li>
              <li>
                3. Delete Your Information. You may request that we delete your
                information at any time, subject to certain exceptions as
                discussed below. You may request to have your information
                deleted by contacting us by email at{" "}
                <a
                  className="text-blue-600"
                  href="mailto: support@imadeasong.com"
                >
                  support@imadeasong.com
                </a>
                . We will comply with your request within a reasonable period of
                time; however, in certain circumstances, we may not be able or
                required to delete all of your information. For example, if we
                have provisioned a user account for you and you intend to
                continue to use portions of the Services requiring access
                through said account, we must retain certain information in
                order to maintain your account and your access to the Services.
                We may also retain certain information if required in connection
                with our performance of a contract (such as a license agreement
                between us and your employer), if required by law or relevant to
                a legal proceeding, or for other compliance or auditing
                purposes, as permitted by law. Please note that we may obtain
                information about you in the future, as discussed under the
                heading "Information We Collect From Other Sources," and we may
                not be able to recognize it as your information due to complying
                with a previous deletion request from you. In this case, you
                will need to submit a new request if you wish to have your
                re-added information deleted. In addition, if we e have
                deidentified, anonymized or aggregated data such that it no
                longer identifies or relates to you, we may retain and use that
                information for any lawful purpose.{" "}
              </li>
            </ol>
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Payment Processing
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            We utilize third-party service providers that act as resellers of,
            and/or payment processors for the fees payable for, licenses to
            certain of our Services and/or content that may be purchased through
            our Services (each, a "Payment Processor"). In order to purchase
            such Services through our Services, you will be required to provide
            credit/debit card and/or financial institution account information
            directly to the Payment Processor. Such information is not collected
            or stored by us.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Rights of U.S. Residents
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            This section applies if you are a resident of the State of
            California, Colorado or another U.S. state that has passed a privacy
            law similar to the California Consumer Privacy Act (“CCPA”) and
            requires specific privacy notice disclosures. For purposes of this
            section, references to “personal information” shall also include
            “sensitive personal information” as those terms are defined under
            the CCPA.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            Depending on where you live, you may have certain other rights
            regarding your personal information (in addition to those discussed
            elsewhere in this Privacy Notice), including:
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <ol>
              <li>
                1. Notice of Information Collected and Used. We collect, use and
                disclose, and over the past 12 months have collected, used and
                disclosed the following categories of personal information as
                described further in this Privacy Notice under the headings
                "What Information We Collect", "How We Use Your Information" and
                "How we Share Your Information:" identifiers, personal
                information listed in the California Customer Records statute,
                commercial information, Internet and similar network activity
                information, geolocation information, visual and audio
                information that you upload to the Services, professional or
                employment-related information, and account access credentials.*
                We only use and disclose sensitive personal information for the
                purposes specified in the CCPA or otherwise in line with your
                consent. We may disclose your personal information as described
                in this Privacy Notice under the heading "How We Share Your
                Information," but we do not "sell" or "share" your personal
                information, as those terms are defined in the CCPA and similar
                U.S. state privacy laws, nor have we done so in the preceding 12
                months. Further, we do not have actual knowledge that we sell or
                share personal information of California residents under 16
                years of age.{" "}
              </li>
              <li>
                2. Right to Know Requests. You may have a right to request (i)
                the categories and specific pieces of personal information about
                you that we have collected, (ii) the categories of sources from
                which such information was collected, (iii) the business or
                commercial purposes for which such information was collected and
                (iv) the categories of third parties with which such information
                is disclosed and the business purposes for such disclosure.{" "}
              </li>
              <li>
                3. Right to Delete Requests. You may have a right to request
                that we delete personal information we maintain about you.{" "}
              </li>
              <li>
                4. Right to Correct Requests. You may have a right to request
                that we correct inaccurate personal information we maintain
                about you.{" "}
              </li>
              <li>
                5. Right to Request Deletion of Visible Content. California
                residents under the age of 18 who have registered to use the
                Services and who posted Content that is visible to other users
                can request that such Content be removed from the Services. Such
                request must state that they personally made such Content
                available to other users and detail where such Content is
                posted. We will make reasonable good faith efforts to remove the
                Content from prospective public view or anonymize it so the
                minor cannot be individually identified in relation to such
                Content. This removal process cannot ensure complete or
                comprehensive removal. For instance, third parties may have
                republished the Content and archived copies of it may be stored
                by search engines and others that we do not control.{" "}
              </li>
            </ol>
          </p>
          <p className="text-lg text-gray-200 mb-8">
            You may submit a request to exercise any of these rights by
            contacting us by email at{" "}
            <a className="text-blue-600" href="mailto: support@imadeasong.com">
              support@imadeasong.com
            </a>
            . We will not discriminate against you for exercising these rights.
            However, we will need to verify your request by asking you to
            provide at least two pieces of personal information (e.g., full name
            and email address) as reasonably necessary to properly identify you.
            You may also designate, in writing or through a power of attorney
            document, an authorized agent to make requests on your behalf to
            exercise your rights. Before accepting such a request from an agent,
            we will require that the agent provide proof you have authorized
            them to act on your behalf, and we may need you to verify your
            identity directly with us. Upon receipt of a verifiable request, we
            will respond to such request as required by, and subject to certain
            restrictions and exemptions under, applicable laws. If we deny your
            request, you may appeal our decision by contacting us by email at{" "}
            <a className="text-blue-600" href="mailto: support@imadeasong.com">
              support@imadeasong.com
            </a>
            .
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Rights of EEA Residents
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            If you are a resident of the European Economic Area (EEA), you have
            certain other rights regarding our processing of your personal data
            (in addition to those discussed elsewhere in this Privacy Notice),
            in accordance with European data protection laws, including the
            General Data Protection Regulation, and subject to certain
            restrictions and exemptions under such laws. These rights include,
            among others: (1) the right to be informed of the specifics
            regarding your personal data, including what data is being
            processed, the purposes for such processing, the third parties with
            which we share your data, the sources from which we obtain your
            data, and the period of time for which your personal data will be
            stored; (2) the right to receive a copy of your information that we
            process; and (3) the right to ask that we provide your data in an
            easily readable format to another company. EEA residents may contact
            us online by email at{" "}
            <a className="text-blue-600" href="mailto: support@imadeasong.com">
              support@imadeasong.com
            </a>{" "}
            to request such information or data.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Other Important Privacy Information
          </h6>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Digikore Studios acting as a Data Processor
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            In connection with the use of certain Services by our customers or
            other parties, such as under license agreements pertaining to use of
            our Platform and related Services, our customers or other parties
            and their designated end users may upload, process, store and/or
            share data through the Services (or may provide data to us through
            other means for processing), the contents of which are generally not
            known to us but may contain personal information. In these
            circumstances, we are acting as a "data processor" or "service
            provider". We process such data in accordance with the terms and
            conditions of our written agreements with, and any related
            instructions received from, such customers and other parties. This
            Privacy Notice does not apply to our processing of information as a
            data processor/service provider.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Use by Children
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            Our Services are not intended to be used by individuals under the
            age of 18, and we do not knowingly collect personal information from
            children under 18. If we become aware that a child under 18 has
            provided us with personal information, we will take steps to delete
            such information. If you become aware that a child has provided us
            with personal information, please contact us at{" "}
            <a className="text-blue-600" href="mailto: support@imadeasong.com">
              support@imadeasong.com
            </a>
            .
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Changes to our Privacy Notice
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            We may change this Privacy Notice from time to time. We will post
            any Privacy Notice changes on this page, with a revised date at the
            top of this page. If the changes are material, we will provide a
            more prominent notice by adding a notice on the homepages of our
            websites or login screens for the Services, or by sending you an
            email notification. We encourage you to review the Privacy Notice
            whenever you access the Services to stay informed about our data
            privacy practices and your choices regarding your information.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            If you disagree with this Privacy Notice and/or any changes to this notice
            {/* hereto, */}
            you will need to stop using the Services.
          </p>
          <p className="text-3xl text-start font-bold text-white mb-6">Contact</p>
          <p className="text-lg text-gray-200 mb-8">
            If you have any questions or concerns regarding this Privacy Notice,
            please email us at{" "}
            <a className="text-blue-600" href="mailto: support@imadeasong.com">
              support@imadeasong.com
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
