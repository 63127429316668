import React from 'react';

const HowToUsePlatform = ({ onClose }) => {

  return (
    <div className="p-2  h-[66vh] rounded-lg ">
      <div className="relative w-full">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 text-white hover:text-white bg-black rounded-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="w-full">


        <h1 className="text-base sm:text-xl text-white font-bold mb-4 mt-4">How to Use the "I Made a Song" Platform 🎶</h1>

        <div className="mb-6 text-white">
          <h2 className="text-md font-semibold text-white mb-2">Create meaningful melodies for any occasion in just a few steps!</h2>


          <div className="mb-6 text-white">
          <h2 className="text-md text-white font-semibold mb-2">1. Specify the Occasion</h2>
          <p>🎉 Make your song truly unique by tailoring it to the celebration.</p>
          <ul className="list-disc pl-5 mt-2 text-white">
            <li>Options include Anniversary, Birthdays, Express Love personalized events.</li>
            <li>This step helps craft lyrics and melodies to match the theme.</li>

            <p> 💡 Example: For a Anniversary, your song could include themes of love, togetherness, and promises</p>

          </ul>
        </div>
        <div className="mb-6 text-white">
          <h2 className="text-md font-semibold mb-2">2. Select the Language</h2>
          <p>🌍 Pick the language that resonates with your message.</p>
          <ul className="list-disc pl-5 mt-2 text-white">
            <li>Choose from languages like English, Hindi.</li>
            <li>
              The lyrics or song’s theme will align with the language you select.
            </li>
            <p>💡 Example: A heartfelt Hindi song can add a cultural touch to an emotional family moment.</p>

          </ul>
        </div>
          <h2 className="text-md font-semibold text-white mb-2">3. Choose Your Music Style</h2>
          <p>🎵 Start by setting the tone of your song.</p>
          <ul className="list-disc pl-5 mt-2 text-white">
            <li>Select a genre from the dropdown menu, such as Pop, peppy, disco.</li>
            <li>This choice will define the rhythm, beat, and overall vibe of your song.</li>
            <p>💡 Example: A Pop song will sound upbeat and energetic, perfect for celebrations like birthdays or weddings.</p>
          </ul>
        </div>




        <div className="mb-6 text-white">
          <h2 className="text-md font-semibold mb-2">4. Capture the Emotion</h2>
          <p>💖 Decide how the song should make people feel.</p>
          <ul className="list-disc pl-5 mt-2 ">
            <li>Choose emotional tones like Romantic, Thankful, Sentiment, or Heartfelt.</li>


          </ul>
        </div>
        {/* <div className="mb-6">
          <h2 className="text-md font-semibold mb-2">5. Include Instruments</h2>
          <p>🎻 Personalise your sound with instrument choices.</p>
          <ul className="list-disc pl-5 mt-2 text-gray-900">
            <li>Add a touch of Guitar, Piano, or any other instrument to highlight your song’s style.</li>
            <li>Each instrument contributes uniquely to the overall composition.</li>

          </ul>
        </div> */}
        <div className="mb-6 text-white">
          <h2 className="text-md font-semibold mb-2">5. Select the Voice Type</h2>
          <p>🎤 Choose the perfect voice to bring your song to life.</p>
          <ul className="list-disc pl-5 mt-2 ">
            <li>Decide between Male or Female vocals, depending on the tone and feel.</li>
            <li>Vocal style can also influence how the song connects emotionally.</li>

          </ul>
        </div>
        <div className="mb-6 text-white">
          <h2 className="text-md font-semibold mb-2">6. Generate Your Song</h2>
          <p>✨ Watch your creation come to life!</p>
          <ul className="list-disc pl-5 mt-2 ">
            <li>Once all your choices are made, click the "Generate" button.</li>
            <li>The platform will craft a customised song based on your preferences.</li>
          </ul>
        </div>

        <div className="mb-6 mt-5">
          <p className='mb-6 p-1 text-white'>💡 Note: If you’re unsure about your selections, feel free to revisit and tweak any step before generating the final song.</p>
        </div>
      </div>
    </div>
  );
};

export default HowToUsePlatform;
