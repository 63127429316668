import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import apiUrl from "../../services/api";


export const UserProfileDetails = createAsyncThunk(
  "profile/get_user_details",
  async (_, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(apiUrl.getUserDetails, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      var userName = response.data.first_name;

      localStorage.setItem('userName', userName);

      return response.data;

    } catch (error) {
      console.error("Error fetching captcha:", error.message);

      if (!error.response) {
        console.error("Network error or server is unreachable");
        return rejectWithValue("Network error or server is unreachable");
      }

      const navigate = useNavigate();

      navigate("/signin");

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProfile= createAsyncThunk(
  "credits/update_user",
  async ( payload , { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(apiUrl.updateUser,payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        
      });

      return true;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error updating profile data"
      );
    }
  }
);

export const UserPaymentDetails = createAsyncThunk(
  "profile/get_payment_details",
  async (_, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(apiUrl.getPaymentHistory, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;

    } catch (error) {
      console.error("Error fetching captcha:", error.message);

      if (!error.response) {
        console.error("Network error or server is unreachable");
        return rejectWithValue("Network error or server is unreachable");
      }

    }
  }
);

const initialState = {
  userDetails: null,
  paymentDetails: [],
  loading: false,
  paymentLoading: false,
  error: null,
  updateStatus:''
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(UserProfileDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(UserProfileDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.userDetails = action.payload;
    });

    builder.addCase(UserProfileDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(UserPaymentDetails.pending, (state) => {
      state.paymentLoading = true;
      state.error = null;
    });

    builder.addCase(UserPaymentDetails.fulfilled, (state, action) => {
      state.paymentLoading = false;
      state.paymentDetails = action.payload;
    });

    builder.addCase(UserPaymentDetails.rejected, (state, action) => {
      state.paymentLoading = false;
      state.error = action.payload;
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.updateStatus = "loading";
      state.error = null;
    });

    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.updateStatus = 'success';
      state.paymentDetails = action.payload;
    });

    builder.addCase(updateProfile.rejected, (state, action) => {
      state.updateStatus = 'failed';
      state.error = action.payload;
    });
  },
});

export default userSlice.reducer;
