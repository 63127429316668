import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import PageLoader from "../../components/PageLoader.jsx";
import { FaPlayCircle, FaStopCircle } from "react-icons/fa";
import {
  downloadVideo
} from "../../redux/ReduxSlices/slideShowSlice";
import apiUrl from "../../services/api";

const VideoCollage = () => {
  const [images, setImages] = useState([]);
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isNull, setIsNull] = useState(false);
  const [videoURL, setVideoURL] = useState(null);
  const [selectedAspectRatio, setSelectedAspectRatio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadFileName, setDownloadFileName] = useState(null)
  const audioRef = useRef(null);
  const dispatch = useDispatch();

  const { data, isLoading, error } = useSelector((state) => state.slideshow);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    const validFiles = files.filter((file) => {

      const validTypes = ["image/png", "image/webp", "image/jpeg"];
      if (!validTypes.includes(file.type)) {
        toast.info(`${file.name} is not a valid image file (PNG, WebP, JPEG).`);
        return false;
      }
      if (file.size > 5 * 1024 * 1024) {
        toast.info(`${file.name} exceeds the 5 MB size limit.`);
        return false;
      }
      return true;
    });

    // Ensure no more than 7 images
    if (images.length + validFiles.length > 20) {
      toast.info("You can only select up to 20 images.");
      return;
    }

    // Add valid files to the list
    setImages((prevImages) => [...prevImages, ...validFiles]);
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };


  const handleAudioChange = (e) => {
    const file = e.target.files[0];
    setDownloadFileName(file.name)
    if (file) {
      if (audioRef.current && !audioRef.current.paused) {
        audioRef.current.pause();
        setIsPlaying(false); // Reset the play/pause button state
      }

      if (file.type !== "audio/mpeg") {
        toast.info("Only MP3 files are allowed.");
        return;
      }
      if (file.size > 10 * 1024 * 1024) {
        toast.info("Audio size must be under 10 MB.");
        return;
      }

      setAudio(file); // Set the new audio
      if (audioRef.current) {
        audioRef.current.load();
      }
      setIsPlaying(false);
    }
  };


  const clearAudio = () => {
    setAudio(null); // Clears the selected audio file
    setIsPlaying(false); // Resets the play/pause state
    if (audioRef.current) {
      audioRef.current.pause(); // Pauses the audio if it's playing
      audioRef.current.currentTime = 0; // Resets the audio playback to the beginning
    }
  };

  // const handleSubmit = (e) => {
  //     e.preventDefault();
  //     if (images.length === 0 || !audio) {
  //         toast.info("Please select at least one image and an audio file.");
  //         return;
  //     }

  //     const formData = new FormData();
  //     formData.append("images", images);
  //     formData.append("audio", audio);
  //     console.log("check karo")
  //     dispatch(generateSlideshow(formData));
  //     // const formData = new FormData();
  //     // images.forEach((image, index) => formData.append(`image_${index}`, image));
  //     // formData.append("audio", audio);

  //     // dispatch({
  //     //     type: "SEND_COLLAGE_DATA",
  //     //     payload: formData,
  //     // });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if images are selected
    if (!images || images.length === 0) {
      return toast.error("Please select at least one image.");
    }

    // Check if audio is selected
    if (!audio) {
      return toast.error("Please select an audio file.");
    }

    // Check if aspect ratio is selected
    if (!selectedAspectRatio) {
      return toast.error("Please select an aspect ratio.");
    }

    const MAX_TOTAL_SIZE = 100 * 1024 * 1024; // 100 MB
    let totalSize = 0;

    // Validate file sizes
    images.forEach((image) => (totalSize += image.size));
    totalSize += audio.size;

    if (totalSize > MAX_TOTAL_SIZE) {
      return toast.error("Total file size exceeds the limit of 100 MB.");
    }

    setImages([]);
    clearAudio();

    // Create FormData
    const formData = new FormData();
    images.forEach((image) => {
      formData.append("images", image);
    });
    formData.append("audio", audio);
    formData.append("aspect_ratio", selectedAspectRatio);

    // Set loading to true
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(apiUrl.generateSlideShow, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {

        throw new Error("Failed to upload files.");
      }

      const data = await response.json();
      console.log(data, "response from generate slideshow");

      const watermarkedVideo = data.watermarked_video;
      const videoURL1 = `https://stgmusicaifastapi.imadeasong.com${watermarkedVideo}`;
      setVideoURL(videoURL1);

      toast.success("Upload successful!");
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred during upload.");
    } finally {
      // Set loading to false after API call
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    if (!videoURL) {
      toast.error("No video URL available to download.");
      return;
    }

    const watermarkedVideoUrl = videoURL.split("/").pop();
    const payload = { watermarked_video_url: watermarkedVideoUrl };

    try {
      setLoading(true)
      const response = await dispatch(downloadVideo(payload)).unwrap();
      const fullVideoPath = `https://stgmusicaifastapi.imadeasong.com${response.original_video_path}`;

      const videoResponse = await fetch(fullVideoPath);
      if (!videoResponse.ok) {
        throw new Error("Failed to fetch video.");
      }
      const blob = await videoResponse.blob();

      const link = document.createElement("a");
      const blobUrl = URL.createObjectURL(blob);
      link.href = blobUrl;
      link.download = downloadFileName;
      console.log(link.download, "link.download");

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Revoke the blob URL to free memory
      URL.revokeObjectURL(blobUrl);
      toast.success("Video downloaded successfully!");
      setVideoURL(null)
    } catch (error) {
      console.error("Download error:", error);
      toast.error(error.message || "Failed to download video.");
    } finally {
      setLoading(false);
    }
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play();
        setIsPlaying(true);
      } else {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleStop = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  };

  return (
    <div className="min-h-screen text-white p-4">
      <div className="max-w-8xl mx-auto flex flex-col md:flex-row">
        {loading && <PageLoader />}
        {/* Form Section */}
        <div className="flex-1 space-y-4">
          <h1 className="text-2xl font-bold mb-4">Video Collage</h1>

          {/* Image Picker */}
          <div>
            <label className="block text-sm font-medium mb-1">
              Select Images (PNG, WebP, JPEG, Max 5 MB each, Max 7 images)
            </label>
            <input
              type="file"
              accept="image/png, image/webp, image/jpeg"
              multiple
              onChange={handleImageChange}
              className="block w-full text-sm text-gray-300 bg-gray-800 border border-gray-600 rounded-lg file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-gray-700 file:text-gray-200 hover:file:bg-gray-600 mt-2 lg:mt-6"
            />
            {images.length !== 0 ? (
              <p className="mt-4 text-ellipsis text-green-400">
                You have {images.length} Images
              </p>
            ) : (
              <div className="mt-4"></div>
            )}
          </div>

          {/* Audio Picker */}
          <div>
            <label className="block text-gray-400 text-sm font-medium mb-1 mt-1 lg:mt-2">
              First, download the song from "All Collection."
            </label>
            <label className="block text-sm font-medium mb-1 mt-3 lg:mt-6">
              Select an Audio File (MP3 Only, Max 10 MB)
            </label>
            <input
              type="file"
              accept="audio/mpeg"
              onChange={handleAudioChange}
              className="block w-full text-sm text-gray-300 bg-gray-800 border border-gray-600 rounded-lg file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-gray-700 file:text-gray-200 hover:file:bg-gray-600 mt-2 lg:mt-4"
            />
          </div>

          <div className="mb-2">
            <h2 className="text-sm mb-4 font-medium">Aspect ratio</h2>
            <div className="flex gap-3">
              <button
                onClick={() => setSelectedAspectRatio("9:16")}
                className={`h-10 w-[122px] rounded-lg flex items-center justify-center ${selectedAspectRatio === "9:16"
                  ? "border-teal-200 border-2"
                  : "bg-gray-800 border border-gray-600"
                  }`}
              >
                <div className="h-[16px] w-[8px] rounded-sm border "></div>
                <p className="px-1"> 9:16</p>
              </button>
              <button
                onClick={() => setSelectedAspectRatio("16:9")}
                className={`h-10 w-[122px] rounded-lg flex items-center justify-center ${selectedAspectRatio === "16:9"
                  ? "border-teal-200 border-2"
                  : "bg-gray-800 border border-gray-600"
                  }`}
              >
                <div className="h-[8px] w-[16px] rounded-sm border"></div>
                <p className="px-1"> 16:9</p>
              </button>
              <button
                onClick={() => setSelectedAspectRatio("1:1")}
                className={`h-10 w-[122px] rounded-lg flex items-center justify-center ${selectedAspectRatio === "1:1"
                  ? "border-teal-200 border-2"
                  : "bg-gray-800 border border-gray-600"
                  }`}
              >
                <div className="h-[14px] w-[14px] rounded-sm border"></div>
                <p className="px-1">1:1</p>
              </button>
            </div>
          </div>

          <div className="flex justify-start items-center">
            <button
              onClick={handleSubmit}
              className="w-full sm:w-[100%] md:w-[50%] text-lg custom-button text-white font-bold py-2 px-4 rounded-lg mt-2 lg:mt-7"
            >
              Submit
            </button>
          </div>
        </div>

        {/* Preview Section */}
        <div className="flex-1 mt-6 md:mt-0 md:ml-8">
          {images.length !== 0 ? (
            <div>
              <h2 className="text-lg font-semibold mb-4">
                Selected Images ({images.length})
              </h2>
              <div className="p-4 relative w-full h-40 sm:h-48 lg:h-56 overflow-x-auto scrollbar-hide rounded-lg border border-gray-600">
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-6">
                  {images.map((image, index) => (
                    <div key={index} className="relative">
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Preview ${index + 1}`}
                        className="w-full h-24 object-cover rounded-lg"
                      />
                      <button
                        onClick={() => handleRemoveImage(index)}
                        className="absolute top-0 right-0 bg-red-600 text-white p-1 text-xs"
                      >
                        ✕
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {/* Audio Section */}
          {audio && (
            <div className="mt-6">
              <h3 className="text-lg font-semibold mb-4">Test Audio</h3>
              <div className="flex items-center space-x-4 ">
                <button
                  onClick={handlePlayPause}
                  className="text-white py-2 px-4 border-radius-2 rounded-lg flex items-center gap-2"
                >
                  <FaPlayCircle className="text-white" /> Play
                </button>
                <button
                  onClick={handleStop}
                  className="text-white py-2 px-4 border-radius-2 rounded-lg flex items-center gap-2"
                >
                  <FaStopCircle className="text-white" /> Stop
                </button>
              </div>
              <audio ref={audioRef} src={audio && URL.createObjectURL(audio)} />
            </div>
          )}
        </div>
      </div>
      <div class="divider mt-8 my-4"></div>

      <div className="mt-4">
        {/* <p className="text-2xl font-bold text-center">Generated Video</p> */}
        {videoURL ? (
          <div className="flex flex-col md:flex-row justify-center items-center md:space-x-8">
            {/* Display the video */}
            <video controls autoPlay className="w-full md:w-[50%] mt-4 radius-8 video-border">
              <source src={videoURL} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            {/* Download Button */}
            <div className="mt-4 md:mt-0 text-center">
              <p className="text-center mb-7"> * Download a video without watermark </p>
              <button
                onClick={handleDownload}
                className="w-full md:w-auto text-base custom-button text-white font-bold py-2 px-14 rounded-lg"
                disabled={isLoading} // Disable button during API call
              >
                {isLoading ? "Downloading..." : "Download Video"}
              </button>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center min-h-[20vh] sm:min-h-[30vh]">
            <p className="text-base text-gray-400 text-center">No video available, Please select images and audio first</p>
          </div>
        )}
      </div>


      <ToastContainer />
    </div>
  );
};

export default VideoCollage;
