// redux/voicesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for fetching voices
export const fetchVoices = createAsyncThunk(
  'voices/fetchVoices',
  async (_, { rejectWithValue }) => {

    var baseUrl = process.env.REACT_APP_MUSIC_STAGE;

    try {
      const response = await axios.get(`${baseUrl}/api/get`, {
        // const response = await axios.get("/api/get", {
        method: "GET",
        credentials: "include",
      });

      if (response.status === 200) {
        return response.data; // Return data if the request was successful
      } else {
        return rejectWithValue(response.statusText); // Return an error message if not 200
      }
    } catch (error) {
      return rejectWithValue(error.message); // Handle errors
    }
  }
);

// Slice for managing voices state
const voicesSlice = createSlice({
  name: 'voices',
  initialState: {
    voices: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVoices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVoices.fulfilled, (state, action) => {
        state.voices = action.payload;
        state.loading = false;
      })
      .addCase(fetchVoices.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default voicesSlice.reducer;
