const occasionOption = {
  birthday: "Birthday",
  // wedding: "Wedding",
  anniversary: "Anniversary",
  Express_love: "Express Love",
  // proposal: "Proposal",
  // congratulations: "Congratulations",
  // thank_you: "Thank you",
  // thankmother: "Thank you mother",
  // thank_father: "Thank you father",
  // thank_son: "Thank you son",
  // thank_daughter: "Thank you daughter",
  // thank_Friend: "Thank you friend",
  // thank_boss: "Thank you boss",
  // thank_teacher: "Thank you teacher",


  // graduation: "Graduation",
  // christmas: "Christmas",
  // new_year_eve: "New Year",
  // valentine_day: "Valentine's Day",
  // mother_day: "Mother's Day",
  // father_day: "Father's Day",
  // thanksgiving: "Thanksgiving",
  // easter: "Easter",
  // independence_day: "Independence Day",
  // diwali: "Diwali",
  // holi: "Holi",
  // eid: "Eid",
  // st_Patrick_Day: "St. Patrick’s Day",
  // corporate_event: "Corporate Event",
  // charity_event: "Charity Event",
  // family_reunion: "Family Reunion",
  // baby_shower: "Baby Shower",
  // album_release: "Album Release",
  // concert_tour: "Concert Tour",
  // art_exhibition: "Art Exhibition",
  // book_launch: "Book Launch",
  // film_premiere: "Film Premiere",
  // homecoming: "Homecoming",
  // new_business_launch: "New Business Launch",
  // housewarming_party: "Housewarming Party",
  // holiday_season: "Holiday Season",
};

export const getOccassionOption = () => {
  return Object.entries(occasionOption).map(([key, value]) => ({
    key,
    value,
  }));
};
