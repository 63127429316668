import React, { useEffect, useState } from "react";
import ceoImg from "../../assets/dashboard/abhishek_more (1).webp";

function AboutUs() {
  const [visibleParagraphs, setVisibleParagraphs] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    // Show each paragraph with a 1-second delay
    visibleParagraphs.forEach((_, index) => {
      setTimeout(() => {
        setVisibleParagraphs((prev) => {
          const newVisible = [...prev];
          newVisible[index] = true;
          return newVisible;
        });
      }, index * 800); // Delay each paragraph by 1 second
    });
  }, []);
  return (
    <>
      {/* </div> */}

      <div className="bg-black min-h-screen px-4 py-12">
        <div className="max-w-6xl mx-auto mt-20">
          <div className="text-center">
            <h1 className="text-4xl md:text-6xl font-bold text-center text-white mb-12">
              About Us
            </h1>
            <p className="text-xl text-white mb-8 font-bold">
              Discover the story of Digikore Studios Limited and our
              revolutionary approach to AI-driven music creation.
            </p>
          </div>

          <div>
            <p className="text-lg text-white mb-8">
              I Made a Song is owned and operated by Digikore Studios Limited.
              At Digikore Studios, we are redefining the future of music with
              cutting-edge technology and innovation. As pioneers in AI-driven
              music solutions, we are building the world’s first-of-its-kind AI
              music platform, where artistry meets artificial intelligence to
              create transformative musical experiences.
            </p>

            <p className="text-lg text-white mb-8">
              With a foundation in advanced technology, Digikore Studios
              combines deep expertise in artificial intelligence, machine
              learning, and sound engineering to push the boundaries of what
              music can be. Our team of seasoned engineers, musicians, and AI
              experts work collaboratively to design a platform that empowers
              creators, artists, and listeners to explore new realms of musical
              creativity.
            </p>

            <p className="text-lg text-white mb-8">
              We understand the profound impact music has on people, and our
              platform is engineered to make music creation accessible, dynamic
              and endlessly innovative. Whether you are an artist seeking unique
              compositions or a listener craving immersive experiences, Digikore
              Studios is here to elevate your journey with state-of-the-art AI
              technology.
            </p>

            <p className="text-lg text-white mb-8">
              At Digikore, our commitment is not only to stay at the forefront
              of AI in music but to pioneer a new era where technology and
              creativity harmonize seamlessly. Join us as we shape the future of
              sound, setting a new standard in the world of digital music.
            </p>
          </div>
        </div>

        {/* <section className="ceo-banner-bg mt-10 w-full">
          <div className="mx-auto justify-center items-center ">
            <div className="text-center">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 items-center justify-center">
                <div className="md:w-2/4 my-5 mx-auto ">
                  <img
                    src={ceoImg}
                    alt="Mission-Image"
                    className="rounded-[148px_23px_18px_5px] w-full md:w-auto h-auto max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg mx-auto"
                  />
                </div>

                <div className="text-left mt-5 px-4">
                  <h2 className="text-2xl font-normal text-[#b88e2f]">
                    Introduce yourself to our CEO
                  </h2>
                  <h1 className="text-3xl md:text-4xl font-bold text-white mb-2 mt-1">
                    Mr. Abhishek More
                  </h1>

                  {visibleParagraphs[0] && (
                    <p className="text-sm md:text-base text-gray-300">
                      As the CEO of Digikore Studios, I bring over three decades
                      of invaluable experience in the media and AI industries to
                      the forefront.
                    </p>
                  )}
                  {visibleParagraphs[1] && (
                    <p className="text-sm md:text-base text-gray-300">
                      With a rich background spanning over 30 years, I have
                      established myself as a visionary leader, deeply involved
                      in the integration of AI in media production and
                      technological advancements.
                    </p>
                  )}
                  {visibleParagraphs[2] && (
                    <p className="text-sm md:text-base text-gray-300">
                      My passion for innovation and commitment to excellence
                      drives Digikore Studios to explore new frontiers in AI,
                      revolutionizing the way content is created and
                      experienced.
                    </p>
                  )}
                  {visibleParagraphs[3] && (
                    <p className="text-sm md:text-base text-gray-300">
                      My leadership ensures that Digikore Studios remains at the
                      cutting edge of AI technology, delivering exceptional
                      creativity and quality to clients worldwide.
                    </p>
                  )}

                  <div
                    id="thank-you"
                    className="text-lg text-gray-300 mt-2 mb-2"
                  >
                    Thank You.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </>
  );
}

export default AboutUs;
