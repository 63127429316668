const genderOptions = {
    male: "Male Voice",
    female: "Female Voice",
};

export const getGenderOptions = () => {
    return Object.entries(genderOptions).map(([key, value]) => ({
        key,
        value,
    }));
};
