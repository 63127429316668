import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import AlertFail from '../../reusable/AlertFail';
import AlertSuccess from '../../reusable/AlertSuccess';
import apiUrl from '../../services/api';

function PaymentForm({ selectedPlan, closeModalSubscription }) {

    const navigate = useNavigate();
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [dateTime, setDateTime] = useState(new Date());

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDateTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);


    useEffect(() => {
        const loadRazorpayScript = () => {
            const script = document.createElement("script");
            script.src = apiUrl.razorpayCheckout;
            script.async = true;
            document.body.appendChild(script);
        };
        loadRazorpayScript();
    }, []);

    useEffect(() => {
        if (paymentStatus) {
            const timer = setTimeout(() => setPaymentStatus(null), 4000);
            return () => clearTimeout(timer);
        }
    }, [paymentStatus]);


    function getCurrentDateTime() {
        const now = new Date();

        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');

        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const currentDateTime = getCurrentDateTime();

    const handleSendPaymentDetails = async (response) => {
        const cardHolderName = document.getElementById('cardholderName').value;
        const token = localStorage.getItem("token");

        const finalPayload = {
            ...response, // Include all fields from the original response
            subscription_type: selectedPlan.planName,
            transaction_time: currentDateTime ?? '2024-11-10 10:00:00',
            payment_method: response.method ?? "Credit Card",
            card_holder_name: cardHolderName,
            payment_status: "Captured",
            transaction_mode: "Online",
            discount_applied: false,
            razorpay_payment_id: response.id,
            acquirer_data: {
                rrn: response.acquirer_data?.rrn || "", // Use empty string if rrn is undefined
                upi_transaction_id: response.acquirer_data?.upi_transaction_id || "", // Use empty string if upi_transaction_id is undefined
            },
            notes: [{ username: response.notes.username ?? 'unknown username' }],

        };


        try {
            setIsLoading(true);
            await axios.post(apiUrl.sendPaymentDetails, finalPayload, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            setPaymentStatus("success");

            setTimeout(() => {
                closeModalSubscription();
                navigate('/dashboard');
            }, 1200);

        } catch (error) {
            console.error("Error sending payment data:", error);
            setPaymentStatus("failure");

            toast.error("An error occurred while processing your payment data. Please retry.");
        } finally {
            setIsLoading(false); // Hide loader
        }
    };


    const handlePaymentSuccess = async (response) => {

        const token = localStorage.getItem("token");

        const payload = {
            payment_id: response.razorpay_payment_id,
        }

        try {
            setIsLoading(true);
            await axios.post(apiUrl.getPaymentDetails, payload, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }).then(async (res) => {

                await handleSendPaymentDetails(res.data);
            });

        } catch (error) {
            console.error("Error sending payment data:", error);
            setPaymentStatus("failure");
            toast.error("An error occurred while processing your payment data. Please retry");
        } finally {
            setIsLoading(false); // Hide loader
        }
    };


    // First call 
    const handleRazorPayOrder = async (c) => {

        c.preventDefault();

        const token = localStorage.getItem("token");
        const amountInINR = selectedPlan.monthlyPrice;
        // const amountInINR = 1;
        const amountInPaise = amountInINR * 100;

        const payload = {
            "amount": amountInPaise,
            "currency": "INR"
        }

        try {
            await axios.post(apiUrl.createOrder, payload, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }).then(async (data) => {

                await handlePayment(data);
            });

        } catch (error) {
            console.error("Error sending payment data:", error);
            setPaymentStatus("failure");
            toast.error("An error occurred while processing your payment data. Please retry");
        }
    };

    const handlePayment = async (e) => {

        if (!e.data.data || !e.data.data.id) {
            toast.error("Oops... Something went wrong. Please try again.");
            return;
        }

        var rzId = e.data.data.id;

        const name = document.getElementById('cardholderName').value;
        const phone = document.getElementById('phoneNumber').value;
        const email = document.getElementById('email').value;

        const nameRegex = /^[a-zA-Z\s]+$/;
        if (!name || !nameRegex.test(name)) {
            toast.error("Cardholder Name must contain only characters.");
            return;
        }

        const phoneRegex = /^[0-9]{10}$/;
        if (!phone || !phoneRegex.test(phone)) {
            toast.error("Phone Number must contain 10 digits.");
            return;
        }

        const emailRegex = /\S+@\S+\.\S+/;
        if (!email || !emailRegex.test(email)) {
            toast.error("Please enter a valid Email Address.");
            return;
        }

        const amountInINR = selectedPlan.monthlyPrice;
        // const amountInINR = 1;
        const amountInPaise = amountInINR * 100;

        const options = {
            key: process.env.REACT_APP_RAZORPAY_LIVE_KEY,
            // key: process.env.REACT_APP_RAZORPAY_TEST_KEY,
            amount: amountInPaise,
            currency: "INR",
            order_id: rzId,
            name: "Digikore Studio Limited",
            description: "Transaction from i made a song",
            handler: async function (response) {

                await handlePaymentSuccess(response);
            },
            prefill: {
                name: name,
                email: email,
                contact: phone,
            },
            theme: {
                color: "#57D0C1"
            },
            modal: {
                ondismiss: function () {
                    setPaymentStatus("failure");
                }
            },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };



    // const handlePayment = async (e) => {

    //     console.log('Here is id :');
    //     console.log(e.data);
    //     console.log(e.data.id);

    //     const name = document.getElementById('cardholderName').value;
    //     const phone = document.getElementById('phoneNumber').value;
    //     const email = document.getElementById('email').value;

    //     const nameRegex = /^[a-zA-Z\s]+$/;
    //     if (!name || !nameRegex.test(name)) {
    //         toast.error("Cardholder Name must contain only characters.");
    //         return;
    //     }

    //     const phoneRegex = /^[0-9]{10}$/;
    //     if (!phone || !phoneRegex.test(phone)) {
    //         toast.error("Phone Number must contain 10 digits.");
    //         return;
    //     }

    //     const emailRegex = /\S+@\S+\.\S+/;
    //     if (!email || !emailRegex.test(email)) {
    //         toast.error("Please enter a valid Email Address.");
    //         return;
    //     }

    //     const amountInINR = selectedPlan.monthlyPrice;
    //     // const amountInINR = 1;
    //     const amountInPaise = amountInINR * 100;

    //     const options = {
    //         key: process.env.REACT_APP_RAZORPAY_LIVE_KEY,
    //         // key: process.env.REACT_APP_RAZORPAY_TEST_KEY,
    //         amount: amountInPaise,
    //         currency: "INR",
    //         order_id: e.data.id,
    //         name: "Digikore Studio Limited",
    //         description: "Transaction from i made a song",
    //         handler: async function (response) {

    //             console.log('Inside function');
    //             console.log(response);

    //             await handlePaymentSuccess(response);

    //         },
    //         prefill: {
    //             name: name,
    //             email: email,
    //             contact: phone,
    //         },
    //         theme: {
    //             color: "#57D0C1"
    //         },
    //         modal: {
    //             ondismiss: function () {
    //                 setPaymentStatus("failure");
    //             }
    //         },

    //     };

    //     const rzp1 = new window.Razorpay(options);
    //     rzp1.open();

    // };

    const closeModal = () => setPaymentStatus(null);

    return (
        <div>
            <form className="flex flex-col mt-4 max-w-full rounded-none w-[357px]" onSubmit={handleRazorPayOrder}>
                {/* <form className="flex flex-col mt-4 max-w-full rounded-none w-[357px]" > */}
                <label htmlFor="cardholderName" className="self-start text-sm leading-tight text-white">
                    Customer Name <span className="text-red-500">*</span>
                </label>
                <input
                    id="cardholderName"
                    type="text"
                    className="gap-2 p-3.5 mt-2 text-sm leading-snug rounded-lg border border-solid bg-zinc-800 border-zinc-700 text-zinc-200"
                />
                <label htmlFor="phoneNumber" className="self-start mt-4 text-sm leading-tight text-white">
                    Phone Number <span className="text-red-500">*</span>
                </label>
                <input
                    id="phoneNumber"
                    type="text"
                    className="gap-2 p-3.5 mt-2 text-sm leading-snug rounded-lg border border-solid bg-zinc-800 border-zinc-700 text-zinc-200"
                />
                <label htmlFor="email" className="self-start text-sm mt-4 leading-tight text-white">
                    Email Address <span className="text-red-500">*</span>
                </label>
                <input
                    id="email"
                    type="text"
                    className="gap-2 p-3.5 mt-2 text-sm leading-snug rounded-lg border border-solid bg-zinc-800 border-zinc-700 text-zinc-200"
                />
                <button
                    type="submit"
                    className="mt-4 lg:mt-12 p-3.5 font-semibold bg-teal-300 rounded-lg"
                >
                    Pay Now
                </button>
            </form>

            {paymentStatus && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                    {paymentStatus === "success" ? (
                        <AlertSuccess />
                    ) : (
                        <AlertFail />
                    )}
                </div>
            )}

            {isLoading && <div className="loader mt-4 text-lg ">Please wait we are processing...</div>}

            <ToastContainer />
        </div>
    );
}

export default PaymentForm;


