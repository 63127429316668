export const plansRegular = [
  {
    // 04
    name: "Creation of upto 04 songs",
    yearlyPrice: "Free",
    monthlyPrice: "Free",
    planName: "Song Plan 0",
    features: [
      "Create upto 4 unique songs",
      "Choose any song genre or style",
      "No commercial use allowed",
      "No download allowed",
    ],
    status: "Active",
  },
  {
    // 10
    name: "Creation of upto 10 songs",
    yearlyPrice: "Rs. 399",
    monthlyPrice: "399",
    planName: "Song Plan 399",
    features: [
      "Create upto 10 unique songs",
      // 'Create 1 video',
      "Download all 10 songs as mp3",
      // 'Download video as mp4',
      "No commercial use allowed",
      "No monthly recurring charges",
      "1 month validity",
    ],
  },
  {
    // 20
    name: "Creation of upto 20 songs",
    yearlyPrice: "Rs. 749",
    monthlyPrice: "749",
    planName: "Song Plan 749",
    features: [
      "Create upto 20 unique songs",
      // 'Create 5 videos',
      "Download all 20 songs as mp3",
      // 'Download all 5 videos as mp4',
      "No commercial use allowed",
      "No monthly recurring charges",
      "2 months validity",
    ],
  },
  {
    // 50
    name: "Creation of upto 50 songs",
    yearlyPrice: "Rs. 1,799",
    monthlyPrice: "1799",
    planName: "Song Plan 1799",
    features: [
      "Create upto 50 unique songs",
      // 'Create 15 videos',
      "Download all 50 songs as mp3",
      // 'Download all 15 videos as mp4',
      "No commercial use allowed",
      "No monthly recurring charges",
      "3 months validity",
    ],
  },
  {
    // 100
    name: "Creation of upto 100 songs",
    yearlyPrice: "Rs. 3,999",
    monthlyPrice: "3999",
    planName: "Song Plan 3999",
    features: [
      "Create upto 100 unique songs",
      // 'Create 40 videos',
      "Download all 100 songs as mp3",
      // 'Download all 40 videos as mp4',
      "No commercial use allowed",
      "No monthly recurring charges",
      "6 months validity",
    ],
  },
];
