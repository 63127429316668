import React from 'react';
import { useNavigate } from 'react-router-dom';

const BottomNavbar = () => {
    const navigate = useNavigate();

    return (
        <div className="fixed bottom-0 left-0 w-full text-white shadow-md flex justify-around py-1 bottom-sheet">
            <button
                onClick={() => navigate('/')}
                className="flex flex-col items-center group"
            >
                <span className="material-icons mt-3 text-xl text-gray-400 group-hover:text-teal-500 transition duration-200">Home</span>
            </button>
            <button
                onClick={() => navigate('/dashboard')}
                className="flex flex-col items-center group"
            >
                <div className="bg-blue-500 py-2 px-4 mt-2 mb-2 rounded-full shadow-lg bottom-sheet-btn transition duration-200">
                    <span className="material-icons text-white text-base">Create</span>
                </div>
            </button>
            <button
                onClick={() => navigate('/upgrade-plan')}
                className="flex flex-col items-center group"
            >
                <span className="material-icons text-xl mt-3 text-gray-400 group-hover:text-teal-500 transition duration-200">Pricing</span>

            </button>
        </div >
    );
};

export default BottomNavbar;