import React, { useEffect, useRef, useState } from "react";
import { AiFillPauseCircle, AiFillPlayCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import songImage from "../../assets/dashboard/music.webp";
import PageLoader from "../../components/PageLoader.jsx";

function SongList({ songs, setPlayingVoiceId, handlePlayPause, isPlaying, setIsPlaying, setSelectedLyrics, setIsVisibleLyrics }) {
  const [currentAudio, setCurrentAudio] = useState(null);
  // const [isPlaying, setIsPlaying] = useState(false);
  const [activeSongId, setActiveSongId] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const { collectionData } = useSelector((state) => state.uploadAudio);
  const containerRef = useRef(null);
  const dispatch = useDispatch();

  // const handlePlay = async (songId) => {
  //   try {
  //     // Display loader while the API is processing
  //     setShowLoading(true);

  //     // Function to handle playing audio with fallback support
  //     const playAudio = (audioUrl, fallbackUrl = null) => {
  //       const audio = new Audio(audioUrl);

  //       audio
  //         .play()
  //         .then(() => {
  //           setCurrentAudio(audio);
  //           setIsPlaying(true);
  //           setActiveSongId(audioUrl);

  //           // Add event listener to handle audio end
  //           audio.addEventListener("ended", () => {
  //             setIsPlaying(false);
  //             setActiveSongId(null);
  //           });
  //         })
  //         .catch((error) => {
  //           console.error(`Error playing audio from ${audioUrl}:`, error);

  //           // Attempt fallback URL if provided
  //           if (fallbackUrl) {
  //             playAudio(fallbackUrl);
  //           }
  //         });
  //     };

  //     // Check free trial count
  //     if (freeTrailData.free_trial_count <= 0) {
  //       // Handle pause for the active song if free trials are exhausted
  //       if (
  //         activeSongId ===
  //         `${process.env.REACT_APP_AI}${songId}${process.env.REACT_APP_EXTENSION}`
  //       ) {
  //         if (currentAudio) {
  //           currentAudio.pause();
  //         }
  //         setIsPlaying(false);
  //         setActiveSongId(null);
  //         return;
  //       }

  //       // Play audio with fallback support
  //       const primaryUrl = `${process.env.REACT_APP_AI}${songId}${process.env.REACT_APP_EXTENSION}`;
  //       const fallbackUrl = `${process.env.REACT_APP_FALLBACK}item_id=${songId}`;
  //       playAudio(primaryUrl, fallbackUrl);
  //       return;
  //     }

  //     // Toggle play/pause for the active song
  //     if (
  //       activeSongId ===
  //       `${process.env.REACT_APP_AI}${songId}${process.env.REACT_APP_EXTENSION}`
  //     ) {
  //       if (currentAudio) {
  //         if (currentAudio.paused) {
  //           currentAudio
  //             .play()
  //             .then(() => setIsPlaying(true))
  //             .catch((error) => console.error("Error resuming audio:", error));
  //         } else {
  //           currentAudio.pause();
  //           setIsPlaying(false);
  //         }
  //       }
  //       return; // Exit early
  //     }

  //     // Pause any currently playing audio
  //     if (currentAudio) {
  //       currentAudio.pause();
  //       setCurrentAudio(null);
  //       setActiveSongId(null);
  //       setIsPlaying(false);
  //     }

  //     // Fetch and play new audio
  //     const audioUrl = await dispatch(createWatermarkAudio(songId)).unwrap();
  //     const audio = new Audio(audioUrl);

  //     audio
  //       .play()
  //       .then(() => {
  //         setCurrentAudio(audio);
  //         setActiveSongId(
  //           `${process.env.REACT_APP_AI}${songId}${process.env.REACT_APP_EXTENSION}`
  //         );
  //         setIsPlaying(true);

  //         // Add event listener to handle audio end
  //         audio.addEventListener("ended", () => {
  //           setIsPlaying(false);
  //           setActiveSongId(null);
  //         });
  //       })
  //       .catch((error) => console.error("Error playing audio:", error));
  //   } catch (error) {
  //     console.error("Error handling play:", error);
  //   } finally {
  //     // Hide loader after the operation completes
  //     setShowLoading(false);
  //   }
  // };



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        if (currentAudio) {
          currentAudio.pause();
          setIsPlaying(false);
          setActiveSongId(null);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentAudio]);

  useEffect(() => {
    const stopAudio = () => {
      if (currentAudio) {
        currentAudio.pause();
      }
    };

    window.onbeforeunload = stopAudio;

    return () => {
      stopAudio();
      window.onbeforeunload = null;
    };
  }, [currentAudio]);

  const handleNewSongPlay = (song) => {
    setActiveSongId(song.id)
    handlePlayPause(song)
  }
  return (
    <>
      {collectionData.length === 0 ? (
        <div></div>
      ) : (
        <div
          className="flex-row gap-8 generated-card flex justify-center"
          ref={containerRef}
        >
          {songs?.map((song) => (
            <div
              key={song.id}
              className="flex generate-song-bg flex-row w-40 mt-4 gap-16 p-4 mx-2 rounded-xl  hover:shadow-xl transition-all duration-300 sm:w-56 md:w-72 lg:w-80"
              onClick={() => {
                setSelectedLyrics({
                  title: song.title,
                  lyric: song.lyric,
                });
                setIsVisibleLyrics(true)
              }}
            >
              <div className="flex justify-center h-20 items-center">
                <img
                  loading="lazy"
                  src={songImage}
                  alt={song.title}
                  className="object-contain rounded-full shadow-sm aspect-square w-32 sm:w-20 lg:w-20 generate-song-height"
                />
              </div>
              <div className="display-flex-row">
                <div className="">
                  <h2 className="text-sm sm:text-xl font-medium text-white text-center">
                    {song.title ? song.title : "Title"}
                  </h2>
                </div>
                {/* <div className="ml-6 mt-2">
                  {activeSongId ===
                    `${process.env.REACT_APP_AI}${song.id}${process.env.REACT_APP_EXTENSION}` ||
                  activeSongId ===
                    `${process.env.REACT_APP_FALLBACK}item_id=${song.id}` ? (
                    isPlaying ? (
                      <button
                        onClick={() => handlePlay(song.id)}
                        className="playButton"
                      >
                        <AiFillPauseCircle size="40px" color="#FFF" />
                      </button>
                    ) : (
                      <button
                        onClick={() => handlePlay(song.id)}
                        className="playButton"
                      >
                        <AiFillPlayCircle size="40px" color="#FFF" />
                      </button>
                    )
                  ) : (
                    <button
                      onClick={() => handlePlay(song.id)}
                      className="playButton"
                    >
                      <AiFillPlayCircle size="40px" color="#FFF" />
                    </button>
                  )}
                </div> */}
                {/* <div className="ml-6 mt-2" ref={containerRef}>
                  {activeSongId ===
                  `${process.env.REACT_APP_AI}${song.id}${process.env.REACT_APP_EXTENSION}` ? (
                    isPlaying ? (
                      <button
                        onClick={() => handlePlay(song.id)}
                        className="playButton"
                      >
                        <AiFillPauseCircle size="40px" color="#FFF" />
                      </button>
                    ) : (
                      <button
                        onClick={() => handlePlay(song.id)}
                        className="playButton"
                      >
                        <AiFillPlayCircle size="40px" color="#FFF" />
                      </button>
                    )
                  ) : (
                    <button
                      onClick={() => handlePlay(song.id)}
                      className="playButton"
                    >
                      <AiFillPlayCircle size="40px" color="#FFF" />
                    </button>
                  )}
                </div> */}
                <div className="ml-6 mt-2" ref={containerRef}>
                  {activeSongId === song.id ? (
                    isPlaying === song.id ? (
                      <button
                        // onClick={() => handlePlay(song.id)}
                        onClick={() => handleNewSongPlay(song)}
                        className="playButton"
                      >
                        <AiFillPauseCircle size="40px" color="#FFF" />
                      </button>
                    ) : (
                      <button
                        // onClick={() => handlePlay(song.id)}
                        onClick={() => handleNewSongPlay(song)}
                        className="playButton"
                      >
                        <AiFillPlayCircle size="40px" color="#FFF" />
                      </button>
                    )
                  ) : (
                    <button
                      // onClick={() => handlePlay(song.id)}
                      onClick={() => handleNewSongPlay(song)}
                      className="playButton"
                    >
                      <AiFillPlayCircle size="40px" color="#FFF" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
          {showLoading && <PageLoader />}
        </div>
      )}
    </>
  );
}

export default SongList;
