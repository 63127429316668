// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { UserProfileDetails } from '../../redux/ReduxSlices/ProfileSlice';
// import { fetchCreditBalance } from '../../redux/ReduxSlices/creditSlice.jsx';
// import { clearState } from '../../redux/ReduxSlices/openAISlice.jsx';
// import SecureLS from "secure-ls";

// const sidebarItems = [
//   {
//     text: 'Create song',
//   },
//   {
//     text: 'Library',
//   },
//   // {
//   //   text: 'Create Video',
//   // },
// ];

// function SidebarItem({ text, isActive, onClick }) {
//   return (
//     <div
//       onClick={onClick}
//       className={`flex gap-3 mt-2 items-center text-[16px] cursor-pointer transition-all duration-300
//           ${isActive ? 'text-teal-300 font-bold' : 'text-white'}
//           ${!isActive ? 'mt-4  hover:text-teal-300' : ''}
//         `}
//     >
//       <div
//         className={`w-1 h-10 rounded-full
//             ${isActive ? 'custom-button' : 'bg-transparent'}
//           `}
//       ></div>

//       <div
//         className={`text-sm md:text-base lg:text-base mb-4 max-md:text-lg ${isActive ? 'self-stretch my-auto mt-2' : 'my-auto'
//           }`}
//       >
//         {text}
//       </div>
//     </div>
//   );
// }

// export default function Sidebar({ onItemClick, onClose }) {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { userDetails, loading, error } = useSelector((state) => state.user);
//   const [activeItem, setActiveItem] = useState('Create song');
//   const { balance, status } = useSelector((state) => state.credits);
//   const ls = new SecureLS();

//   useEffect(() => {
//     dispatch(UserProfileDetails());
//     dispatch(fetchCreditBalance());

//   }, [dispatch, balance.credit_point]);

//   useEffect(() => {
//     dispatch(clearState());
//   }, [activeItem, dispatch]);


//   const handleLogout = async () => {
//     const savedCredentials = ls.get("credentials");

//     localStorage.clear();
//     if (savedCredentials) {
//       ls.set("credentials", savedCredentials);
//     }

//     navigate("/signin");
//   };

//   const sumCount = (userDetails?.free_trial_count ?? 0) + (balance?.credit_point ?? 0);

//   const avatarLetter = userDetails?.first_name
//     ? userDetails?.first_name.charAt(0).toUpperCase()
//     : "U";

//   const handleItemClick = (itemText) => {
//     setActiveItem(itemText);
//     onItemClick(itemText);
//     onClose();
//   };

//   const handleUpgradeClick = () => {
//     navigate('/upgrade-plan');
//   };

//   const abbreviateEmail = (email) => {
//     if (!email) return "";
//     const [username, domain] = email.split("@");
//     if (!domain) return email;
//     const abbreviatedDomain = domain.length > 5 ? `${domain[0]}..` : domain;
//     return `${username}@${abbreviatedDomain}`;
//   };


//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error.detail || error.toString()}</div>;
//   }

//   return (
//     <aside className="flex flex-col items-center pt-6 mx-auto w-full text-sm font-medium tracking-wide whitespace-nowrap bg-black min-h-screen">
//       {/* User Info Section */}
//       <div className="text-center mt-14 text-xl lg:text-sm bg-gradient-to-r from-green-500 via-teal-500 to-pink-500 text-transparent bg-clip-text font-bold">
//         I MADE A SONG
//       </div>
//       <div className="user-info cursor-pointer flex items-center p-4 bg-gradient-to-r from-green-500 via-teal-500 to-pink-500 text-white rounded-md ml-4" onClick={() => handleItemClick("Profile")}>
//         <div className="avatar text-black custom-button rounded-full max-md:w-12 max-md:h-12 w-12 h-12 flex items-center justify-center mr-4">
//           {avatarLetter}
//         </div>
//         <div className="email max-md:ml-4">
//           <p className="font-bold text-xs max-md:text-xl">{userDetails?.first_name} {userDetails?.last_name}</p>
//           <p className="text-xs max-md:text-lg">{abbreviateEmail(userDetails?.email)}</p>
//         </div>
//       </div>

//       {/* Sidebar Navigation */}
//       <nav className="mt-4 max-md:mt-6 pl-6 ml-6 w-full">
//         {sidebarItems.map((item, index) => (
//           <SidebarItem
//             key={index}
//             text={item.text}
//             isActive={activeItem === item.text}
//             onClick={() => handleItemClick(item.text)}
//           />
//         ))}
//       </nav>

//       <div className="flex-grow sm:hidden" />

//       {/* Mobile-only Content */}
//       <div className="mt-4 pl-6 ml-6 w-full sm:hidden logout-sm-button">
//         <SidebarItem text="Log Out" onClick={() => handleLogout()} />
//       </div>

//       <div className="mt-4 pl-6 ml-6 w-full sm:hidden logout-sm-button">
//         <SidebarItem text={`${sumCount} Credits`} />
//       </div>

//       <div className="flex-grow" />

//       {/* Desktop-only Content */}
//       <div className="flex-col justify-between items-center mb-20 sm:mb-0 hidden sm:flex">
//         <div className="flex items-center justify-between space-x-2 max-md:space-x-8">
//           <div className="text-white credit-balence text-start text-sm lg:text-l mb-8 d-flex justify-content-between align-items-center">
//             {sumCount} Credits
//           </div>
//           <div className="text-white credit-balence text-start text-sm lg:text-l mb-8 d-flex justify-content-between align-items-center cursor-pointer">
//             <button onClick={handleUpgradeClick} className="btn btn-primary">Upgrade</button>
//           </div>
//         </div>
//       </div>
//     </aside>

//   );
// }

// // {/* Spacer */}
// // <div className="flex-grow" />


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SecureLS from "secure-ls";
import { UserProfileDetails } from '../../redux/ReduxSlices/ProfileSlice';
import { fetchCreditBalance } from '../../redux/ReduxSlices/creditSlice.jsx';
import { clearState } from '../../redux/ReduxSlices/openAISlice.jsx';

const sidebarItems = [
  {
    text: 'Create song',
  },
  {
    text: 'Library',
  },
];

function SidebarItem({ text, isActive, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`flex gap-3 items-center text-[16px] cursor-pointer transition-all duration-300
          ${isActive ? 'text-teal-300 font-bold' : 'text-white'}
          hover:text-teal-300`
      }
    >
      <div
        className={`w-1 h-10 rounded-full
            ${isActive ? 'custom-button' : 'bg-transparent'}
          `}
      ></div>

      <div className={`text-sm md:text-base lg:text-base my-auto`}>
        {text}
      </div>
    </div>
  );
}

export default function Sidebar({ onItemClick, onClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetails, loading, error } = useSelector((state) => state.user);
  const [activeItem, setActiveItem] = useState('Create song');
  const { balance } = useSelector((state) => state.credits);
  const ls = new SecureLS();
  const sumCount = (userDetails?.free_trial_count ?? 0) + (balance?.credit_point ?? 0);

  const [isIphone, setIsIphone] = useState(false);

  useEffect(() => {
    // Check if the user is on an iPhone device
    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipod/.test(userAgent)) {
      setIsIphone(true);
    }
  }, []);

  useEffect(() => {
    dispatch(UserProfileDetails());
    dispatch(fetchCreditBalance());

  }, [dispatch, sumCount]);


  // For contact navigate
  const handleContactClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    dispatch(clearState());
  }, [activeItem, dispatch]);

  const handleLogout = async () => {
    const savedCredentials = ls.get("credentials");

    localStorage.clear();
    if (savedCredentials) {
      ls.set("credentials", savedCredentials);
    }

    navigate("/signin");
  };


  const avatarLetter = userDetails?.first_name
    ? userDetails?.first_name.charAt(0).toUpperCase()
    : "U";

  const handleItemClick = (itemText) => {
    setActiveItem(itemText);
    onItemClick(itemText);
    onClose();
  };

  const handleUpgradeClick = () => {
    navigate('/upgrade-plan');
  };

  const abbreviateEmail = (email) => {
    if (!email) return "";
    const [username, domain] = email.split("@");
    if (!domain) return email;
    const abbreviatedDomain = domain.length > 5 ? `${domain[0]}..` : domain;
    return `${username}@${abbreviatedDomain}`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.detail || error.toString()}</div>;
  }

  return (
    <aside className="flex flex-col items-center pt-6 w-full text-sm font-medium tracking-wide bg-black min-h-screen">
      {/* User Info Section */}
      <div className="text-center mt-12 max-md:mt-0 text-xl bg-gradient-to-r from-green-500 via-teal-500 to-pink-500 text-transparent bg-clip-text font-bold">
        I MADE A SONG
      </div>
      <div className="user-info flex items-center p-4 bg-gradient-to-r from-green-500 via-teal-500 to-pink-500 text-white rounded-md mt-6 cursor-pointer" onClick={() => handleItemClick("Profile")}>
        <div className="avatar text-black custom-button rounded-full w-12 h-12 flex items-center justify-center mr-4">
          {avatarLetter}
        </div>
        <div className="email">
          <p className="font-bold text-xs">{userDetails?.first_name} {userDetails?.last_name}</p>
          <p className="text-xs">{abbreviateEmail(userDetails?.email)}</p>
        </div>
      </div>

      {/* Sidebar Navigation */}
      <nav className="mt-6 w-full px-4">
        {sidebarItems.map((item, index) => (
          <SidebarItem
            key={index}
            text={item.text}
            isActive={activeItem === item.text}
            onClick={() => handleItemClick(item.text)}
          />
        ))}
      </nav>

      <div className="flex-grow" />


      {/* Desktop-only Content */}
      <div className="flex-col justify-between items-center mb-20 sm:mb-0 hidden sm:flex">
        <div className="flex items-center justify-between space-x-2 max-md:space-x-8">
          <div className="text-white credit-balence text-start text-sm lg:text-l mb-8 d-flex justify-content-between align-items-center">
            {sumCount} Credits
          </div>
          <div className="text-white credit-balence text-start text-sm lg:text-l mb-8 d-flex justify-content-between align-items-center cursor-pointer">
            <button onClick={handleUpgradeClick} className="btn btn-primary">Upgrade</button>
          </div>
        </div>
      </div>

      <div className="mt-4 pl-6 ml-6 w-full sm:hidden">
        <div className="flex items-center">
          <img
            src="https://img.icons8.com/ios-filled/50/ffffff/phone.png"
            alt="Contact Us Icon"
            className="w-5 h-5 mr-2"
          />
          <SidebarItem text="Contact us"
            onClick={() => handleContactClick('/contact')}
          />
        </div>
      </div>


      <div className="mt-4  pl-6 ml-6 w-full sm:hidden logout-sm-button">
        <SidebarItem text={`${sumCount} Credits`} />
      </div>
      <div
        className={"mt-4  pl-6 ml-6 w-full sm:hidden logout-sm-button mb-40"}
      >
        <SidebarItem text="Log Out" onClick={() => handleLogout()} />
      </div>
    </aside>
  );
}
