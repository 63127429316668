import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { AiFillPauseCircle, AiFillPlayCircle } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import MusicImage from "../../assets/dashboard/music.webp";
import ErrorImage from "../../assets/dashboard/no-results.png";
import SideBannerImage from "../../assets/dashboard/sideBanner.png";
import subscriptionImg from "../../assets/gif/subscribe.png";
import BottomAudioPlayer from "../../components/BottomAudioPlayer.jsx";
import PageLoader from "../../components/PageLoader.jsx";
import { deleteAudio } from "../../redux/ReduxSlices/downloadAudioSlice.jsx";
import { getCollection } from "../../redux/ReduxSlices/uploadAudioSlice";
import apiUrl from "../../services/api.jsx";
import SongList from "./SongList.jsx";

const normalizeText = (text) => {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

const GetAllVoice = ({ songs }) => {
  const { collectionData, loading } = useSelector((state) => state.uploadAudio);
  const { isLoading } = useSelector((state) => state.openAi);

  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const [currentAudio, setCurrentAudio] = useState(null);

  const [playingVoiceId, setPlayingVoiceId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showLoading, setLoading] = useState(false);
  const [isVisibleLyrics, setIsVisibleLyrics] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const { userDetails } = useSelector((state) => state.user);
  const { balance } = useSelector((state) => state.credits);
  const [selectedLyrics, setSelectedLyrics] = useState({
    title: null,
    lyric: null,
  });
  const dispatch = useDispatch();
  const [songToDeleteId, setSongToDeleteId] = useState(null);
  const balanceCredit = balance.credit_point;
  const { freeTrailData } = useSelector((state) => state.freeTrial);
  const [menuOpenId, setMenuOpenId] = useState(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  // const toggleMenu = (id) => {
  //   setMenuOpenId(menuOpenId === id ? null : id);
  // };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      // setMenuOpenId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const handlePlayPause = (voice) => {
  //   if (playingVoiceId === voice.id) {
  //     if (currentAudio) {
  //       currentAudio.pause();
  //       currentAudio.currentTime = 0;
  //     }
  //     setCurrentAudio(null);
  //     setPlayingVoiceId(null);
  //     setIsPlaying(false);
  //     return;
  //   }

  //   setLoading(true);

  //   if (currentAudio) {
  //     currentAudio.pause();
  //     currentAudio.currentTime = 0;
  //     setCurrentAudio(null);
  //     setPlayingVoiceId(null);
  //     setIsPlaying(false);
  //   }

  //   dispatch(getStreamBlob(voice.id))
  //     .unwrap()
  //     .then((audioUrl) => {
  //       const audio = new Audio(audioUrl);

  //       audio.onloadedmetadata = () => {
  //         setDuration(audio.duration); // Set the total duration
  //       };
  //       audio.ontimeupdate = () => {
  //         setCurrentTime(audio.currentTime); // Update the current time
  //       };
  //       audio.onended = () => {
  //         setPlayingVoiceId(null); // Reset when playback ends
  //         setIsPlaying(false);
  //       };

  //       // Play the audio
  //       audio
  //         .play()
  //         .then(() => {
  //           setCurrentAudio(audio);
  //           setPlayingVoiceId(voice.id);
  //           setIsPlaying(true);
  //         })
  //         .catch((error) => {
  //           // console.error("Error playing audio:", error);
  //           setPlayingVoiceId(null); // Reset playing state on error
  //           setIsPlaying(false);
  //         });
  //     })
  //     .catch((error) => {
  //       // console.error("Error fetching audio blob:", error);
  //       setPlayingVoiceId(null); // Reset playing state on error
  //       setIsPlaying(false);
  //     })
  //     .finally(() => {
  //       // Ensure loading is false after API call completes (success or failure)
  //       setLoading(false);
  //     });
  // };

  const handlePlayPause = async (songId, songName) => {
    if (playingVoiceId === songId.id) {
      // Pause and reset current audio if the same song is clicked
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
      return;
    }

    // Stop and reset any currently playing audio
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
    }

    try {
      setLoading(true);

      const token = localStorage.getItem("token");
      const response = await fetch(`${apiUrl.getStreamBlob}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: songId.id }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json(); // Assuming the API returns JSON with an audio URL
      const audioUrl = data.audio_url; // Replace with the correct key in your response if different

      if (audioUrl) {
        const audio = new Audio(audioUrl);

        // Attach event listeners for audio
        audio.onloadedmetadata = () => {
          setDuration(audio.duration); // Set the total duration
        };
        audio.ontimeupdate = () => {
          setCurrentTime(audio.currentTime); // Update the current time
        };
        audio.onended = () => {
          setPlayingVoiceId(null); // Reset when playback ends
          setIsPlaying(false);
        };

        // Play the audio
        audio
          .play()
          .then(() => {
            setCurrentAudio(audio);
            setPlayingVoiceId(songId.id); // Correctly set the playing voice ID
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error("Error playing audio:", error);
            setPlayingVoiceId(null); // Reset playing state on error
            setIsPlaying(false);
          });
      } else {
        throw new Error("No URL provided in the response.");
      }
    } catch (error) {
      console.error("Download or playback failed:", error);
      if (error.message.includes("HTTP error")) {
        toast.error(
          `Failed to download. Server responded with: ${error.message}`
        );
      } else {
        toast.error(
          "An unexpected error occurred during download or playback."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  // Stop audio when navigating to another page or unmounting
  useEffect(() => {
    return () => {
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
    };
  }, [currentAudio]);

  // Hook to stop audio on page change or unload
  useEffect(() => {
    const stopAudio = () => {
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
        setPlayingVoiceId(null);
        setCurrentAudio(null);
      }
    };

    // Attach global event listeners
    window.addEventListener("beforeunload", stopAudio); // Page reload or close
    window.addEventListener("popstate", stopAudio); // Browser navigation (back/forward)

    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener("beforeunload", stopAudio);
      window.removeEventListener("popstate", stopAudio);
    };
  }, [currentAudio]);

  useEffect(() => {
    if (isLoading && currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setPlayingVoiceId(null);
    }
  }, [isLoading, currentAudio]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  // To download Audio
  // const handleDownload = async (songId, songName) => {
  //   try {
  //     setLoading(true);

  //     const token = localStorage.getItem("token");

  //     const response = await fetch(`${apiUrl.downloadAudio}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({ id: songId }),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const contentDisposition = response.headers.get("Content-Disposition");
  //     let fileName = `${songName}.mp3`;

  //     if (contentDisposition && contentDisposition.includes("filename=")) {
  //       const match = contentDisposition.match(/filename="?(.+)"?/);
  //       if (match && match[1]) {
  //         fileName = match[1];
  //       }
  //     }

  //     const blob = await response.blob();

  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = fileName;
  //     document.body.appendChild(a);
  //     a.click();
  //     a.remove();

  //     window.URL.revokeObjectURL(url);
  //     toast.success("Download file successfully!");
  //   } catch (error) {
  //     console.error("Download failed:", error);
  //     if (error.message.includes("HTTP error")) {
  //       toast.error(
  //         `Failed to download. Server responded with: ${error.message}`
  //       );
  //     } else {
  //       toast.error("An unexpected error occurred during download.");
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleDownload = async (songId, songName) => {
  //   try {
  //     setLoading(true);

  //     const token = localStorage.getItem("token");

  //     const response = await fetch(`${apiUrl.downloadAudio}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({ id: songId }),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const contentDisposition = response.headers.get("Content-Disposition");
  //     let fileName = `${songName}.mp3`;

  //     if (contentDisposition && contentDisposition.includes("filename=")) {
  //       const match = contentDisposition.match(/filename="?(.+)"?/);
  //       if (match && match[1]) {
  //         fileName = match[1];
  //       }
  //     }

  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);

  //     // For iPhone, open the blob in a new window/tab
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.target = "_blank"; // This is important for iPhones
  //     link.download = fileName;
  //     link.click();

  //     // Clean up
  //     window.URL.revokeObjectURL(url);
  //     toast.success("Download file successfully!");
  //   } catch (error) {
  //     console.error("Download failed:", error);
  //     if (error.message.includes("HTTP error")) {
  //       toast.error(
  //         `Failed to download. Server responded with: ${error.message}`
  //       );
  //     } else {
  //       toast.error("An unexpected error occurred during download.");
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleDownload = async (songId, songName) => {
  //   console.log("Song ID:", songId);

  //   try {
  //     setLoading(true);

  //     const token = localStorage.getItem("token");

  //     // API call to fetch the download URL
  //     const response = await fetch(`${apiUrl.downloadAudio}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({ id: songId }),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     // Parse the response to get the download URL
  //     const data = await response.json();
  //     const downloadUrl = data.audio_url;

  //     if (!downloadUrl) {
  //       throw new Error("No download URL provided in the response.");
  //     }

  //     // Check if the device is iOS (iPhone/iPad)
  //     const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  //     if (isIOS) {
  //       // For iOS, use window.open to trigger the download (force it to open in a new tab)
  //       const iframe = document.createElement("iframe");
  //       iframe.style.display = "none";
  //       iframe.src = downloadUrl; // URL of the file
  //       document.body.appendChild(iframe);
  //       setTimeout(() => document.body.removeChild(iframe), 100); // Clean up iframe
  //     } else {
  //       // For other devices (non-iOS), proceed with Blob download method
  //       const fileResponse = await fetch(downloadUrl);
  //       if (!fileResponse.ok) {
  //         throw new Error(
  //           `Failed to fetch the file. Status: ${fileResponse.status}`
  //         );
  //       }

  //       const blob = await fileResponse.blob(); // Convert response to Blob
  //       const fileUrl = window.URL.createObjectURL(blob); // Create a Blob URL

  //       // Trigger direct download for other devices
  //       const link = document.createElement("a");
  //       link.href = fileUrl;
  //       link.target = "_blank";
  //       link.download = `${songName || "song"}.mp3`; // Default name if songName is not provided
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link); // Clean up

  //       // Revoke the Blob URL
  //       window.URL.revokeObjectURL(fileUrl);
  //     }

  //     console.log("File downloaded successfully!");
  //     toast.success("File downloaded successfully!");
  //   } catch (error) {
  //     console.error("Download failed:", error);

  //     if (error.message.includes("HTTP error")) {
  //       toast.error(
  //         `Failed to download. Server responded with: ${error.message}`
  //       );
  //     } else {
  //       toast.error("An unexpected error occurred during download.");
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleDownload = async (songId, songName) => {
    try {
      setLoading(true);

      const token = localStorage.getItem("token");

      // Fetch the download URL from the API
      const response = await fetch(`${apiUrl.downloadAudio}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: songId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const downloadUrl = data.audio_url;

      if (!downloadUrl) {
        throw new Error("No download URL provided in the response.");
      }

      let fileName = `${songName}.mp3`;

      // Check if the device is an iOS device
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      console.log(isIOS, "isIOS")
      // Fetch the file content
      const fileResponse = await fetch(downloadUrl);
      if (!fileResponse.ok) {
        throw new Error(
          `Failed to fetch the file. Status: ${fileResponse.status}`
        );
      }

      const blob = await fileResponse.blob();

      if (isIOS) {
        // Create a temporary link and open it
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = fileName;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();

        // Clean up the Blob URL and link
        setTimeout(() => {
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }, 5000);
      } else {
        // For other devices, handle the download as usual
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up the Blob URL
        window.URL.revokeObjectURL(url);
      }

      toast.success("Download file successfully!");
    } catch (error) {
      console.error("Download failed:", error);
      if (error.message.includes("HTTP error")) {
        toast.error(
          `Failed to download. Server responded with: ${error.message}`
        );
      } else {
        toast.error("An unexpected error occurred during download.");
      }
    } finally {
      setLoading(false);
    }
  };




  const openDeleteModal = (id) => {
    setSongToDeleteId(id);
    setIsModalOpen(true);
  };

  const handleDelete = async (songId) => {
    setLoading(true);

    try {
      await dispatch(deleteAudio(songId)).unwrap();

      toast.success("Song deleted successfully!");

      setIsVisibleLyrics(true);

      // Refresh the collection
      setTimeout(() => {
        dispatch(getCollection());
      }, 800);

      setIsModalOpen(false);
      setSongToDeleteId(null);
    } catch (error) {
      console.error("Delete failed:", error);

      toast.error("Failed to delete song. Please try again.");
    } finally {
      setIsVisibleLyrics(false);
      setLoading(false);
    }
  };

  // const handlePlayPause1 = async (voice) => {
  //   try {
  //     setLoading(true)
  //     if (isLoading) {
  //       if (currentAudio) {
  //         currentAudio.pause();
  //         currentAudio.currentTime = 0;
  //       }
  //       return;
  //     }

  //     if (playingVoiceId === voice.id) {
  //       if (currentAudio) {
  //         if (!currentAudio.paused) {
  //           currentAudio.pause();
  //           setPlayingVoiceId(null);
  //         } else {
  //           currentAudio
  //             .play()
  //             .catch((error) => console.error("Error playing audio:", error));
  //           setPlayingVoiceId(voice.id);
  //         }
  //       }
  //       return;
  //     }

  //     const audioUrl = await dispatch(createWatermarkAudio(voice.id)).unwrap();

  //     if (currentAudio) {
  //       currentAudio.pause();
  //       currentAudio.currentTime = 0;
  //     }

  //     const audio = new Audio(audioUrl);
  //     audio.play().catch((error) => {
  //       console.error("Error playing audio:", error);
  //     });

  //     audio.onloadedmetadata = () => {
  //       setDuration(audio.duration);
  //     };
  //     audio.ontimeupdate = () => {
  //       setCurrentTime(audio.currentTime);
  //     };
  //     audio.onended = () => {
  //       setPlayingVoiceId(null);
  //     };

  //     setCurrentAudio(audio);
  //     setPlayingVoiceId(voice.id);
  //   } catch (error) {
  //     console.error("Error playing audio:", error);
  //   } finally {
  //     setLoading(false)
  //   }
  // };

  const filteredCollection = collectionData.filter((voice) =>
    normalizeText(voice.title).includes(normalizeText(searchQuery))
  );

  if (loading) {
    return (
      <div className="absolute top-0 left-12 right-0 bottom-0 flex items-center justify-center  bg-opacity-30 z-20">
        <PageLoader />
      </div>
    );
  } else if (collectionData.length === 0) {
    return (
      <div className="flex flex-col items-center justify-start  text-center mt-12 lg:mt-52 pb-8">
        <img className="invert h-10" src={ErrorImage} alt="error" />
        <div className="no-song-generated mt-2">No collection found</div>
        <p className="text-gray-300 text-base mt-4">
          <a className="text-teal-300 text-base">
            "Generate song with I made a song"
          </a>
        </p>
      </div>
    );
  } else {
    return (
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        className="lg:p-0"
      >
        {filteredCollection.length === 0 ? (
          <div className="flex justify-center mt-24 pt-10">
            <p className="invert mr-4">
              <IoSearch size="2em" />
            </p>
            <div className="text-white text-xl">
              No song found with name {searchQuery}
            </div>
          </div>
        ) : (
          <div className="flex h-auto md:h-screen">
            {/* Left Side: Song List */}
            <div className="w-full p-0 sm:w-2/3 md:w-2/3 flex flex-col overflow-y-auto max-md:mb-8">
              <SongList
                songs={songs}
                setPlayingVoiceId={setPlayingVoiceId}
                handlePlayPause={handlePlayPause}
                isPlaying={playingVoiceId}
                setIsPlaying={setIsPlaying}
                setSelectedLyrics={setSelectedLyrics}
                setIsVisibleLyrics={setIsVisibleLyrics}
              />
              <div className="max-md:px-4 max-md:py-0 lg:p-4 overflow-y-auto flex-grow max-md:mb-20">
                {freeTrailData.free_trial_count >= 1 &&
                  freeTrailData.free_trial_count <= 4 &&
                  balanceCredit === 0 ? (
                  <div className="text-white free-song-banner bg-gradient-to-br from-teal-900 via-gray-800 to-black-300 backdrop-blur-md">
                    Songs created with a Free account include a watermark. To
                    generate watermark-free songs, please subscribe to one of
                    our plans.
                    <br className="mobile-break" />
                    <Link
                      to="/upgrade-plan"
                      className="text-blue-600 hover:underline max-md:ml-0 ml-2 view-plans-btn"
                    >
                      View Plans
                    </Link>
                  </div>
                ) : null}

                <div className="flex lg:flex-row justify-between items-center relative space-y-2 lg:space-y-0 lg:space-x-5">
                  <div className="text-l lg:text-xl font-bold text-white">
                    Your Collection
                  </div>
                  {/* <input
                    type="text"
                    placeholder="Search song..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="p-2 search-bar text-sm lg:relative lg:right-0"
                  /> */}
                </div>
                {filteredCollection.reverse().map((voice) => (
                  <div
                    key={voice.id}
                    className="shadow-lg mt-3 sm:mt-4 p-2 song mb-2 sm:mb-6 relative cursor-pointer"
                    onClick={() => {
                      setSelectedLyrics({
                        title: voice.title,
                        lyric: voice.lyric,
                      });
                      setIsVisibleLyrics(true);
                    }}
                  >
                    <div className="flex gap-4">
                      <img
                        src={MusicImage}
                        alt={voice.title}
                        className="w-18 h-10 object-cover rounded-lg cursor-pointer"
                      />
                      <div className="flex flex-col flex-grow">
                        <h2 className="text-sm mt-2 font-semibold text-white heading-text-mobile">
                          {voice.title === "" ? "Song - Title" : voice.title}
                        </h2>
                      </div>
                      <div className="flex items-center justify-between gap-4 relative">
                        <button
                          onClick={() => {
                            handlePlayPause(voice);
                          }}
                          className="text-blue-500 mx-2"
                        >
                          {playingVoiceId === voice.id ? (
                            <AiFillPauseCircle className="text-3xl fill-white" />
                          ) : (
                            <AiFillPlayCircle className="text-3xl fill-white" />
                          )}
                        </button>
                        <button
                          className="text-white text-2xl focus:outline-none"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDownload(voice.id, voice.title);
                          }}
                          style={{
                            display:
                              userDetails.subscription_plan_name === null
                                ? "none"
                                : "inline-block",
                          }}
                        >
                          <FiDownload />
                        </button>
                        <button
                          className="text-white text-2xl focus:outline-none"
                          onClick={(e) => {
                            e.stopPropagation();
                            openDeleteModal(voice.id);
                            setIsModalOpen(true);
                          }}
                        >
                          <MdDelete />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Right Side: Selected Lyrics */}
            {/* <div
              className={`p-4 w-1/3 sm:w-1/3 md:w-1/3 flex-shrink-0 text-white right-side-bar h-screen overflow-y-auto hidden sm:block`}
              style={{
                background:
                  !isVisibleLyrics ?? !selectedLyrics.lyric
                    ? `url(${SideBannerImage}) center center / contain no-repeat`
                    : "#000000",
                borderLeft:
                  !isVisibleLyrics ?? !selectedLyrics.lyric
                    ? "1px solid #313131b0"
                    : "none",
              }}
            > */}

            <div
              className={`p-4 w-1/3 sm:w-1/3 md:w-1/3 flex-shrink-0 text-white right-side-bar h-screen overflow-y-auto hidden sm:block`}
              style={{
                background:
                  !isVisibleLyrics ?? !selectedLyrics.lyric
                    ? `url(${SideBannerImage}) center center / cover no-repeat` // Changed 'contain' to 'cover'
                    : "#000000",
                backgroundSize: "150%", // Adjust the scale of the background image
                borderLeft:
                  !isVisibleLyrics ?? !selectedLyrics.lyric
                    ? "1px solid #313131b0"
                    : "none",
              }}
            >
              {isVisibleLyrics ?? selectedLyrics.lyric ? (
                <div>
                  <h2 className="text-xl font-extrabold mb-4 text-start bg-gradient-to-r from-yellow-400 via-teal-200 to-teal-100 bg-clip-text text-transparent">
                    {selectedLyrics.title}
                  </h2>
                  <p className="text-sm leading-loose">
                    {selectedLyrics.lyric}
                  </p>
                </div>
              ) : (
                <div className="flex items-center justify-center h-full">
                  <div className="text-black text-center">
                    <p className="text-gray-600 text-xl">I Made A Song</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {playingVoiceId && (
          <BottomAudioPlayer
            voice={filteredCollection.find((v) => v.id === playingVoiceId)}
            currentAudio={currentAudio}
            playing={!!playingVoiceId}
            onPlayPause={() =>
              handlePlayPause(
                filteredCollection.find((v) => v.id === playingVoiceId)
              )
            }
            currentTime={currentTime}
            duration={duration}
            formatTime={formatTime}
          />
        )}

        {showLoading && <PageLoader />}

        {isModalOpen && (
          <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
            <div className="w-full max-w-md delete-popup shadow-lg rounded-lg p-6 relative">
              {/* Close Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 cursor-pointer shrink-0 fill-white   hover:fill-red-500 float-right"
                onClick={() => setIsModalOpen(false)}
                viewBox="0 0 320.591 320.591"
              >
                <path
                  d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                  data-original="#000000"
                ></path>
                <path
                  d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                  data-original="#000000"
                ></path>
              </svg>

              {/* Modal Content */}
              <div className="my-8 text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-14 fill-red-300 inline"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M19 7a1 1 0 0 0-1 1v11.191A1.92 1.92 0 0 1 15.99 21H8.01A1.92 1.92 0 0 1 6 19.191V8a1 1 0 0 0-2 0v11.191A3.918 3.918 0 0 0 8.01 23h7.98A3.918 3.918 0 0 0 20 19.191V8a1 1 0 0 0-1-1Zm1-3h-4V2a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v2H4a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM10 4V3h4v1Z"
                    data-original="#000000"
                  />
                  <path
                    d="M11 17v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Zm4 0v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Z"
                    data-original="#000000"
                  />
                </svg>
                <h4 className="text-white text-l font-semibold mt-4">
                  Are you sure you want to delete it?
                </h4>
              </div>

              {/* Action Buttons */}
              <div className="flex flex-col space-y-2">
                <button
                  type="button"
                  className="px-4 py-2 rounded-lg text-white text-sm tracking-wide bg-red-500 hover:bg-red-600 active:bg-red-500"
                  onClick={() => handleDelete(songToDeleteId)}
                >
                  Delete
                </button>
                {/* <button
                  type="button"
                  className="px-4 py-2 rounded-lg text-gray-800 text-sm tracking-wide bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button> */}
              </div>
            </div>
          </div>
        )}

        {showModal && (
          <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
            <div className="w-full max-w-md delete-popup  shadow-lg rounded-lg p-6 relative">
              {/* Close Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 cursor-pointer shrink-0 fill-black hover:fill-red-500 float-right"
                onClick={() => setShowModal(false)}
                viewBox="0 0 320.591 320.591"
              >
                <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"></path>
                <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"></path>
              </svg>

              {/* Modal Content */}
              <div className="my-5 text-center">
                <img
                  src={subscriptionImg}
                  alt="subscription"
                  className="w-20 h-20 mx-auto mb-4 transition-all duration-300 transform hover:scale-110"
                />{" "}
                <h4 className="text-black text-l font-semibold mt-4">
                  Please subscribe to download the songs
                </h4>
                <p className="text-gray-600 mt-2">
                  Enjoy exclusive songs by becoming a subscriber!
                </p>
              </div>

              {/* Action Buttons */}
              <div className="flex flex-col space-y-2">
                <button
                  type="button"
                  className="px-6 py-3 rounded-lg text-white text-sm tracking-wide custom-button transition-all duration-300 transform hover:scale-105"
                  onClick={() => navigate("/upgrade-plan")} // handleSubscription is a function to handle the subscription logic
                >
                  Subscribe Now
                </button>
              </div>
            </div>
          </div>
        )}
        <ToastContainer />
      </motion.div>
    );
  }
};

export default GetAllVoice;
