import React, { useState } from 'react';
import '../../styles/partner.css';
import brandLogo from '../../assets/partners_images/logo.png';
import ImageTen from '../../assets/partners_images/10.png';
import ImageTwelve from '../../assets/partners_images/12.png';
import ImageNine from '../../assets/partners_images/9.png';
import ImageThree from '../../assets/partners_images/3.png';
import ImageFour from '../../assets/partners_images/4.png';
import ImageFive from '../../assets/partners_images/5.png';
import ImageSix from '../../assets/partners_images/6.png';

const PartnersPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleContactUs = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <section className="banner about_section">
        <div className="container about-sec">
          <div className="row">
            <div className="left-column">
              <div className="main">
           <a href='/'>   <img src={brandLogo} className="logo" alt="Logo" /></a>
                <h1 className="title1">
                  Start Your <br /> AI-generated custom Song Platform
                </h1>
                <p>Monthly Earnings <br /> Rs. 5 - Rs. 20 Lakhs – Effortlessly!</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="info about_section layout_padding">
        <div className="container about-section">
          <div className="row">
            <div className="w-full md:w-1/2">
              <h2 className="info_h1">The Generative AI music market is projected to grow to</h2>
              <h1 className="mb">$3.42 billion by 2033!</h1>
              <p>
                Tap into the growing demand for unique, memorable songs customized for every occasion.
                Create a profitable business, offering consumers an affordable, personalized music experience.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="thirdsec about_section">
        <div className="container">
          <div className="row">
            <div className="w-full md:w-1/2">
              <h2 className="mb1">AI-Powered Custom</h2>
              <h1 className="third_h1">Are the Future of Personalized Gifting!</h1>
              <img src={ImageTwelve} alt="AI-Powered Custom" />
            </div>
            <div className="w-full md:w-1/2">
              <button className="btn1">Memorable & Unique</button>
              <button className="btn1">Scalable</button>
              <button className="btn1">Cost-Effective</button>
            </div>
          </div>
        </div>
      </section>

      <section className="img_box about_section">
        <div className="container img-box">
          <div className="row test">
            <img src={ImageThree} className="img1" alt="Image 1" />
            <img src={ImageFour} className="img1" alt="Image 2" />
            <img src={ImageFive} className="img1" alt="Image 3" />
            <img src={ImageSix} className="img1" alt="Image 4" />
          </div>
        </div>
      </section>

      <section className="why_us about_section layout_padding">
        <div className="container">
          <h2 className="fourth_h2">Why Partner with Us?</h2>
          <ul className="line">
            <li>Turnkey Solution: <br /> A fully functional platform, ready to launch.</li>
            <li>High-Profit Margins: <br /> Earn between Rs. 5 lakhs to Rs. 20 lakhs a month.</li>
            <li>Expert Support: <br /> Our team provides ongoing training and technical assistance.</li>
            <li>Proven Business Model: <br /> Tap into a rapidly growing market with immense potential.</li>
          </ul>
        </div>
      </section>

      <section className="fifth about_section">
        <div className="container mx-auto px-4">
          <div className="row flex flex-wrap">
            <div className="w-full text-center py-8">
              <h1 className="text-3xl font-bold">Drive Sales with a Powerful Marketing Strategy</h1>
            </div>

            <p className="w-full text-center text-lg mb-8">
              Our dedicated digital marketing team is here to guide you through launching targeted campaigns
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 justify-center mx-auto text-center">
              <button id="finance-btn" className="btn-primary">
                SEO &amp; Content Marketing
              </button>
              <button id="refer-btn" className="btn-secondary">
                Social Media Ads
              </button>
              <button id="finance-btn" className="btn-primary">
                PPC Campaigns
              </button>
              <button id="refer-btn" className="btn-secondary">
                Influencer Marketing
              </button>
            </div>


            <div className="hidden md:flex w-full space-x-4">
              <div className="md:w-1/2 p-4">
                <div className="detail-box listcheck p-6 rounded-lg ">
                  <div className="get_strt mb-4">
                    <h2 className="text-2xl font-semibold text-left">
                      Get Started with an Investment <br /> of <span className="text-left">just Rs.10 lakhs!</span>
                    </h2>
                  </div>
                  <ul className="checkmark ml-5 space-y-2">
                    <li>We provide you with a Fully Functional Website on your Domain Name / Brand Name</li>
                    <li>25,000 song credits valid for 1 year</li>
                    <li>Rs. 4 lakhs worth of Digital Marketing to get you your first 10,000 to 15,000 website visitors within 60 days of launch, which can result in sales of between Rs. 5 lakhs to Rs. 10 lakhs</li>
                  </ul>
                </div>
              </div>

              <div className="md:w-1/2 mt-5 p-4">
                <img src={ImageNine} alt="Digital Marketing Image" className="rounded-lg " />
              </div>
            </div>

            <div className="hidden md:flex w-full space-x-4 mt-8">
              <div className="md:w-1/2 p-4">
                <img src={ImageTen} alt="Another Marketing Image" className="rounded-lg " />
              </div>

              <div className="md:w-1/2 p-4">
                <div className="detail-box  p-6 rounded-lg ">
                  <h2 className="text-2xl font-semibold mb-4">What You’ll Need</h2>
                  <ul className="checkmark ml-5 space-y-2">
                    <li>Interest in digital business and customer service</li>
                    <li>Basic marketing understanding (training provided)</li>
                    <li>Understanding of local trends &amp; customer base for better targeting</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="md:hidden w-full space-y-4 mt-5">
              <div className="flex flex-col items-center">
                <img src={ImageNine} alt="Digital Marketing Image" className="l1_img rounded-lg  mb-4" />
                <div className="detail-box listcheck  p-6 rounded-lg  text-center">
                  <h2 className="text-2xl font-semibold mb-4 text-left">Get Started with an Investment <br /> of <span className="text-left">just Rs.10 lakhs!</span></h2>
                  <ul className="checkmark ml-5 space-y-2 text-left">
                    <li>We provide you with a Fully Functional Website on your Domain Name / Brand Name</li>
                    <li>25,000 song credits valid for 1 year</li>
                    <li>Rs. 4 lakhs worth of Digital Marketing to get you your first 10,000 to 15,000 website visitors within 60 days of launch, which can result in sales of between Rs. 5 lakhs to Rs. 10 lakhs</li>
                  </ul>
                </div>
              </div>

              <div className="flex flex-col items-center mt-4">
                <img src={ImageTen} alt="Another Marketing Image" className="l1_img rounded-lg shadow-md mb-4" />
                <div className="detail-box  p-6 rounded-lg  text-center">
                  <h2 className="text-2xl font-semibold mb-4">What You’ll Need</h2>
                  <ul className="checkmark ml-5 space-y-2 text-left">
                    <li>Interest in digital business and customer service</li>
                    <li>Basic marketing understanding (training provided)</li>
                    <li>Understanding of local trends &amp; customer base for better targeting</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="last about_section">
        <div className="container">
          <div className="heading">
            <h1>Simple Setup, Big Earnings Potential!</h1>
          </div>
          <p className="description">
            We have done all the hard work. You can reap the benefits!!!
          </p>
        </div>
      </section>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="rounded-lg w-96 relative">
            <h2 className="text-xl text-gray-500 font-semibold m-3">Contact Us</h2>
            <iframe
              src="https://forms.zohopublic.in/access2/form/forimadeasong/formperma/1-aXrXYR1VuyMl0y_7Ve-e_rgiBe_K521lQyGrz2Boo"
              width="100%"
              height="600"
              title="Contact Us Form"
              frameBorder="0"
              className="rounded-lg"
            ></iframe>
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-gray-500"
            >
              &times;
            </button>
          </div>
        </div>
      )}

      <section className="cta about_section">
        <div className="container">
          <button onClick={handleContactUs} className="cta_btn">Contact us today</button>
          <p className="description"><strong>Start your Profitable Business in less than 15 days</strong></p>
        </div>
      </section>
    </>
  );
};

export default PartnersPage;
