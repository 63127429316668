import React from 'react';
import { AiFillPauseCircle, AiFillPlayCircle } from "react-icons/ai";
import MusicImage from '../assets/dashboard/music.webp';

const BottomAudioPlayer = ({
    voice,
    currentAudio,
    playing,
    onPlayPause,
    currentTime,
    duration,
    formatTime,
}) => {

    const progressPercentage = (currentTime / duration) * 100;


    const handleSeek = (e) => {
        const progressBar = e.currentTarget;
        const rect = progressBar.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const newTime = (clickX / rect.width) * duration;
        currentAudio.currentTime = newTime;
    };

    // console.log(voice);

    return (
        <div className="fixed music-player bottom-0 left-0 right-0 bg-gray-800 p-4 flex items-center justify-between rounded-t-xl shadow-lg z-50">
            <div className="flex items-center space-x-4">
                {/* Audio Image */}
                <img src={MusicImage} alt={'Voice'} className="w-12 h-12 md:w-14 md:h-14 rounded-full border-2 border-gray-600" />
                {/* Audio Info */}
                <div className="text-white text-xs sm:text-sm md:text-base">
                    <h2 className="font-semibold">{voice.title || 'Unknown Title'}</h2>
                    <p className="text-gray-400">{formatTime(currentTime)} / {formatTime(duration)}</p>
                </div>
            </div>

            {/* Progress Bar with Seek Functionality */}
            <div
                className="flex-grow mx-4 relative"
                onClick={handleSeek}
            >
                <div className="w-full h-1 bg-gray-600 rounded-full overflow-hidden cursor-pointer">
                    <div
                        className="h-full bg-gradient-to-r from-green-400 to-blue-500"
                        style={{ width: `${progressPercentage}%`, transition: 'width 0.5s ease-out' }}
                    />
                </div>
            </div>

            {/* Play/Pause Button */}
            <div className="flex items-center space-x-4">
                <button onClick={onPlayPause} className="text-white transform hover:scale-110 transition-transform">
                    {playing ? <AiFillPauseCircle size={36} /> : <AiFillPlayCircle size={36} />}
                </button>
            </div>
        </div>
    );
};

export default BottomAudioPlayer;
