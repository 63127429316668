import React from 'react';
import brandLogo from '../../assets/partners_images/logo.png'
import '../../styles/partner-thanks.css';
import ImagesRight from '../../assets/partners_images/rightw1.png';


const PartnersThanksPage = () => {
    return (<>


        <section className="thankyou">
            <header className="imgclass">
                <img src={brandLogo} className="logo" alt="Logo"/>
            </header>
            <div className="img">
                <img src={ImagesRight}/>
            </div>
            <div className="content">
                <h1 className='thanks-class'>Thank you for your interest</h1>
                <h3>we will get back to you shortly!</h3>
            </div>
        </section>
    </>
    );

};

export default PartnersThanksPage;