import React from "react";
import FAQAccordion from "../../components/FAQAccordion";
import { Link } from "react-router-dom";

const TermsOfService = () => {
  return (
    <div className="bg-black min-h-screen px-4 py-12">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl md:text-6xl font-bold text-center text-white mb-12 mt-16">
          Terms of Service
        </h1>
        <h6 className="text-3xl text-start font-bold text-white mb-6">
          Acceptance of These Terms of Service
        </h6>
        <p className="text-lg text-gray-200 mb-8">
          Digikore Studios Limited (&quot;Digikore&quot;, &quot;we&quot;,
          &quot;us&quot; or &quot;our&quot;) provides our services (described
          below) and related content to you through our website(s) located at
          <Link to={"/"} className="text-indigo-800 font-medium">
            {" "}
            https://www.imadeasong.com
          </Link>
          &nbsp;(the &quot;Site&quot;), through any third-party social platforms such
          as Discord, and through our mobile applications and related
          technologies (&quot;Mobile Apps&quot;, and collectively, such Mobile
          Apps and the Site, including any content, tools, features,
          functionality and technology offered on or through our Site or Mobile
          Apps, the &quot;Service&quot;). All access and use of the Service is
          subject to the terms and conditions contained in these Terms of
          Service (as amended from time to time, these &quot;Terms of
          Service&quot;). By accessing, browsing, or otherwise using the Site,
          Mobile Apps, or any other aspect of the Service, you acknowledge that
          you have read, understood, and agree to be bound by these Terms of
          Service. If you do not accept the terms and conditions of these Terms
          of Service, you will not access, browse, or otherwise use the Service.
        </p>
        <p className="text-lg text-gray-200 mb-8">
          We reserve the right, at our sole discretion, to change or modify
          portions of these Terms of Service at any time. If we do this, we will
          post the changes on this page and will indicate at the top of this
          page the date these Terms of Service were last revised. You may read a
          current, effective copy of these Terms of Service by visiting the
          &quot;Terms of Service&quot; link on the Site. We will also notify you
          of any material changes, either through the Service user interface, a
          pop-up notice, email, or through other reasonable means. Your
          continued use of the Service after the date any such changes become
          effective constitutes your acceptance of the new Terms of Service. You
          should periodically visit this page to review the current Terms of
          Service so you are aware of any revisions. If you do not agree to
          abide by these or any future Terms of Service, you will not access,
          browse, or use (or continue to access, browse, or use) the Service.
        </p>
        <p className="text-lg text-gray-200 mb-8">
          PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY CONTAIN AN
          AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR
          LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE
          REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE
          AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU WILL
          ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST DIGIKORE ON AN INDIVIDUAL
          BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
          REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO
          SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF)
          ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS
          YOU HAVE AGAINST US RESOLVED IN A COURT OF LAW.
        </p>

        <p className="text-lg text-gray-200 mb-8">
          Your Privacy: At Digikore Studios, we respect the privacy of our
          users. By using the Service, you consent to our collection, use and
          disclosure of personal data and other data as outlined therein.
        </p>

        <p className="text-lg text-gray-200 mb-8">
          Additional Terms: In addition, when using certain features through the
          Service, you will be subject to any additional terms applicable to
          such features that may be posted on or within the Service from time to
          time. All such terms are hereby incorporated by reference into these
          Terms of Service.
        </p>

        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Access and Use of the Service
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            Service Description: The Service is designed to process input data,
            information, and content that you provide in the form of code,
            video, images, information, data, text, software, music, sound and
            other audio, photographs, graphics, messages, and other materials
            and mediums (collectively referred to as the
            &quot;Submissions&quot;) and generate and return audio and visual
            output based on those Submissions (&quot;Output&quot;, and together
            with the Submissions, &quot;Content&quot;). The Service may include
            a public forum where Content can be shared in a productive
            environment with other users.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            It is very important that you only upload, post, publish, or display
            (hereinafter, &quot;upload(ing)&quot;) Submissions that you have
            rights to use and provide hereunder. By uploading any Submission,
            you represent and warrant that:
          </p>
          <p>
            <ol>
              <li className="text-lg text-gray-200 mb-8">
                {" "}
                1. you have, or have obtained, all rights, licenses, consents,
                permissions, power and/or authority necessary to submit and use
                (and allow us to use) such Submission in connection with the
                Service, including for the purpose of generating your Output.
              </li>
              <li className="text-lg text-gray-200 mb-8">
                {" "}
                2. your Submissions and the use thereof by the Service,
                including to generate Output, will not violate any law or any
                third party’s rights, terms and conditions associated with such
                Submission, and
              </li>
              <li className="text-lg text-gray-200 mb-8">
                3. no other licenses, permissions, consents or authorizations
                must be obtained from or payments made to any other person or
                entity by us (or any third party deriving any rights or
                obligations from us) arising out of or related to our use of
                your Submissions, including to create your Output and/or to
                train, develop, fine-tune or otherwise improve the Service and
                any related artificial intelligence or machine learning models.
              </li>
            </ol>
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">Your Registration Obligations:</span>{" "}
             You may be required to register with Digikore or provide
            information about yourself (e.g., name and email address) in order
            to access and use certain features of the Service. If you choose to
            register for the Service, you agree to provide and maintain true,
            accurate, current, and complete information about yourself as
            prompted by the Service&#39;s registration form. Registration data
            and certain other information about you are governed by our Privacy
            Policy. You must be at least 13 years old to use the Service. In
            addition, if you are under 18 years old, you may use the Service
            only with the express consent of your parent or guardian, and you
            agree to provide true, accurate, current, and complete information
            as requested by Digikore to confirm such express consent. We may,
            but are not obligated to, provide a free tier of the Service. You
            may not create more than one account to benefit from the free tier
            of the Service. If we believe you are not using the free tier in
            good faith, we may stop providing access to the Service. If we
            discontinue the Service, we will refund you any pre-paid fees on a
            pro rata basis, based upon the number of months left in the pre-
            payment period.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">
              Member Account, Password and Security:
            </span>{" "}
            You are responsible for maintaining the confidentiality of your
            password and account details, if any, and are fully responsible for
            any and all activities that occur under your password or account.
            You agree to (a) immediately notify Digikore of any unauthorized use
            of your password or account or any other breach of security, and (b)
            ensure that you exit from your account at the end of each session
            when accessing the Service. Digikore will not be liable for any loss
            or damage arising from your failure to comply with this paragraph.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold"> Modifications to Service: </span>
            Digikore reserves the right to modify, suspend or discontinue,
            temporarily or permanently, the Service (or any part thereof) with
            or without notice. You agree that Digikore will not be liable to you
            or to any third party for any modification, suspension or
            discontinuance of the Service.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">
              General Practices Regarding Use and Storage:
            </span>{" "}
            You acknowledge that Digikore may establish general practices and
            limits concerning use of the Service, including the maximum period
            of time that data or other content will be retained by the Service
            and the maximum storage space that will be allotted on Digikore's or
            its third-party service providers' servers on your behalf. You
            acknowledge that Digikore reserves the right to terminate accounts
            (and all of their corresponding Submissions and Output) that are
            inactive for an extended period of time. You further acknowledge
            that Digikore reserves the right to change these general practices
            and limits at any time, in its sole discretion, with or without
            notice.
          </p>
        </div>

        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Conditions of Access and Use
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            User Conduct: You are solely responsible for any use of the Service
            and all Submissions that you make available to Digikore, including
            by uploading Submissions via the Service or by emailing or otherwise
            making available Submissions to other users of the Service, and for
            any Outputs that you generate via the Service. You assume all risk
            associated with Submissions, including the transmission thereof, and
            you have sole responsibility for the accuracy, quality, legality and
            appropriateness of Submissions. The posting of Content on the
            Service by users does not indicate any approval or endorsement by
            Digikore of such Content. Digikore is not responsible for, and
            disclaims, any and all liability in connection with Content or any
            act of accessing, browsing, contributing to or otherwise using the
            Service. In no event will you use the Output to compete with
            Digikore, including to create a competing product or service. The
            list below provides examples of the kinds of Submissions or uses
            that are illegal or prohibited by Digikore. Digikore reserves the
            right to investigate and take appropriate legal action against
            anyone who, in Digikore&#39;s sole discretion, violates this
            provision, including removing the offending content from the
            Service, suspending or terminating the account of such violators,
            and reporting the violator to law enforcement authorities. You agree
            to not use the Service to:
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <ol>
              <li>
                - submit, upload, transmit or otherwise make available any
                Submissions or direct the Services to generate any Output that
                (i) infringes any intellectual property or other proprietary
                rights of any party; (ii) you do not have a right to upload and
                use under any law or under contractual or fiduciary
                relationships; (iii) contains software viruses or any other
                computer code, files or programs designed to interrupt, destroy,
                or limit the functionality of any computer software or hardware
                or telecommunications equipment; (iv) poses or creates a privacy
                or security risk to any person; (v) constitutes unsolicited or
                unauthorized advertising, promotional materials, commercial
                activities and/or sales, &quot;junk mail,&quot;
                &quot;spam,&quot; &quot;chain letters,&quot; &quot;pyramid
                schemes,&quot; &quot;contests,&quot; &quot;sweepstakes,&quot; or
                any other form of solicitation; (vi) is (or is used for or in
                connection with any purpose, initiative, activity, product or
                service that is) unlawful, harmful, threatening, abusive,
                harassing, tortious, excessively violent, defamatory, vulgar,
                obscene, pornographic, libelous, invasive of another&#39;s
                privacy, hateful, discriminatory, or otherwise objectionable; or
                (vii) in the sole judgment of Digikore, is objectionable or
                which restricts or inhibits any other person from using or
                enjoying the Service, or which may expose Digikore or its users
                to any harm or liability of any type;
              </li>
              <li>
                - interfere with or disrupt the Service or servers or networks
                connected to the Service, or disobey any requirements,
                procedures, policies, or regulations of networks connected to
                the Service;
              </li>
              <li>
                - violate any applicable local, state, national, or
                international law, or any regulations having the force of law;
              </li>
              <li>
                - impersonate any person or entity, or falsely state, imply or
                otherwise misrepresent your affiliation with any person or
                entity (including by generating, making available, promoting,
                labelling or otherwise using Output in a manner that suggests
                that such Output was created by or otherwise associated with an
                individual who was not the creator thereof);
              </li>
              <li>
                - solicit personal information from anyone under the age of 18;
              </li>
              <li>
                - post any private information, or otherwise harvest, collect or
                disclose email addresses or other information about another user
                or any other person without his or her express consent or for
                the purposes of sending unsolicited emails or other unsolicited
                communications;
              </li>
              <li>
                - advertise or offer to sell or buy any goods or services for
                any business purpose that is not specifically authorized;
              </li>
              <li>
                - imply that Digikore endorses any of your Submissions, Output,
                statements or positions;
              </li>
              <li>
                - further or promote any criminal activity or enterprise or
                provide instructional information about illegal activities;
              </li>
              <li>
                - obtain or attempt to access or otherwise obtain any content or
                information through any means not intentionally made available
                or provided for through the Service;
              </li>
              <li>
                - use the Services (and any Output) to create, develop or
                improve any competing products or services or to power, enable
                or train other artificial intelligence and machine learning
                models, tools or technologies;
              </li>
              <li>
                - circumvent, remove, alter, deactivate, degrade, or thwart any
                of the content protections in or geographic restrictions on any
                content (including Service Content (as defined below)) available
                on or through the Service, including through the use of virtual
                private networks; or
              </li>
              <li>
                - engage in or use any data mining, robots, scraping, or similar
                data gathering or extraction methods.
              </li>
            </ol>
          </p>
          <p className="text-lg text-gray-200 mb-8">
            If you are blocked by Digikore from accessing the Service (including
            by blocking your IP address), you agree not to implement any
            measures to circumvent such blocking (e.g., by masking your IP
            address or using a proxy IP address or virtual private network).
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">Fees: </span>To the extent the Service
            or any portion thereof is made available for any fee, you may be
            required to select a payment plan and provide information regarding
            your credit card or other payment instrument. You represent and
            warrant to Digikore that such information is true and that you are
            authorized to use the payment instrument. You will promptly update
            your account information with Digikore or the Payment Processor (as
            defined below), as applicable, of any changes (for example, a change
            in your billing address or credit card expiration date) that may
            occur. You agree to pay Digikore the amount that is specified in the
            payment plan in accordance with the terms of such plan and these
            Terms of Service. If your payment plan includes an ongoing
            subscription that is automatically renewed periodically, you hereby
            authorize Digikore (through the Payment Processor) to bill your
            payment instrument in advance on such periodic basis in accordance
            with the terms of the applicable payment plan until you terminate
            your account, and you further agree to pay any charges so incurred.
            If you dispute any charges you must let Digikore know within sixty
            (60) days after the date that Digikore charges you, or within such a
            longer period of time as may be required under applicable law.
            Details on our free and paid subscription offerings, including
            prices for paid subscriptions, are listed at{" "}
            <Link to={"/"} className="text-indigo-800 font-medium">
            https://www.imadeasong.com
            </Link>
             We reserve the right to change Digikore&#39;s prices. If Digikore
            does change prices, Digikore will provide notice of the change
            through the Service user interface, a pop-up notice, email, or
            through other reasonable means, at Digikore&#39;s option, at least
            fifteen (15) days before the change is to take effect. Your
            continued use of the Service after the price change becomes
            effective constitutes your agreement to pay the changed amount. You
            will be responsible for all taxes associated with the Service, other
            than taxes based on Digikore&#39;s net income.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">
              Subscription Renewals and Cancellations:
            </span>{" "}
            You agree that if you purchase a subscription, your subscription
            will automatically renew at the subscription period frequency
            referenced on your subscription page (or if not designated, then
            monthly) and at the then-current rates, and your payment method will
            automatically be charged at the start of each new subscription
            period for the fees and taxes applicable to that period. To avoid
            future subscription charges, you must cancel your subscription seven
            (7) days before the subscription period renewal date by either
            cancelling your subscription within the account interface of the
            Site at{" "}
            <Link to={"/"} className="text-indigo-800 font-medium">
            https://www.imadeasong.com
            </Link>{" "}
            or by telling us via email at action arose or be forever barred. A
            printed version of these Terms of Service and of any notice given in
            electronic form will be admissible in judicial or administrative
            proceedings based upon or relating to these Terms of Service to the
            same extent and subject to the same conditions as other business
            documents and records originally generated and maintained in printed
            form. You may not assign these Terms of Service without the prior
            written consent of Digikore, but Digikore may assign or transfer
            these Terms of Service, in whole or in part, without restriction.
            The section titles in these Terms of Service are for convenience
            only and have no legal or contractual effect. As used in these Terms
            of Service, the words &quot;include&quot; and &quot;including,&quot;
            and variations thereof, will not be deemed to be terms of
            limitation, but rather will be deemed to be followed by the words
            &quot;without limitation.&quot; Notices to you may be made via
            either email or regular mail. The Service may also provide notices
            to you of changes to these Terms of Service or other matters by
            displaying notices or links to notices generally on the Service.
            Digikore will not be in default hereunder by reason of any failure
            or delay in the performance of its obligations where such failure or
            delay is due to civil disturbances, riot, epidemic, hostilities,
            war, terrorist attack, embargo, natural disaster, acts of God,
            flood, fire, sabotage, fluctuations or unavailability of electrical
            power, network access or equipment, or any other circumstances or
            causes beyond Digikore&#39;s reasonable control. that you want to
            cancel your subscription. Following any cancellation by you, you
            will continue to have access to the paid Service through the end of
            the subscription period for which payment has already been made.
             <a className="text-blue-600" href="mailto: support@imadeasong.com">
             {" "} support@imadeasong.com
            </a>{" "}
            that you want to cancel your subscription. Following any
            cancellation by you, you will continue to have access to the paid
            Service through the end of the subscription period for which payment
            has already been made.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">Free Trial:</span>You can sign up for a
            trial for the paid portion of the Service and your trial period
            starts on the day you sign up for the trial and lasts for the
            duration indicated on your free trial confirmation email (or if not
            specified, then 7 days). If you are on a trial, you may cancel at
            any time until the last day of your trial by either cancelling your
            trial within the account interface of the Site at
            <Link to={"/"} className="text-indigo-800 font-medium">{" "}
            https://www.imadeasong.com
            </Link>{" "}
            or by telling us via email at
            <a className="text-blue-600" href="mailto: support@imadeasong.com">
              {" "}support@imadeasong.com
            </a>{" "}
            that you want to cancel your subscription. If you do not cancel your
            trial at the end of your free trial period, and we have notified you
            that your account will be converted to a paid subscription at the
            end of the free trial period, you authorize us to charge your credit
            card or other designated billing method for continued use of the
            paid Service. You may, however, then cancel your subscription
            through the methods described above. If you cancel your trial
            account or decide not to purchase a paid version of the Service at
            the end of your trial period, your content or data associated with
            your trial account may no longer be available to you, and Digikore
            may delete or remove any such content or data.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold"> Payment Processing:</span>{" "}
             Notwithstanding any amounts owed to Digikore hereunder, DIGIKORE
            DOES NOT PROCESS PAYMENT FOR ANY SERVICES. To facilitate payment for
            the Service via bank account, credit card, or debit card, we use
            Razorpay. and its affiliates (Razorpay), a third-party payment
            processor. These payment processing services are provided by
            Razorpay and are subject to Razorpay terms and conditions and other
            policies available at https://razorpay.com. By agreeing to these
            Terms of Service, users that use the payment functions of the
            Service also agree to be bound by the Razorpay Agreements, as the
            same may be modified by Razorpay from time to time. You hereby
            authorize Razorpay to store and continue billing your specified
            payment method even after such payment method has expired, to avoid
            interruptions in payment for your use of the Service. Please contact
            Razorpay for more information. Digikore assumes no liability or
            responsibility for any payments you make through the Service.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">Refunds and Cancellations:</span>{" "}
            Payments made by you hereunder are final and nonrefundable, unless
            otherwise determined by Digikore.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">Commercial Use:</span> Subject to the
            Content Section below, unless otherwise expressly authorized herein
            or in the Service, you agree not to display, distribute, license,
            perform, publish, reproduce, duplicate, copy, create derivative
            works from, modify, sell, resell, grant access to, transfer, or
            otherwise use or exploit any portion of the Service, and any Output,
            for any commercial purposes.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">Use of the Mobile App:</span>You are
            responsible for providing the mobile device, wireless service plan,
            software, Internet connections and/or other equipment or services
            that you need to download, install and use the Mobile App. We do not
            guarantee that the Mobile App can be accessed and used on any
            particular device or with any particular service plan. We do not
            guarantee that the Mobile App or will be available in, or that
            orders for our paid Services can be placed from, any particular
            geographic location. As part of the Services and to update you
            regarding the status of deliveries, you may receive push
            notifications, local client notifications, text messages, picture
            messages, alerts, emails or other types of messages directly sent to
            you in connection with the Mobile App (“Push Messages”). You
            acknowledge that, when you use the Mobile App, your wireless service
            provider may charge you fees for data, text messaging and/or other
            wireless access, including in connection with Push Messages. You
            have control over the Push Messages settings, and can opt in or out
            of these Push Messages through the Services or through your mobile
            device’s operating system (with the possible exception of
            infrequent, important service announcements and administrative
            messages). Please check with your wireless service provider to
            determine what fees apply to your access to and use of the Mobile
            App, including your receipt of Push Messages from Digikore. You are
            solely responsible for any fee, cost or expense that you incur to
            download, install and/or use the Mobile App on your mobile device,
            including for your receipt of push messages from Digikore.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">
              Mobile Software from the Apple App Store:
            </span>{" "}
            The following terms and conditions apply to you only if you are
            using the Mobile App from the Apple Inc.’s (“Apple”) App Store. To
            the extent the other terms and conditions of these Terms of Service
            are less restrictive than, or otherwise conflict with, the terms and
            conditions of this paragraph, the more restrictive or conflicting
            terms and conditions in this paragraph apply, but solely with
            respect to your use of the Mobile App from the Apple App Store. You
            acknowledge and agree that these Terms of Service are solely between
            you and Digikore, not Apple, and that Apple has no responsibility
            for the Mobile App or content thereof. Your use of the Mobile App
            must comply with the App Store’s applicable terms of use. You
            acknowledge that Apple has no obligation whatsoever to furnish any
            maintenance and support services with respect to the Mobile App. In
            the event of any failure of the Mobile App to conform to any
            applicable warranty, you may notify Apple, and Apple will refund the
            purchase price, if any, for the Mobile App to you. To the maximum
            extent permitted by applicable law, Apple will have no other
            warranty obligation whatsoever with respect to the Mobile App, and
            any other claims, losses, liabilities, damages, costs or expenses
            attributable to any failure to conform to any warranty will be
            solely governed by these Terms of Service. You and Digikore
            acknowledge that Apple is not responsible for addressing any claims
            of yours or any third party relating to the Mobile App or your
            possession and/or use of the Mobile App, including, but not limited
            to: (a) product liability claims, (b) any claim that the Mobile App
            fails to conform to any applicable legal or regulatory requirement,
            and (c) claims arising under consumer protection or similar
            legislation. You and Digikore acknowledge that, in the event of any
            third party claim that the Mobile App or your possession and use of
            that Mobile App infringes that third party’s intellectual property
            rights, Digikore, not Apple, will be solely responsible for the
            investigation, defense, settlement and discharge of any such
            intellectual property infringement claim to the extent required by
            these Terms of Service. You must comply with applicable third party
            terms of agreement when using the Mobile App. You and Digikore
            acknowledge and agree that Apple, and Apple’s subsidiaries, are
            third party beneficiaries of these Terms of Service as they relate
            to your use of the Mobile App, and that, upon your acceptance of
            these Terms of Service, Apple will have the right (and will be
            deemed to have accepted the right) to enforce these Terms of Service
            against you as a third party beneficiary thereof.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Software
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            Ownership; Restrictions: The technology and software underlying the
            Service or distributed in connection therewith are the property of
            Digikore, its affiliates, and its licensors (the
            &quot;Software&quot;). You agree not to copy, modify, create a
            derivative work of, reverse engineer, reverse assemble, or otherwise
            attempt to discover any source code, sell, assign, sublicense, or
            otherwise transfer any right in the Software. Any rights not
            expressly granted herein are reserved by Digikore.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            Special Notice for International Use; Export Controls: Digikore is
            headquartered in India. Whether inside or outside of India, you are
            solely responsible for ensuring compliance with the laws of your
            specific jurisdiction. Software available in connection with the
            Service and the transmission of applicable data, if any, is subject
            to India’s export controls. No Software may be downloaded from the
            Service or otherwise exported or re-exported in violation of Indian
            export laws. Downloading, accessing or using the Software or the
            Service is at your sole risk.
          </p>
        </div>

        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Intellectual Property Rights
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            Service Content: You acknowledge and agree that the Service may
            contain content or features (&quot;Service Content&quot;) that are
            protected by copyright, patent, trademark, trade secret, or other
            proprietary rights and laws. Except as expressly authorized by
            Digikore or otherwise under these Terms of Service, you agree not to
            modify, copy, frame, scrape, rent, lease, loan, sell, distribute, or
            create derivative works based on the Service or the Service Content,
            in whole or in part, except that the foregoing does not apply to
            Content that you upload to or make available through the Service in
            accordance with these Terms of Service. Any use of the Service or
            the Service Content other than as specifically authorized herein is
            strictly prohibited.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">Trademarks:</span> The Digikore name and
            logos are trademarks and service marks of Digikore (collectively the
            &quot;Digikore Trademarks&quot;). Other company, product, and
            service names and logos used and displayed via the Service may be
            trademarks or service marks of their respective owners who may or
            may not endorse or be affiliated with or connected to Digikore.
            Nothing in these Terms of Service or the Service should be construed
            as granting, by implication, estoppel, or otherwise, any license or
            right to use any of Digikore Trademarks displayed on the Service,
            without our prior written permission in each instance. All goodwill
            generated from the use of Digikore Trademarks will inure to our
            exclusive benefit.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">Third-Party Material:</span> Under no
            circumstances will Digikore be liable in any way for any content or
            materials of any third parties (including users), including for any
            errors or omissions in any content, or for any loss or damage of any
            kind incurred as a result of the use of any such content. You
            acknowledge that Digikore does not pre-screen content, but that
            Digikore and its designees will have the right (but not the
            obligation) in their sole discretion to refuse or remove any content
            that is available via the Service. Without limiting the foregoing,
            Digikore and its designees will have the right to remove any content
            that violates these Terms of Service or is deemed by Digikore, in
            its sole discretion, to be otherwise objectionable. You agree that
            you must evaluate, and bear all risks associated with, the use of
            any content, including any reliance on the accuracy, completeness,
            or usefulness of such content.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">Content:</span>You represent and warrant
            that you own all right, title and interest in and to Submissions,
            including all copyrights and rights of publicity contained therein,
            and that you possess all necessary rights or have obtained all
            consents necessary to grant Digikore the rights and licenses herein.
            By using the Service or otherwise transmitting Submissions to us,
            you grant to Digikore and our affiliates, successors, assigns, and
            designees a worldwide, non-exclusive, fully paid-up, sublicensable
            (directly and indirectly through multiple tiers), assignable,
            royalty-free, perpetual, irrevocable right and license to use,
            reproduce, store, modify, distribute, create derivative works based
            on, perform, display, communicate, transmit and otherwise make
            available any and all Content (in whole or in part) in any media now
            known or hereafter developed, in connection with the provision, use,
            monetization, promotion, marketing, and improvement of our products
            and services, including the Service and the artificial intelligence
            and machine learning models related to the Service. For the
            avoidance of doubt, this license authorizes us to make your Content
            available to and sublicense Content to other users of the Service as
            necessary to provide the Service, and you further grant to Digikore
            the worldwide, fully paid-up, sublicensable, assignable, perpetual
            and irrevocable right to identify to the public (both on and off the
            Service) that Output (or any of it) was generated via the Service.
            Such additional uses by Digikore and other users of the Service is
            made without compensation to you or any other provider of the
            Submissions with respect to the Content, as the use of the Service
            by you is hereby agreed as being sufficient compensation for the
            Content and grant of rights herein. Furthermore, and for the
            avoidance of doubt, you irrevocably waive any and all so-called
            &quot;moral rights&quot; or &quot;droit moral&quot; that may exist
            in or in connection with the Content. You acknowledge that to the
            extent that you include personally identifiable information in the
            Submissions, we may republish such information. Although Digikore
            has no obligation to monitor the Content, you acknowledge and agree
            that we may do one or all of the following: (i) monitor the Content;
            (ii) alter, edit, or remove any Content in whole or in part; or
            (iii) disclose any Content.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            Subject to your compliance with these Terms of Service, if you are a
            user who has subscribed to the paid tier of the Service, Digikore
            hereby assigns to you all of its right, title and interest in and to
            any Output owned by Digikore and generated from Submissions made by
            you through the Service during the term of your paid-tier
            subscription. However, due to the nature of machine learning,
            Digikore makes no representation or warranty to you that any
            copyright will vest in any Output.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            If you are a user of the free tier of the Service then, you covenant
            and agree that you will only use Outputs generated from Submissions
            made by you through the Service solely for your lawful, internal,
            personal and non-commercial purposes, provided that you give
            attribution credit to Digikore in each case.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            For the avoidance of doubt, Output may be publicly available in a
            third party application such as Discord, where it may be viewable,
            downloadable, and modified by other users of that third party
            application. If the Output becomes publicly available in such third-
            party application, you agree that other users have a right to
            access, display, view, store, modify, and copy such Output;
            provided, however, that you may change your settings to bypass these
            public sharing default settings so that Output generated will remain
            private.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            Due to the nature of machine learning, Output may not be unique
            across users and the Service may generate the same or similar output
            for a third party. Other users may provide similar submissions and
            receive the same output. Output that is requested by and generated
            for other users is not your Content.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            You hereby authorize Digikore and its third-party service providers
            to derive statistical and usage data relating to your use of the
            Service (&quot;Usage Data&quot;). We may use Usage Data for any
            purpose in accordance with applicable law and our Privacy Policy.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            Any questions, comments, suggestions, ideas, feedback, reviews, or
            other information about the Service (&quot;Feedback&quot;) provided
            by you to Digikore are non-confidential, and Digikore will be
            entitled to the unrestricted use and dissemination of Feedback for
            any purpose, commercial or otherwise, without acknowledgment,
            attribution, or compensation to you.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            You acknowledge and agree that Digikore may preserve Content and may
            also disclose Content if required to do so by law or in the good
            faith belief that such preservation or disclosure is reasonably
            necessary to: (a) comply with legal process, applicable laws, or
            government requests; (b) enforce these Terms of Service; (c) respond
            to claims that any content violates the rights of third parties; or
            (d) protect the rights, property, or personal safety of Digikore,
            its users, or the public. You understand that the technical
            processing and transmission of the Service, including Content, may
            involve (i) transmissions over various networks; and (ii) changes to
            conform and adapt to technical requirements of connecting networks
            or devices.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">Copyright Complaints:</span> Digikore
            respects the intellectual property of others, and we ask our users
            to do the same. If you believe that your work has been copied on the
            Services in a way that constitutes copyright infringement, or that
            your intellectual property rights have been otherwise violated, you
            should notify Digikore of your infringement claim in accordance with
            the procedure set forth below.
          </p>

          <p className="text-lg text-gray-200 mb-8">
            Digikore will process and investigate notices of alleged
            infringement and will take appropriate actions under the Copyright
            Act, 1957 and other applicable intellectual property laws with
            respect to any alleged or actual infringement. A notification of
            claimed copyright infringement should be emailed to Digikore&#39;s
            Copyright Agent at{" "}
            <a className="text-blue-600" href="mailto: support@imadeasong.com">
              support@imadeasong.com {" "}
            </a>
            (Subject line: &quot;DMCA Takedown Request&quot;). You may also
            contact the Copyright Agent by mail at:
          </p>
          <p className="text-lg text-gray-200 mb-8">
            Digikore Legal 4 th Floor, Lalwani Triumph, Sakore Nagar, Viman
            Nagar, Pune 411014
          </p>
          <p className="text-lg text-gray-200 mb-8">
            To be effective, the notification must be in writing and contain the
            following information:
          </p>

          <p className="text-lg text-gray-200 mb-8">
            a physical or electronic signature of a person authorized to act on
            behalf of the owner of the copyright or other intellectual property
            interest that is allegedly infringed;
          </p>
          <p className="text-lg text-gray-200 mb-8">
            identification of the copyrighted work or other intellectual
            property that you claim has been infringed, or, if multiple
            copyrighted works or other intellectual property are covered by a
            single notification, a representative list of such works or other
            intellectual property;
          </p>
          <p className="text-lg text-gray-200 mb-8">
            identification of the content that is claimed to be infringing or to
            be the subject of infringing activity, and where the content that
            you claim is infringing is located on the Service, with enough
            detail that we may find it on the Service;
          </p>
          <p className="text-lg text-gray-200 mb-8">
            your address, telephone number, and email address;
          </p>
          <p className="text-lg text-gray-200 mb-8">
            a statement by you that you have a good faith belief that the
            disputed use is not authorized by the copyright or intellectual
            property owner, its agent, or the law; and a statement by you that
            the information in your notice is accurate and, under penalty of
            perjury, that you are the copyright or intellectual property owner
            or are authorized to act on the behalf of the owner of the copyright
            or intellectual property that is allegedly infringed.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">Counter-Notice:</span> If you believe
            that your Content that was removed (or to which access was disabled)
            is not infringing, or that you have the authorization from the
            copyright owner, the copyright owner&#39;s agent, or pursuant to the
            law, to upload and use the content in Content, you may send a
            written counter-notice containing the following information to the
            Copyright Agent:
          </p>
          <p className="text-lg text-gray-200 mb-8">
            your physical or electronic signature;
          </p>
          <p className="text-lg text-gray-200 mb-8">
            identification of the content that has been removed or to which
            access has been disabled and the location at which the content
            appeared before it was removed or disabled;
          </p>
          <p className="text-lg text-gray-200 mb-8">
            a statement by you, made under penalty of perjury, that you have a
            good faith belief that the content was removed or disabled as a
            result of mistake or a misidentification of the content to be
            removed or disabled; and
          </p>
          <p className="text-lg text-gray-200 mb-8">
            your name, address, telephone number, and email address, a statement
            that you consent to the jurisdiction of the federal court located
            within Massachusetts and a statement that you will accept service of
            process from the person who provided notification of the alleged
            infringement.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            If a counter-notice is received by the Copyright Agent, Digikore
            will send a copy of the counter-notice to the original complaining
            party informing them that Digikore may replace the removed content
            or cease disabling it within ten (10) business days. Unless the
            owner of the applicable copyrighted work or other intellectual
            property files an action seeking a court order against Digikore or
            the user, the removed content may be replaced, or access to it
            restored, within ten (10) to fourteen (14) business days or more
            after receipt of the counter-notice, at our sole discretion.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            <span className="font-bold">Repeat Infringer Policy:</span> In
            accordance with the Copyright Act, 1957 and other applicable law,
            Digikore has adopted a policy of terminating, in appropriate
            circumstances and at Digikore&#39;s sole discretion, the accounts of
            users who are deemed to be repeat infringers. Digikore may also at
            its sole discretion limit access to the Service and/or terminate the
            accounts of any users who infringe any intellectual property rights
            of others, whether or not there is any repeat infringement.
          </p>
        </div>

        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Third-Party Services and Websites{" "}
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            The Service may display, include, make available, or provide links
            or other access to services, sites, software, applications, content,
            data, technology, and resources that are provided or otherwise made
            available by third parties (the &quot;Third-Party Services&quot;).
            Additionally, you may enable or log in to the Service via various
            online Third-Party Services, such as social media and social
            networking services like Discord. Your access and use of the
            Third-Party Services may also be subject to additional terms and
            conditions, privacy policies, or other agreements with such third
            party, and you may be required to authenticate to or create separate
            accounts to use Third-Party Services on the websites or via the
            technology platforms of their respective providers. Some Third-
            Party Services will provide us with access to certain information
            that you have provided to third parties, including through such
            Third-Party Services, and we will use, store and disclose such
            information in accordance with our Privacy Policy. For more
            information about the implications of activating Third-Party
            Services and our use, storage and disclosure of information related
            to you and your use of such Third-Party Services within the Service,
            please see our Privacy Policy. Digikore has no control over and is
            not responsible for such Third-Party Services, including for the
            accuracy, availability, reliability, or completeness of information
            shared by or available through Third-Party Services, or on the
            privacy practices of Third-Party Services. We encourage you to
            review the privacy policies of the third parties providing
            Third-Party Services prior to using such services. You, and not
            Digikore, will be responsible for any and all costs and charges
            associated with your use of any Third-Party Services. Digikore
            enables these Third-Party Services merely as a convenience and the
            integration or inclusion of such Third-Party Services does not imply
            an endorsement or recommendation. Any dealings you have with third
            parties while using the Service are between you and the third party.
            Digikore will not be responsible or liable, directly or indirectly,
            for any damage or loss caused or alleged to be caused by or in
            connection with use of or reliance on any Third- Party Services.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Indemnification{" "}
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            To the extent permitted under applicable law, you agree to defend,
            indemnify, and hold harmless Digikore, its affiliates, and its and
            their respective officers, employees, directors, service providers,
            licensors, and agents (collectively, the &quot;Digikore
            Parties&quot;) from any and all losses, damages, expenses, including
            reasonable attorneys&#39; fees, rights, claims, actions of any kind,
            and injury (including death) arising out of or relating to your use
            of the Service, any Content or use thereof, your connection to the
            Service, your violation of these Terms of Service, or your violation
            of any rights of another, including through your generation or use
            of any Output. Digikore will provide notice to you of any such
            claim, suit, or proceeding. Digikore reserves the right to assume
            the exclusive defense and control of any matter which is subject to
            indemnification under this section, and you agree to cooperate with
            any reasonable requests assisting Digikore&#39;s defense of such
            matter. You may not settle or compromise any claim against the
            Digikore Parties without Digikore&#39;s written consent.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Disclaimer of Warranties{" "}
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            YOUR USE OF THE SERVICE AND ANY OUTPUT IS AT YOUR SOLE RISK. THE
            SERVICE AND ANY OUTPUT IS PROVIDED ON AN &quot;AS IS&quot; AND
            &quot;AS AVAILABLE&quot; BASIS. THE DIGIKORE PARTIES EXPRESSLY
            DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR
            STATUTORY, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            THE DIGIKORE PARTIES MAKE NO WARRANTY THAT (A) THE SERVICE WILL MEET
            YOUR REQUIREMENTS; (B) THE SERVICE WILL BE UNINTERRUPTED, TIMELY,
            SECURE, OR ERROR-FREE; (C) THE RESULTS THAT MAY BE OBTAINED FROM THE
            USE OF THE SERVICE WILL BE SUITABLE, ACCURATE OR RELIABLE; (D) YOUR
            USE OF ANY OUTPUT WILL NOT INFRINGE THE RIGHTS OF ANY THIRD PARTY;
            OR (D) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER
            MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET
            YOUR EXPECTATIONS. YOU ACKNOWLEDGE THAT THE SERVICE USES
            EXPERIMENTAL TECHNOLOGY AND MAY SOMETIMES PROVIDE INACCURATE OR
            OFFENSIVE CONTENT THAT DOES NOT REPRESENT THE VIEWS OF THE DIGIKORE
            PARTIES.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            Due to the nature of artificial intelligence and machine learning,
            your Output may not be unique and the Service may generate the same
            or similar output for a third party. Other users may provide similar
            input to the Service and receive the same or similar output from the
            Service. An input that is submitted by other users is not your
            Submission, and an output that is requested by and generated for
            other users is not your Output.
          </p>
        </div>

        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Limitation of Liability{" "}
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE DIGIKORE PARTIES WILL
            NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
            EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING DAMAGES
            FOR LOSS OF GOODWILL, USE, OR DATA OR OTHER INTANGIBLE LOSSES (EVEN
            IF THE DIGIKORE PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
            LIABILITY, OR OTHERWISE, RESULTING FROM: (A) THE USE OR THE
            INABILITY TO USE THE SERVICE OR ANY OUTPUT; (B) THE COST OF
            PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY
            GOODS, DATA, INFORMATION, OR SERVICES PURCHASED OR OBTAINED OR
            MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE
            SERVICE; (C) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR CONTENT,
            TRANSMISSIONS OR DATA; (D) STATEMENTS OR CONDUCT OF ANY THIRD PARTY
            ON THE SERVICE; OR (E) ANY OTHER MATTER RELATING TO THE SERVICE. IN
            NO EVENT WILL THE DIGIKORE PARTIES&#39; TOTAL LIABILITY TO YOU FOR
            ALL DAMAGES, LOSSES, OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE
            PAID DIGIKORE IN THE LAST SIX (6) MONTHS, OR, IF GREATER, ONE
            HUNDRED DOLLARS ($100).
          </p>
          <p className="text-lg text-gray-200 mb-8">
            SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF
            CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
            INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE
            LIMITATIONS OR DISCLAIMERS SET FORTH ABOVE MAY NOT APPLY TO YOU OR
            BE ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE DISSATISFIED WITH ANY
            PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE AND
            EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICE.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Dispute Resolution By Binding Arbitration
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Agreement to Arbitrate{" "}
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            This Dispute Resolution by Binding Arbitration section is referred
            to in these Terms of Service as the "Arbitration Agreement." You
            agree that any and all disputes or claims that have arisen or may
            arise between you and Digikore, whether arising out of or relating
            to these Terms of Service (including any alleged breach thereof),
            the Service, any Content, any advertising, or any aspect of the
            relationship or transactions between us, will be resolved
            exclusively arbitration in accordance with the provisions of the
            <span className="font-bold">
              {" "}
              Arbitration and Conciliation Act, 1996,{" "}
            </span>
            as amended from time to time (the 'Act').", rather than a court, in
            accordance with the terms of this Arbitration Agreement, except that
            you may assert individual claims in small claims court, if your
            claims qualify. You agree that, by entering into these Terms of
            Service, you and Digikore are each waiving the right to a trial by
            jury or to participate in a class action. Your rights will be
            determined by a neutral arbitrator, not a judge or jury. The
            Arbitration and Conciliation Act, 1996 governs the interpretation
            and enforcement of this Arbitration Agreement.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Prohibition of Class and Representative Actions and
            Non-Individualized Relief{" "}
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            YOU AND DIGIKORE AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE
            OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR
            PROCEEDING. UNLESS BOTH YOU AND DIGIKORE AGREE OTHERWISE, THE
            ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON&#39;S OR
            PARTY&#39;S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
            CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE
            ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
            DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING
            RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF
            NECESSITATED BY THAT PARTY&#39;S INDIVIDUAL CLAIM(S), EXCEPT THAT
            YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR MAY AWARD PUBLIC
            INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO THE EXTENT REQUIRED FOR
            THE ENFORCEABILITY OF THIS PROVISION.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Arbitration Institution and Rules
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            &quot;The arbitration shall be conducted in accordance with the
            Arbitration and Conciliation Act, 1996, Arbitration Rules of the
            Indian Council of Arbitration (ICA), or if mutually agreed by the
            parties, under the International Chamber of Commerce (ICC) or London
            Court of International Arbitration (LCIA) Rules, in force at the
            time of the dispute. If the parties do not agree on an institution,
            the dispute shall be resolved by ad hoc arbitration under the rules
            as provided in the Act.&quot;
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Seat and Venue of Arbitration
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            &quot;The seat of arbitration shall be Pune, Maharashtra India. The
            place of arbitration, where hearings and proceedings may take place,
            shall be determined by the arbitral tribunal, but the legal seat of
            arbitration shall be in Maharashtra, India. The courts of Pune,
            Maharashtra shall have exclusive jurisdiction to supervise the
            arbitration proceedings, including interim measures, under the
            Act.&quot;
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Number of Arbitrators
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            &quot;The arbitration shall be conducted by a sole arbitrator or a
            panel of three arbitrators by mutual agreement. In case the parties
            mutually decided to appoint a panel of arbitrators then each party
            shall appoint one arbitrator, and the two arbitrators so appointed
            shall jointly appoint the third arbitrator, who shall act as the
            presiding arbitrator. If the two party-appointed arbitrators fail to
            agree on the appointment of the third arbitrator within 30 days, the
            third arbitrator shall be appointed by the Indian Council of
            Arbitration (ICA), or the arbitration institution chosen by the
            parties.&quot;
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Language of Arbitration
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            "The language of the arbitration shall be English, unless otherwise
            agreed by the parties. All documents and communications shall be
            submitted in English, or translated into English."
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Interim Relief
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            &quot;Notwithstanding the agreement to arbitrate, either party may
            apply to a competent court in Pune, Maharashtra India for interim or
            conservatory measures under Section 9 of the Arbitration and
            Conciliation Act, 1996, prior to the appointment of the arbitral
            tribunal, and the courts of{" "}
            <span className="font-bold">Pune, India</span> shall have
            jurisdiction for such applications.&quot;
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Enforceability of Arbitral Awards
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            "The arbitral award shall be final and binding on both parties, and
            the parties agree to comply with the award. The award may be
            enforced in any court of competent jurisdiction in India or abroad.
            The parties acknowledge that the award is enforceable under the New
            York Convention on the Recognition and Enforcement of Foreign
            Arbitral Awards, 1958, and may be enforced in any jurisdiction that
            is a signatory to the Convention, including both India and the
            relevant foreign jurisdictions."
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Jurisdiction of Courts for Enforcement
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            &quot;For the purposes of enforcing the arbitral award or any
            interim measure ordered by the arbitral tribunal, the courts of
            Pune, India shall have exclusive jurisdiction. In the event of any
            challenge to the award, such challenge shall be heard by the
            competent courts in Pune, India, subject to the grounds for
            challenge under Section 34 of the Arbitration and Conciliation Act,
            1996.&quot;
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Confidentiality
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            &quot;The parties agree that the arbitration proceedings, including
            any interim orders, documents exchanged, and the final award, shall
            remain confidential, unless disclosure is required by law or for the
            purpose of enforcement of the award.&quot;
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Compliance with Applicable International Treaties
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            &quot;The parties agree that the arbitration shall be conducted in
            compliance with the provisions of the Arbitration and Conciliation
            Act, 1996 and the relevant international treaties, including the New
            York Convention on the Recognition and Enforcement of Foreign
            Arbitral Awards, 1958, to which both India and the relevant foreign
            jurisdictions are signatories.&quot;
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Pre-Arbitration Dispute Resolution
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            Digikore is always interested in resolving disputes amicably and
            efficiently, and most customer concerns can be resolved quickly and
            to the customer&#39;s satisfaction by emailing customer support at {" "}
            <a className="text-blue-600" href="mailto: support@imadeasong.com">
              support@imadeasong.com
            </a>
            If such efforts prove unsuccessful, a party who intends to seek
            arbitration must first send to the other, by certified mail, a
            written Notice of Dispute (&quot;Notice&quot;). The Notice to
            Digikore should be sent to 4 th Floor, Lalwani Triumph, Sakore
            Nagar, Viman Nagar, Pune 411014 (&quot;Notice Address&quot;). The
            Notice must (i) describe the nature and basis of the claim or
            dispute and (ii) set forth the specific relief sought. If Digikore
            and you do not resolve the claim within sixty (60) calendar days
            after the Notice is received, you or Digikore may commence an
            arbitration proceeding. During the arbitration, the amount of any
            settlement offer made by Digikore or you will not be disclosed to
            the arbitrator until after the arbitrator determines the amount, if
            any, to which you or Digikore is entitled.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Costs of Arbitration
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            Payment of all filing, administration, and arbitrator fees
            (collectively, the &quot;Arbitration Fees&quot;) will be governed by
            the Arbitration and Conciliation Act, 1996, unless otherwise
            provided in this Arbitration Agreement. To the extent any
            Arbitration Fees are not specifically allocated to either Digikore
            or you under the Arbitration Rules, Digikore and you shall split
            them equally; provided that if you are able to demonstrate to the
            arbitrator that you are economically unable to pay your portion of
            such Arbitration Fees or if the arbitrator otherwise determines for
            any reason that you should not be required to pay your portion of
            any Arbitration Fees, Digikore will pay your portion of such fees.
            In addition, if you demonstrate to the arbitrator that the costs of
            arbitration will be prohibitive as compared to the costs of
            litigation, Digikore will pay as much of the Arbitration Fees as the
            arbitrator deems necessary to prevent the arbitration from being
            cost-prohibitive. Any payment of attorneys&#39; fees will be
            governed by the Arbitration and Conciliation act, 1996.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Severability
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            If a court or the arbitrator, in accordance with the Arbitration and
            Conciliation Act, 1996 (India), determines that any term or
            provision of this Arbitration Agreement (other than the
            &quot;Prohibition of Class and Representative Actions and
            Non-Individualized Relief&quot;) is invalid, illegal, or
            unenforceable, the parties agree to replace such term or provision
            with a term or provision that is valid, legal, and enforceable, and
            that comes closest to reflecting the original intent of the invalid
            or unenforceable term or provision. In such a case, this Arbitration
            Agreement shall remain enforceable as modified.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            If a court or the arbitrator determines that any provision of
            subsection titled &quot;Prohibition of Class and Representative
            Actions and Non-Individualized Relief&quot; is invalid or
            unenforceable, then the entirety of this Arbitration Agreement shall
            be null and void, unless such provisions are deemed invalid or
            unenforceable solely with respect to claims for public injunctive
            relief under Indian law. Notwithstanding the above, the remainder of
            these Terms of Service shall continue to apply and remain in force.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Future Changes to Arbitration Agreement
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            Notwithstanding any provision in these Terms of Service to the
            contrary, Digikore agrees that if it makes any future change to this
            Arbitration Agreement (other than a change to the Notice Address)
            while you are a user of the Service, you may reject any such change
            by sending Digikore written notice within thirty (30) calendar days
            of the change to the Notice Address provided above. By rejecting any
            future change, you are agreeing that you will arbitrate any dispute
            between us in accordance with the language of this Arbitration
            Agreement as of the date you first accepted these Terms of Service
            (or accepted any subsequent changes to these Terms of Service).
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            Termination
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            You agree that Digikore, in its sole discretion, may suspend or
            terminate your account (or any part thereof) or use of the Service
            and remove and discard any content (including your Content) within
            the Service, for any reason in Digikore’s sole discretion, including
            for lack of use or if Digikore believes that you have violated or
            acted inconsistently with the letter or spirit of these Terms of
            Service. Any suspected fraudulent, abusive, or illegal activity that
            may be grounds for termination of your use of the Service, may be
            referred to appropriate law enforcement authorities. Digikore may
            also in its sole discretion and at any time discontinue or suspend
            providing the Service, or any part thereof, with or without notice.
            You agree that any termination of your access to the Service under
            any provision of these Terms of Service may be effected without
            prior notice, and acknowledge and agree that Digikore may
            immediately deactivate or delete your account and all related
            information and files in your account and/or bar any further access
            to such files or the Service. Further, you agree that Digikore will
            not be liable to you or any third party for any termination of your
            access to the Service.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            SMS Messaging
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            Certain portions of the Service may allow us to contact you via
            telephone or text messages. You agree that we may contact you via
            telephone or text messages (including by an automatic telephone
            dialing system) at any of the phone numbers provided by you or on
            your behalf in connection with your use of the Service, including
            for marketing purposes. You understand that you are not required to
            provide this consent as a condition of purchasing any paid Service.
            You also understand that you may opt out of receiving text messages
            from us at any time, either by texting a reply with the word “STOP”
            using the mobile device that is receiving the messages, or by
            contacting{" "}
            <a className="text-blue-600" href="mailto: support@imadeasong.com">
              support@imadeasong.com {" "}
            </a>
            If you do not choose to opt out, we may contact you as outlined in
            our Privacy Policy.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            User Disputes
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            You agree that you are solely responsible for your interactions with
            any other user in connection with the Service, and Digikore will
            have no liability or responsibility with respect thereto. Digikore
            reserves the right, but has no obligation, to become involved in any
            way with disputes between you and any other user of the Service.
          </p>
        </div>
        <div>
          <h6 className="text-3xl text-start font-bold text-white mb-6">
            General
          </h6>
          <p className="text-lg text-gray-200 mb-8">
            These Terms of Service (together with the terms incorporated by
            reference herein) constitute the entire agreement between you and
            Digikore governing your access and use of the Service, and supersede
            any prior agreements between you and Digikore with respect to the
            Service. You also may be subject to additional terms and conditions
            that may apply when you use Third-Party Services, third-party
            content or third-party software. These Terms of Service will be
            governed by the laws of the Commonwealth of Massachusetts without
            regard to its conflict of law provisions. With respect to any
            disputes or claims not subject to arbitration, as set forth above,
            you and Digikore submit to the personal and exclusive jurisdiction
            of the state and federal courts located within Massachusetts. The
            failure of Digikore to exercise or enforce any right or provision of
            these Terms of Service will not constitute a waiver of such right or
            provision. If any provision of these Terms of Service is found by a
            court of competent jurisdiction to be invalid, the parties
            nevertheless agree that the court should endeavor to give effect to
            the parties&#39; intentions as reflected in the provision, and the
            other provisions of these Terms of Service remain in full force and
            effect. You agree that regardless of any statute or law to the
            contrary, any claim or cause of action arising out of or related to
            use of the Service or these Terms of Service must be filed within
            one (1) year after such claim or cause of action arose or be forever
            barred. A printed version of these Terms of Service and of any
            notice given in electronic form will be admissible in judicial or
            administrative proceedings based upon or relating to these Terms of
            Service to the same extent and subject to the same conditions as
            other business documents and records originally generated and
            maintained in printed form. You may not assign these Terms of
            Service without the prior written consent of Digikore, but Digikore
            may assign or transfer these Terms of Service, in whole or in part,
            without restriction. The section titles in these Terms of Service
            are for convenience only and have no legal or contractual effect. As
            used in these Terms of Service, the words &quot;include&quot; and
            &quot;including,&quot; and variations thereof, will not be deemed to
            be terms of limitation, but rather will be deemed to be followed by
            the words &quot;without limitation.&quot; Notices to you may be made
            via either email or regular mail. The Service may also provide
            notices to you of changes to these Terms of Service or other matters
            by displaying notices or links to notices generally on the Service.
            Digikore will not be in default hereunder by reason of any failure
            or delay in the performance of its obligations where such failure or
            delay is due to civil disturbances, riot, epidemic, hostilities,
            war, terrorist attack, embargo, natural disaster, acts of God,
            flood, fire, sabotage, fluctuations or unavailability of electrical
            power, network access or equipment, or any other circumstances or
            causes beyond Digikore&#39;s reasonable control.
          </p>
          <p className="text-lg text-gray-200 mb-8">
            Questions? Concerns? Suggestions?
          </p>
          <p className="text-lg text-gray-200 mb-8">
            Please contact us at{" "}
            <a className="text-blue-600" href="mailto: support@imadeasong.com">
              support@imadeasong.com
            </a>{" "}
            or 4th Floor, Lalwani Triumph, Sakore Nagar, Viman Nagar, Pune,
            Maharashtra 411014, India to report any violations of these Terms of
            Service or to pose any questions regarding these Terms of Service or
            the Service.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
