import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import brandImage from "../assets/dashboard/brand_img.png";
import { logout } from "../redux/ReduxSlices/UserSlice";
import "../styles/navbar.css";

const ls = new SecureLS();

const Navbar = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    const savedCredentials = ls.get("credentials");

    localStorage.clear();
    if (savedCredentials) {
      ls.set("credentials", savedCredentials);
    }

    dispatch(logout());
    navigate("/signin");
  };

  return (
    <nav className="w-full flex items-center justify-between py-2 px-6 bg-black fixed top-0 z-10">
      {/* Logo */}
      <div
        className="flex items-center sm:order-1 order-2 sm:justify-start justify-end w-full sm:w-auto"
      >
        <Link to="/">
          <img
            src={brandImage}
            alt="I Made a Song"
            className="h-12 w-20 cursor-pointer sm:h-16 sm:w-24"
          />
        </Link>
      </div>

      {/* Navbar Links - Hidden on mobile */}
      <div className="hidden sm:flex gap-16 mx-auto sm:order-2">
        <Link
          to="/dashboard"
          className="text-white hover:text-[#b2e668] transition duration-300 title-link"
        >
          Create
        </Link>

        <Link
          to="/faq"
          className="text-white hover:text-[#b2e668] transition duration-300 title-link"
        >
          FAQ
        </Link>
        <Link
          to="/upgrade-plan"
          className="text-white hover:text-[#b2e668] transition duration-300 title-link"
        >
          Pricing
        </Link>
        <Link
          to="/contact"
          className="text-white hover:text-[#b2e668] transition duration-300 title-link"
        >
          Contact Us
        </Link>
      </div>

      {/* Navbar Right Side - Log Out button hidden on mobile */}
      <div className="flex gap-4 items-center sm:gap-6 sm:order-3 order-1">
        {isLoggedIn ? (
          <button
            onClick={handleLogout}
            className="hidden sm:block text-white btn-border py-2 px-4 rounded hover:text-[#00aaff] hover:border-[#00aaff]"
          >
            Log Out
          </button>
        ) : (
          <Link
            to="/signin"
            className="hidden sm:block text-white btn-border py-2 px-4 rounded hover:text-[#00aaff] hover:border-[#00aaff]"
          >
            Sign In
          </Link>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
