import React, { useState } from 'react';

const paymentMethods = [
    { name: "Card", icon: "https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/2f202595f408e1db90a2b8a21d7961f44323483f56b3f8e88a65912db1972d01?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&", },
    { name: "UPI", icon: "https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/9e4945d0bf478f70da061aede6ddafc0398edfe3762a80a5fee5f8c159090876?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&" },
    { name: "Wallet", icon: "https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/cb199f0535ea7e337fc6c282ee5ddab95210bdfef043e500530a2c65a9af67a6?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&" }
];

function PaymentMethod() {
    const [selectedMethod, setSelectedMethod] = useState(paymentMethods[0].name);

    const handleSelect = (methodName) => {
        setSelectedMethod(methodName);
    };

    return (
        <div className="flex flex-col items-start mt-4">
            <h3 className="self-start text-3xl font-semibold text-white mb-3 max-md:text-2xl">Payment Info</h3>
            {/* <div className="flex gap-2 items-start self-stretch mt-4">
                {paymentMethods.map((method, index) => (
                    <div
                        key={index}
                        onClick={() => handleSelect(method.name)}
                        className={`cursor-pointer flex flex-col px-4 py-2 rounded-lg border ${selectedMethod === method.name ? 'border-teal-300' : 'border-zinc-700'} border-solid bg-zinc-800 ${selectedMethod === method.name ? 'w-[140px]' : 'w-[90px] min-h-[64px] justify-center items-center'}`}
                    >
                        <div className={`flex ${selectedMethod === method.name ? 'gap-5 justify-between items-start self-end w-[97px]' : ''}`}>
                            <img loading="lazy" src={method.icon} alt="" className="object-contain shrink-0 w-6 aspect-square" />
                            {method.checkIcon && selectedMethod === method.name && (
                                <img loading="lazy" src={method.checkIcon} alt="" className="object-contain shrink-0 w-4 aspect-square" />
                            )}
                        </div>
                        <div className={`mt-1.5 text-sm font-medium leading-tight ${selectedMethod === method.name ? 'text-white' : 'text-gray-400'}`}>
                            {method.name}
                        </div>
                    </div>
                ))}
            </div> */}
        </div>
    );
}

export default PaymentMethod;
