import React from "react";


function Footer() {
  return (
    <footer className="flex flex-col justify-center items-center py-8 sm:mt-0 w-full max-w-full max-md:mt-0 bg-black footer-bottom-margin">
      <div className="flex flex-col justify-center p-6 w-full max-w-screen-xl max-md:px-5">
        <div className="flex flex-wrap gap-6 items-start w-full">
          <div className="flex flex-col flex-1 shrink text-white basis-0 min-w-[240px] max-md:max-w-full">
            <div className="flex flex-col w-full">
              <h3 className="text-2xl font-medium tracking-tight">
                Contact
              </h3>
              <div className="flex flex-col mt-2 w-full text-lg font-light tracking-normal text-blue-400">
                <p>
                  <a href="mailto:support@imadeasong.com">
                    support@imadeasong.com
                  </a>
                </p>
              </div>
            </div>

          </div>
          <div className="flex flex-col flex-1 shrink text-white basis-0 min-w-[240px] max-md:max-w-full">
            <div className="flex flex-col w-full">
              <h3 className="text-2xl font-medium tracking-tight">
                Address
              </h3>
              <address className="mt-2 text-gray-500 text-start sm:text-left not-italic">
                Digikore Studios Limited, Lalwani Triumph,
                Viman Nagar,
                Pune, MH 411014
              </address>
            </div>
          </div>
          <div className="flex items-end self-stretch h-full w-auto hidden sm:block">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/e7b11648bfd2a338c59ea9c7d9fb8adb18b010bcdd15af8d0d5b84189d2457c8?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&"
              alt="Company logo"
              className="object-contain aspect-[1.59] w-[146px] max-md:w-[120px] mx-auto"
            />
          </div>
        </div>
      </div>
      <div className="w-full max-w-screen-xl mx-auto flex flex-col sm:flex-row justify-between items-center border-t border-gray-700 pt-3">
        <p className="text-gray-500 text-center sm:text-left">
          Copyright © 2024 Digikore Studios Limited
        </p>
        <div className="flex flex-wrap gap-4 justify-center sm:justify-end mt-3 sm:mt-0">
          <a href="/terms-of-service" className="text-gray-200 hover:underline">
            Terms of Services
          </a>
          <a href="/privacy-policy" className="text-gray-200 hover:underline">
            Privacy Policy
          </a>
          <a href="/cancellation-policy" className="text-gray-200 hover:underline">
            Cancellation and Refund Policy
          </a>
          <a href="/about-us" className="text-gray-200 hover:underline">
            About Us
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
