import React from 'react';


function ActionButton({ text, imageSrc, onClick, isActive }) {
  return (
    <button className={`flex ${isActive ? "custom-button text-black " : "faq-button text-white "} gap-5 justify-between p-4 mt-12 text-2xl font-extrabold rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full max-md:p-2 max-md:mt-8 max-md:font-bold `} onClick={onClick}>
      <span className=''>{text}</span>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" className="size-7">
        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
      </svg>

      {/* <img
        loading="lazy"
        src={imageSrc}
        alt=""
        className="object-contain w-[33px]"
      /> */}
    </button>
  );
}

export default ActionButton;