import Sidebar from "./Sidebar";
import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import Payment from "../Payment/PaymentPopup";
import SongList from "../VoiceCreation/SongList";
import GetStartedPage from "../VoiceCreation/GetStartedPage";
import GetAllVoice from "../VoiceCreation/GetAllVoice";
import SongGeneratedPage from "../VoiceCreation/SongGeneratedPage";
import { motion } from 'framer-motion';
import Settings from '../settings/settings.jsx';
import VideoCollage from '../VideoCollage/VideoCollagePage.jsx';

function CreateMelody() {
  const [activeContent, setActiveContent] = useState("Create song");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  return (
    <main className="overflow-hidden  bg-neutral-900 relative">
      {/* Toggle Button for Small Screens */}
      <button
        className="md:hidden fixed top-4 left-4 z-50 text-white navbar-menu-logo"
        onClick={handleSidebarToggle}
      >
        <FaBars size={24} />
      </button>
      <div className="flex max-md:flex-col">
        <motion.div
          initial={{ x: '-100%' }}
          animate={{ x: 0 }}
          transition={{ duration: .2 }}
          className="w-[15%] max-md:ml-0 max-md:w-full bg-black position-sticky">
          <div
            className={`fixed inset-0 z-40 transition-transform md:static md:translate-x-0 ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
              }`}
          >
            <Sidebar
              onItemClick={setActiveContent}
              onClose={handleSidebarClose}
            />
          </div>
        </motion.div>

        <div className="ml-5 w-[85%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col mt-8  w-full max-md:mt-2 max-md:max-w-full">
            <div className="mt-6 all-list-height">
              {/* Conditionally render components based on activeContent */}
              {activeContent === "Create song" && <SongGeneratedPage />}
              {activeContent === "Library" && <GetAllVoice />}
              {activeContent === 'Profile' && <Settings />}
              {activeContent === 'Create Video' && <VideoCollage />}
            </div>
          </div>
        </div>
      </div>
    </main >
  );
}

export default CreateMelody;
