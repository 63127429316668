import { motion } from "framer-motion";
import moment from "moment";
import React, { useEffect } from "react";
import { FaHeart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import openLightbox from "../../assets/dashboard/bannerImg.jpg";
import MusicImage from "../../assets/dashboard/music.png";
import DashboardBanner from "../../assets/partners_images/dashboard_banner.webp";
import DashboardSubscription from "../../components/DashboardSubscription";
import { fetchIPData, postIpData } from "../../redux/ReduxSlices/ipGetInfo.jsx";
import "../../styles/index.css";
import SongType from "./SongTypes.jsx";

function HomePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.ipData);
  const token = localStorage.getItem("token");

  const handleGenrate = () => {
    if (token) {
      navigate("/dashboard");
    } else {
      toast.info("Please sign in to continue.", { autoClose: 1000 });

      setTimeout(() => {
        navigate("/signin");
      }, 1000);
    }
  };

  const currentDateTime = moment().format("MMMM Do YYYY, h:mm:ss a");

  useEffect(() => {
    dispatch(fetchIPData());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      // Build the payload
      const payload = {
        skey: "a1b2c3d4e5f67890abcdef1234567890abcdef12YWJjMTIzNDU2Nzg5QUJDMTIzNDU2Nzg5IV4mQCNA",
        ip_address: data.ip,
        network: data.network || "Unknown", // Default if network is not present
        city: data.city,
        country_name: data.country_name,
        continental_code: data.continent_code,
        latitude: data.latitude.toString(),
        longitude: data.longitude.toString(),
        date_time: currentDateTime, // Current date and time
        device: navigator.userAgent, // Get the device details from the browser
      };

      // Dispatch the post action with the payload
      dispatch(postIpData(payload));
    }
  }, [data, dispatch]);
  // #1 Personalized Song Generator

  return (
    <>
      <section class="song-creation-section">
        <motion.div
          initial={{ y: "-100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
          className="badge"
        >
          #1 Personalized Song Generator
        </motion.div>
        <motion.div
          initial={{ y: "-100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
          className="text-white flex justify-center items-center text-l font-bold  mt-4 md:mt-0 "
        >
          Made with{" "}
          <a className="ml-2 mr-2">
            <FaHeart style={{ color: "red" }} />
          </a>{" "}
          in India
        </motion.div>

        <motion.h1
          initial={{ y: "-100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
          class="main-title"
        >
          Create an Unforgettable Personal Gift
        </motion.h1>
        <motion.p
          initial={{ y: "-100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
          class="subtitle"
        >
          Turn your words into music—no music experience needed. Just select,
          tune, and share your song with the world!
        </motion.p>
        <motion.div
          initial={{ y: "-100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
          className="text-white text-center text-4xl sm:text-5xl mt-4 font-semibold"
        >
          Say it with a Song
        </motion.div>
        <motion.div
          initial={{ y: "-100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
          class="cta-buttons"
        >
          <button onClick={handleGenrate} class="create-melody-btn ">
            Create a Melody
          </button>
        </motion.div>

        <div class="hero-image">
          <motion.img
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            transition={{ duration: 0.3 }}
            src="https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/faa0f3a54b7a454cca549220f4c04d701ce25a8104dffdc1f6f35c4c3b4fed1a?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&"
            alt="Hero Left"
            class="hero-image-left"
          />
          <motion.div
            initial={{ scale: 0.2 }}
            animate={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 100, damping: 10 }}
            class="hero-image-center"
          >
            <img src={MusicImage} alt="Hero Background" class="hero-image-bg" />
          </motion.div>
          <motion.img
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
            src="https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/9ef777fe9a60ecbb1566266b496c02829449b0651d639b09ba6bde7e123d2290?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&"
            alt="Hero Right"
            class="hero-image-right"
          />
        </div>

        <section class="features-section">
          <div class="features-container">
            <div class="features-title">
              <motion.div
                initial={{ y: "-100%" }}
                animate={{ y: 0 }}
                transition={{ duration: 2 }}
                class="features-content"
              >
                <h2 class="features-heading">
                  Create a Song for any Occasion or Celebration in Under 2
                  Minutes
                </h2>
                {/* <p class="features-description">
                  Simply type in your message, sit back, and our AI will take
                  care of the rest. Let nothing hold back your creativity,
                  innovation and emotions!
                </p> */}
              </motion.div>
            </div>
            <div>
              <img
                src={openLightbox}
                alt="Features Background"
                class="features-image-bg"
              />
            </div>
          </div>
        </section>

        <SongType />

        <div class="features-grid">
          <div class="features-row">
            <div class="feature-card">
              <div class="feature-card-wrapper">
                <div class="feature-content">
                  <div class="flex-bar">
                    <div class="feature-icon">
                      <img
                        src="https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/a16de6c3bb616dcae46a0407490f59cc96da8639f1713acda3f35a3a8f374f2d?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&"
                        alt="Mood-Based Song Generator Icon"
                        class="feature-icon-img"
                      />
                    </div>
                    <h3 class="feature-title">Custom Song</h3>
                  </div>
                  <p class="feature-description">
                    Transform any text or personal message into a one-of-a-kind
                    song with I Made a Song's AI-powered text-to-song generator.
                    Create a Song in any Language!
                  </p>
                  <div class="feature-actions">
                    {/* <button class="feature-button">View dashboard</button> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="feature-card">
              <div class="feature-card-wrapper">
                <div class="flex-bar">
                  <div class="feature-icon">
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/a16de6c3bb616dcae46a0407490f59cc96da8639f1713acda3f35a3a8f374f2d?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&"
                      alt="Mood-Based Song Generator Icon"
                      class="feature-icon-img"
                    />
                  </div>
                  <h3 class="feature-title">AI Lyrics Writer</h3>
                </div>
                <div class="feature-content">
                  <p class="feature-description">
                    Effortlessly create meaningful lyrics for any genre or
                    theme. From social media shout-outs to personal dedications,
                    let AI help you craft compelling lyrics that make every song
                    unforgettable.
                  </p>
                  <div class="feature-actions">
                    {/* <button class="feature-button">View dashboard</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="features-row mt-4">
            <div class="feature-card">
              <div class="feature-card-wrapper">
                <div class="flex-bar">
                  <div class="feature-icon">
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/a16de6c3bb616dcae46a0407490f59cc96da8639f1713acda3f35a3a8f374f2d?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&"
                      alt="Mood-Based Song Generator Icon"
                      class="feature-icon-img"
                    />
                  </div>
                  <h3 class="feature-title">Batch Song Creation</h3>
                </div>
                <div class="feature-content">
                  <p class="feature-description">
                    Generate multiple versions of your song in one go. Explore
                    different genres, moods and vocal styles and find the one
                    that resonates best. Perfect for testing and refining your
                    musical ideas!
                  </p>
                  <div class="feature-actions">
                    {/* <button class="feature-button">View dashboard</button> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="feature-card">
              <div class="feature-card-wrapper">
                <div class="flex-bar">
                  <div class="feature-icon">
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/a16de6c3bb616dcae46a0407490f59cc96da8639f1713acda3f35a3a8f374f2d?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&"
                      alt="Mood-Based Song Generator Icon"
                      class="feature-icon-img"
                    />
                  </div>
                  <h3 class="feature-title">Mood-Based Song Generator</h3>
                </div>
                <div class="feature-content">
                  <p class="feature-description">
                    Create a song based on your current mood or emotion. Whether
                    you're feeling happy, nostalgic or relaxed, let our AI
                    craft the perfect melody and lyrics to match your vibe.
                  </p>
                  <div class="feature-actions">
                    {/* <button class="feature-button">View dashboard</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src={DashboardBanner}
          alt="banner-image"
          className="dashboard-banner-height"
          onClick={() => {
            navigate("/partners");
            window.scrollTo(0, 0); // Reset scroll to the top
          }}
        />
        <div className="mt-4 lg:mt-8"> </div>
        <DashboardSubscription />
      </section>
      <ToastContainer />
    </>
  );
}

export default HomePage;
