import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { motion } from 'framer-motion';
import { plansRegular } from '../utils/regularSubscriptionPlan.js'

// Used of Dashboard

function DashboardSubscription() {

    const [isYearly, setIsYearly] = useState(false);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const handleSubscription = () => {
        if (token) {
            navigate('/upgrade-plan');
        } else {
            toast.info("Please sign in to continue.", { autoClose: 1000 });
            setTimeout(() => {
                navigate("/signin");
            }, 1000);
        }
    }

    return (
        <div className="text-white flex flex-col items-center p-4 max-md:p-0 sm:p-8">
            <motion.div
                initial={{ y: '-100%' }}
                animate={{ y: 0 }}
                transition={{ duration: .3 }}
                className="flex flex-col justify-center items-center p-8 sm:p-16 w-full text-center">
                <div className="flex flex-col max-w-full">
                    <h2 className="self-center text-4xl sm:text-5xl font-bold leading-tight mb-6">
                        Choose the plan that works for you
                    </h2>
                    <p className="text-lg sm:text-xl leading-7 max-w-3xl mx-auto">
                        Pick the perfect plan for you and start enjoying all our website has to offer! Whether you're looking for essential features or the full experience, we have a plan that fits your needs. Dive in and make the most of your Plan! </p>
                </div>
            </motion.div>
            <div className="grid grid-cols-1 p-2 lg:p-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 w-full">
                {plansRegular.map((plan, index) => (
                    <div
                        key={index}
                        className="p-6 sm:p-8 rounded-lg flex flex-col items-center shadow-md"
                    >
                        <h3 className="text-xl sm:text-xl text-center text-transparent font-extrabold mb-4 bg-clip-text bg-gradient-to-r from-green-500 via-teal-500 to-pink-500">{plan.name}</h3>
                        <p className="text-xl sm:text-2xl font-bold mb-4"> {plan.yearlyPrice == "Free" ? "" : "Rs."} {isYearly ? plan.yearlyPrice : plan.monthlyPrice}</p>
                        <button onClick={handleSubscription} className="custom-button mb-4 px-6 py-3 rounded-full w-full">
                            {plan.status ? plan.status : 'Choose Plan'}
                        </button>
                        <ul className="text-sm sm:text-base space-y-2">
                            {plan.features.map((feature, i) => (
                                <li key={i} className="flex items-start">
                                    <span className="mr-2">•</span> {feature}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div >
    )
}

export default DashboardSubscription;
