import React from 'react';

function SubscriptionDetails({ selectedPlan }) {

    return (
        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col items-start w-full max-md:mt-10">
                <div className="flex flex-col mt-4 max-md:w-full w-[416px]">
                    <h1 className="text-3xl font-semibold leading-10 text-white max-md:text-2xl">
                        Subscribe to I Made a Song <br />
                    </h1>
                    <p className="z-10 self-start mt-2 lg:mt-8 text-base font-medium leading-snug text-zinc-300 max-md:mt-4">
                        {selectedPlan.name}
                    </p>
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/1ace08d81d1886966a1ba1a553e5525642703b4e277ab9ae4e132f87504cba7c?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&"
                        alt=""
                        className="object-contain mt-3.5 w-full aspect-[500] stroke-[1px] stroke-zinc-700 max-md:mt-6"
                    />
                </div>
                <div className="flex gap-10 self-stretch py-1.5 pr-12 mt-4 w-full font-medium max-md:gap-4 max-md:pr-5">
                    <div className="flex flex-col flex-1">
                        <p className="text-sm leading-tight text-white max-md:text-base">I Made a Song</p>
                        <p className="mt-1 text-xs leading-snug text-zinc-300 max-md:text-sm">
                            Billed for {selectedPlan.name}
                        </p>
                    </div>
                    <div className="flex flex-1 gap-6 self-start text-base leading-snug max-md:flex-col max-md:gap-2">
                        <div className="gap-2 self-stretch px-3.5 whitespace-nowrap"></div>
                        <p className="my-auto text-right text-white max-md:text-left">Rs. {selectedPlan.monthlyPrice}</p>
                    </div>
                </div>

                <div className="flex gap-10 self-stretch py-1.5 pr-12 mt-4 w-full font-medium max-md:gap-4 max-md:pr-5">
                    <div className="flex flex-col flex-1">
                        <p className="text-sm leading-tight text-white max-md:text-base">Subtotal</p>
                    </div>
                    <div className="flex flex-1 gap-6 self-start text-base leading-snug max-md:flex-col max-md:gap-2">
                        <div className="gap-2 self-stretch px-3.5 whitespace-nowrap"></div>
                        <p className="my-auto text-right text-white max-md:text-left">  Rs. {selectedPlan.monthlyPrice}</p>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default SubscriptionDetails;

{/* <div className="flex flex-col mt-4 max-w-full font-medium leading-snug w-[416px]">
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/1ace08d81d1886966a1ba1a553e5525642703b4e277ab9ae4e132f87504cba7c?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&" alt="" className="object-contain z-10 w-full aspect-[500] stroke-[1px] stroke-zinc-700" />
    <div className="flex gap-5 justify-between mt-6">
        <button className="text-sm text-teal-300">Add Promotionla Code</button>
    </div>
</div> */}