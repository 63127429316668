import React from 'react';

function AlertFail() {
    return (
        <section data-layername="alert01" className="flex pr-2.5 max-w-[545px] small-model-margin">
            <div className="flex flex-col grow shrink-0 justify-center items-center px-10 py-9 mr-0 bg-white rounded-lg border border-solid shadow basis-0 border-zinc-200 w-fit max-md:px-5 max-md:max-w-full">
                <div className="flex gap-6 items-start max-md:max-w-full">
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/65ee49129876bcb8e994922c3d7aaf42f46ee4dafec4cda03da17e6a9d9c7a0c?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&" alt="" className="object-contain shrink-0 w-14 aspect-square" />
                    <div className="flex flex-col min-w-[240px] w-[385px]">
                        <h2 className="text-xl font-semibold text-black">
                            Payment Failure!
                        </h2>
                        <p className="mt-2 text-sm leading-loose text-black text-opacity-60">
                            Opps.. something went wrong, Please retry once
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AlertFail;