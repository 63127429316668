import React from "react";

function CookiePolicy() {
    return (
        <div className="min-h-screen mt-8 bg-black p-4 sm:p-8">
            <div className="max-w-8xl mx-auto rounded-lg p-6">
                <h1 className="text-3xl font-bold text-white mb-4">Cookie Policy</h1>
                <p className="text-gray-200 mb-6">
                    Welcome to <strong>imadeasong.com</strong>, powered by <strong>Digikore Studio Ltd</strong>.
                    This Cookie Policy explains how we use cookies on our website.
                </p>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold text-white">How We Use Cookies</h2>
                    <p className="text-gray-200 mt-2">
                        We use cookies to enhance your experience on our site. Cookies help us provide essential
                        services such as creating MP3/MP4 songs and managing subscription packages. We recommend
                        leaving all cookies enabled to enjoy the full functionality of our services.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold text-white">Third-Party Cookies</h2>
                    <p className="text-gray-200 mt-2">
                        We use cookies from third-party providers to enhance our services, such as analytics and
                        advertisements. These providers include Google Analytics, Facebook Pixel, and LinkedIn
                        Ads. These cookies allow us to analyze site performance and tailor our marketing efforts.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold text-white">Cookie Management</h2>
                    <p className="text-gray-200 mt-2">
                        Most browsers allow you to manage cookies through their settings. You can disable cookies
                        or delete existing ones. Visit the help section of your browser to learn more. Please
                        note that disabling cookies may limit the functionality of our website.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold text-white mb-2">Detailed Cookie List</h2>
                    <table className="w-full border border-bg-black text-white">
                        <thead>
                            <tr className="bg-black">
                                <th className="p-2 text-left">Cookie Name</th>
                                <th className="p-2 text-left">Purpose</th>
                                <th className="p-2 text-left">Expiration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="p-2 border-t border-gray-300">session_id</td>
                                <td className="p-2 border-t border-gray-300">Maintains user session</td>
                                <td className="p-2 border-t border-gray-300">30 minutes</td>
                            </tr>
                            <tr>
                                <td className="p-2 border-t border-gray-300">analytics_id</td>
                                <td className="p-2 border-t border-gray-300">Tracks user interactions</td>
                                <td className="p-2 border-t border-gray-300">1 year</td>
                            </tr>
                            <tr>
                                <td className="p-2 border-t border-gray-300">ad_pref</td>
                                <td className="p-2 border-t border-gray-300">Stores ad preferences</td>
                                <td className="p-2 border-t border-gray-300">6 months</td>
                            </tr>
                        </tbody>
                    </table>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold text-white">Contact Us</h2>
                    <p className="text-gray-200 mt-2">
                        If you have any questions about our Cookie Policy, please contact us at:{" "}
                        <a href="mailto:support@imadeasong.com" className="text-blue-600 underline">
                            support@imadeasong.com
                        </a>
                    </p>
                </section>

                {/* <footer className="mt-8 text-sm text-gray-200">
                    <p>© {new Date().getFullYear()} imadeasong.com. Powered by Digikore Studio Pvt Ltd.</p>
                </footer> */}
            </div>
        </div>
    );
}

export default CookiePolicy;
