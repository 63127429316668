import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ActionButton from "./ActionButton";

const faqDataSet1 = [
  {
    question: "What is 'I Made a Song'?",
    answer:
      "I Made a Song is a platform that lets you generate unique songs based on custom prompts. You can choose the genre, mood, emotion and  occasion, and the platform will create a song for you. Whether you're an amateur or a professional, we help you bring your musical ideas to life.",
  },
  {
    question: "How do I generate a song?",
    answer:
      "After logging in, you can select the type of song you want to generate by specifying details such as the genre, mood, emotion and occasion. The platform will then create a song based on your preferences and automatically assign it a title. Once completed, the song will be saved directly to your song library.",
  },
  {
    question: "What types of prompts do you offer?",
    answer:
      "We offer a wide range of prompts across various genres, moods, and emotion and occasion. You can choose prompts based on specific musical styles like pop, peppy, bollywood pop or more personalised themes such as express love, birthday.",
  },
  {
    question: "Do I need to be a professional musician to use your service?",
    answer:
      "No, you don't need to be a professional musician. 'I Made a Song' is designed for users of all experience levels. Whether you're just getting started or you're an experienced musician, our platform is here to help you create great songs.",
  },
  {
    question: "How do I access the song prompts?",
    answer:
      "After signing up, you can log in to your account and navigate to the dashboard. There, you will find selection chips that allow you to choose your desired genre, mood, emotion, and occasion. Based on your preferences, the platform will generate a personalized song for you."
  },
  {
    question: "What is the song library, and can I modify my generated songs?",
    answer:
      "The song library is where all the songs you've generated are stored, allowing you to access, listen to them. Each song is titled and linked to its respective prompt. While you can’t directly modify a generated song, you can create new songs with updated prompts if you'd like a different version.",
  },
  {
    question: "Do you offer a free trial?",
    answer:
      "Yes! We offer a free trial period where you can explore our platform and generate songs. In the free version, songs will play with a watermark. To enjoy the full experience without watermarks and unlock other premium features, you'll need a subscription."
  },
];

const faqDataSet2 = [
  {
    question: "How does the subscription work?",
    answer:
      "With a subscription, you can play songs without a watermark and enjoy the complete track. The subscription also provides more song generation credits, higher-quality audio, and access to premium features. You can choose from flexible monthly billing plans."
  },
  {
    question: "What happens once my free trial ends?",
    answer:
      "Once the free trial ends, users will need to apply for a subscription to continue generating and downloading songs. Multiple subscription plans are available, and you can find detailed information about these plans on the Upgrade Option or Pricing page. Choose the plan that best suits your needs.",
  },
  {
    question: "Can I get more credits for song generation?",
    answer:
      "Yes,a subscription plan provides you with more credits to generate songs. If you run out of credits, you can upgrade to a higher plan, depending on your selected option.",
  },
  {
    question: "How can I download songs from my library?",
    answer:
      "If you have a subscription, you can download all the songs generated according to your plan by clicking the download button in your Library."
  },
  {
    question: "How do I upgrade my account?",
    answer:
      "After using your 4 free credits, you will see an Upgrade button in your Dashboard. Clicking it will navigate you to the subscription page, where you can select a plan that fits your needs. Once upgraded, you can continue creating and downloading songs with the credits included in your plan.",
  },

  {
    question: "What features do the subscription plans offer?",
    answer:
      "Song Generation: Generate songs based on your custom prompts.\nSong Downloads: Download songs within the plan limits.\nNon-Commercial Use: All plans are for personal use only.\nValidity Period: Plans are valid for 1 to 6 months.\nOne-Time Payment: Pay once for the selected plan period; no recurring charges.",
  },
  {
    question: "How do I cancel my subscription?",
    answer:
      "Please refer to our cancellation policy for detailed information regarding subscription cancellations. If you have any further questions or need assistance, feel free to reach out to our support team.",
  },
];

function FaqSection() {
  const [faqData, setFaqData] = useState(faqDataSet1);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const handleFaqChange = (newFaqData) => {
    setFaqData(newFaqData);
    setActiveIndex(0); // Automatically expand the first question
  };
  return (
    <main className="flex flex-col  py-8 px-8  lg:m-38 md:m-16">
      <section className="w-full max-md:max-w-full">
        <div className="flex gap-4 max-md:flex-col">
          <div className="flex flex-col w-2/5 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col w-full  max-md:mt-4 max-md:max-w-full">
              <header className="flex flex-col max-w-full max-md:h-[120px] h-[150px] w-[393px]">
                <h1 className="text-5xl font-extrabold text-white leading-[65px] max-lg:text-4xl max-md:text-3xl max-md:leading-[50px]">
                  Frequently <br /> asked questions
                </h1>
                <div className="flex gap-3 mt-6 w-20 rounded-none max-md:mt-4">
                  <div className="flex shrink-0 w-3 h-3 rounded-full custom-button" />
                  <div className="flex shrink-0 h-3 rounded-2xl w-[71px] custom-button" />
                </div>
              </header>
              <ActionButton
                text="General FAQs"
                isActive={faqData === faqDataSet1 ? true : false}
                imageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/04be275465418e18453028078795451a0e670c507b3523ab26f865eda85492ec"
                onClick={() => handleFaqChange(faqDataSet1)}
              />
              <ActionButton
                text="Credits & Plans"
                isActive={faqData === faqDataSet2 ? true : false}
                imageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/d10b767664096ca2ca010b5e853aef67c59c6d374a471fa0316493e4b2d59078"
                onClick={() => handleFaqChange(faqDataSet2)}
              />
              <ActionButton
                text="Contact Us"
                isActive={false}
                imageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/d10b767664096ca2ca010b5e853aef67c59c6d374a471fa0316493e4b2d59078"
                onClick={() => navigate("/contact")}
              />
            </div>
          </div>
          <div className="flex flex-col ml-5 w-3/5 max-md:ml-0 max-md:w-full ">
            <div className="flex flex-col w-full text-2xl text-white max-md:mt-8 max-md:max-w-full ">
              {faqData.map((item, index) => (
                <div
                  key={index}
                  className="border border-gray-300 rounded-lg mb-10 mlx-2"
                >
                  <div
                    className="flex justify-between items-center p-4 cursor-pointer"
                    onClick={() => toggleAnswer(index)}
                  >
                    <h3 className="font-medium text-lg ">{item.question}</h3>
                    <svg
                      className={`w-6 h-6 transition-transform duration-300 ${activeIndex === index ? "rotate-180 " : ""
                        }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                  {activeIndex === index && (
                    <div className="p-4 text-black text-lg custom-button">
                     {item.answer.split("\n").map((line, i) => (
            <p key={i}>{line}</p>
          ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default FaqSection;
