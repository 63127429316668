import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import '../styles/cookieBanner.css';

const CookieBanner = () => {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Check if cookies consent was already given
        const cookiesAccepted = localStorage.getItem("cookiesAccepted");
        if (!cookiesAccepted) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem("cookiesAccepted", "true");
        setIsVisible(false);
    };

    const handleCustomize = () => {
        alert("Customize cookies functionality can be added here!");
    };

    if (!isVisible) return null;

    return (
        <div className="cookie-banner">
            <p className="cookie-text">
                We use cookies to enhance your browsing experience, personalize content, and analyze site traffic. By clicking ‘Accept All Cookies,’ you agree to our use of cookies. You can also customize your preferences for a more tailored experience.
            </p>
            <div className="button-container">
                <button className="cookie-button" onClick={handleAccept}>
                    Accept All Cookies
                </button>
                <button className="cookie-button" style={{ backgroundColor: "#000000c7" }} onClick={() => navigate("/cookie-policy")}>
                    Cookie Policy
                </button>
            </div>
        </div>
    );
};

export default CookieBanner;
