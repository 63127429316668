import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SongList from "./SongList";

import { FaInfoCircle } from "react-icons/fa";
import HowToUsePlatform from "../../components/HowToUsePlatform";
import LoaderTwo from "../../components/LoaderTwo";
import {
  deductCreditBalance,
  fetchCreditBalance,
} from "../../redux/ReduxSlices/creditSlice";
import { fetchVoices } from "../../redux/ReduxSlices/voicesSlice";
import apiUrl from "../../services/api";
import CustomePage from "./CustomePage";
import NeedHelp from "./NeedHelp";
import { uploadAudios } from "../../redux/ReduxSlices/uploadAudioSlice.jsx";
import CustomAI from "../custom/CustomAI.jsx";

function GetStartedPage() {
  const [prompt, setPrompt] = useState("");
  const [makeInstrumental, setMakeInstrumental] = useState(false);
  const [waitAudio, setWaitAudio] = useState(true);
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showGeneratedSongs, setShowGeneratedSongs] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showCreateSongUI, setShowCreateSongUI] = useState(true);
  const [showPersonUI, setShowPersonUI] = useState(false);
  const [showOccassion, setShowOccassion] = useState(false);
  const [showMoods, setShowMoods] = useState(false);
  const [showVacation, setShowVacation] = useState(false);
  const [dataFromPerson, setDataFromPerson] = useState([]);
  const [dataFromMood, setDataFromMood] = useState([]);
  const [dataFromOccassion, setDataFromOccassion] = useState([]);
  const [dataFromVacation, setDatafromVacation] = useState([]);
  const [showCustom, setShowCustom] = useState(false);
  const [dataFromCustom, setDataFromCustom] = useState([])

  const [showOpenAi, setShowOpenAi] = useState(false)

  const { balance } = useSelector((state) => state.credits);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (balance.credit_point <= 9) {
      return toast.error("You have insufficient credits!");
    }

    if (!prompt.trim()) {
      setErrorMessage("Please enter a prompt to generate a song.");
      return;
    }

    setErrorMessage("");

    const payload = {
      prompt,
      make_instrumental: makeInstrumental,
      wait_audio: waitAudio,
    };

    setLoading(true);
    setResponseMessage("");
    setSongs([]);
    setShowGeneratedSongs(false);

    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(apiUrl.generate, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setResponseMessage(
        response.data.message || "Song generated successfully!"
      );
      setSongs(response.data || []);
      setShowGeneratedSongs(true);
      // dispatchi audio to the fast api
      const audioPayload = response.data;
      await dispatch(uploadAudios(audioPayload));
      // Deduct 5 credits and refresh balance after a successful response
      await dispatch(deductCreditBalance(10));
      dispatch(fetchCreditBalance());
    } catch (error) {
      setResponseMessage("Failed to generate song. Please try again.");
      setSongs([]);
      setShowGeneratedSongs(false);
    } finally {
      setLoading(false);
    }
  };

  const handleInfoClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenAIModel = () => {
    setShowOpenAi(true)
  }
  const handleOpenAIClose = () => {
    setShowOpenAi(false)
  }

  const handleNeedHelpClick = () => {
    setShowHelp(!showHelp);
  };

  const handlePersonClick = () => {
    setShowCreateSongUI(false); // Hide GetStarted UI
    setShowHelp(false); // Hide NeedHelp UI
    setShowOccassion(false);
    setShowMoods(false);
    setShowVacation(false);
    setShowPersonUI(true); // Show Person UI
  };

  const handleOccassionClick = () => {
    setShowCreateSongUI(false);
    setShowHelp(false);
    setShowPersonUI(false);
    setShowMoods(false);
    setShowVacation(false);
    setShowOccassion(true);
  };

  const handleMoodClick = () => {
    setShowCreateSongUI(false);
    setShowHelp(false);
    setShowPersonUI(false);
    setShowOccassion(false);
    setShowVacation(false);
    setShowMoods(true);
  };

  const handleVacationClick = () => {
    setShowCreateSongUI(false);
    setShowHelp(false);
    setShowPersonUI(false);
    setShowOccassion(false);
    setShowVacation(false);
    setShowVacation(true);
  };

  const handleBack = () => {
    setShowCreateSongUI(true);
    setShowHelp(false);
    setShowPersonUI(false);
    setShowOccassion(false);
    setShowVacation(false);
    setShowMoods(false);
  };

  const handledataFromPerson = (data) => {
    setDataFromPerson(data);
    setLoading(false);
  };

  const handledataFromMood = (data) => {
    setDataFromMood(data);
  };
  const handledataFromOccassion = (data) => {
    setDataFromOccassion(data);
  };
  const handledataFromVacation = (data) => {
    setDatafromVacation(data);
  };

  const handleCustomData = (data) => {
    setDataFromCustom(data)
  }

  const handleGenerate = (generatedText) => {
    setPrompt(generatedText);
  };

  const getSongsData = () => {
    if (songs && songs.length > 0) {
      return songs;
    } else if (dataFromPerson && dataFromPerson.length > 0) {
      return dataFromPerson;
    } else if (dataFromMood && dataFromMood.length > 0) {
      return dataFromMood;
    } else if (dataFromOccassion && dataFromOccassion.length > 0) {
      return dataFromOccassion;
    } else if (dataFromVacation && dataFromVacation.length > 0) {
      return dataFromVacation;
    } else if (dataFromCustom && dataFromCustom.length > 0) {
      return dataFromCustom;
    } else {
      return [];
    }
  };

  useEffect(() => {
    dispatch(fetchVoices());
  }, [
    dispatch,
    songs,
    dataFromPerson,
    dataFromMood,
    dataFromOccassion,
    dataFromVacation,
    dataFromCustom,
  ]);

  const handleCustom = () => {
    setShowCreateSongUI(false);
    setShowCustom(true);
  };
  return (
    <div className="flex w-full min-h-screen">
      {/* Left side - GetStartedPage (30% width) */}
      <div className="w-[30%] p-4 shadow-md">
        {showCreateSongUI && (
          <div className="flex overflow-hidden flex-col grow px-2 pt-6 pb-2 w-full bg-white bg-opacity-10 min-h-[885px] max-md:mt-5">
            <div className="flex overflow-hidden flex-col px-2 pb-70 w-full max-md:pb-24">
              <div className="flex gap-10 items-center py-2 pr-4 pl-2 w-full rounded-lg bg-white bg-opacity-10 max-md:mr-1">
                <label
                  htmlFor="createMelody"
                  className="self-stretch my-auto text-sm font-medium leading-4 text-gray-300"
                  onClick={handleCustom}
                >
                  Create a melody
                </label>
                <div className="flex items-center  bg-slate-200 bg-opacity-45 rounded-full text-sm font-medium ">

                  <p className="text-white px-3" onClick={handleOpenAIModel}> Generate Lyric with AI
                  </p><div className="" />
                </div>
              </div>
              <label
                htmlFor="promptInput"
                className="flex gap-1 items-center self-start px-2 mt-4 text-sm font-medium leading-none text-gray-300"
              >
                Enter Prompt
                <FaInfoCircle
                  className="cursor-pointer"
                  size={20}
                  onClick={handleInfoClick}
                />{" "}
              </label>
              <textarea
                id="promptInput"
                className="mt-2 text-lg text-start text-gray-400 p-2 rounded-md border border-solid bg-white bg-opacity-10 border-gray-500 border-opacity-20"
                placeholder="Enter your prompt here"
                rows={10}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
              <div className="flex gap-20 items-center py-2 pr-2 pl-2 mt-4 w-full rounded-lg bg-white bg-opacity-10 max-md:mr-1">
                <div className="flex items-center self-stretch p-0.5 my-auto w-9 rounded-full shadow-sm bg-neutral-200 min-h-[20px]">
                  <div className="flex self-stretch my-auto w-4 h-4 bg-white rounded-full shadow-lg min-h-[16px]" />
                </div>
                <label
                  htmlFor="instrumental"
                  className="flex gap-1 items-center self-stretch px-2 my-auto text-sm font-medium leading-4 text-gray-300 whitespace-nowrap"
                >
                  Instrumental
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/f0059d0c36d04f0996a171649fe372be/4c3013cf77e7bec047439c1dbceaef534f9379925c6fc7c121bda79bba64b411?apiKey=f0059d0c36d04f0996a171649fe372be&"
                    alt=""
                    className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
                  />
                </label>
              </div>
              <button
                className="flex gap-1 justify-center items-center px-12 py-3 mt-4 text-base font-medium text-center bg-teal-300 rounded-lg text-neutral-900 max-md:px-5 max-md:mr-1"
                onClick={handleSubmit}
              >
                Generate Music
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/f0059d0c36d04f0996a171649fe372be/7e1a71233245e3ab331aadec5d950bfc2ae46f22e53a18f8d0b6a4cf4433e5bb?apiKey=f0059d0c36d04f0996a171649fe372be&"
                  alt=""
                  className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
                />
              </button>

              <button
                className="flex gap-1 justify-center items-center px-12 py-3 mt-4 text-base font-medium text-center bg-teal-300 rounded-lg text-neutral-900 max-md:px-5 max-md:mr-1"
                onClick={handleNeedHelpClick}
              >
                Need Ideas
              </button>

            </div>
          </div>
        )}
        {/* Render Custom song component */}
        {showCustom && <CustomePage handleBack={handleBack} sendDataToParent={handleCustomData} />}
      </div>

      {/* {loading && (
      <img src={"https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="MusicImage" className="h-48 w-full" />
    )} */}

      {/* Right side - SongList (70% width) */}
      <div className="w-[70%] p-4">
        <div className="text-white">{loading && <LoaderTwo />}</div>

        {showGeneratedSongs ? (
          loading ? (
            <div>Loading...</div>
          ) : (
            <div>
              {/* You can replace the img source URL with your desired one */}
              <img
                src="https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="MusicImage"
                className="h-48 w-full radius-4 opacity-4"
              />
            </div>
          )
        ) : null}

        <SongList
          songs={getSongsData()}
          showGeneratedSongs={showGeneratedSongs}
          loading={loading}
        />
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-60 z-50 flex justify-center items-center text-black shadow-lg">
          <div className="rounded-lg p-6 w-1/2 overflow-auto bg-white">
            <HowToUsePlatform onClose={closeModal} />
          </div>
        </div>
      )}

      {
        showOpenAi && (
          <div className="fixed inset-0 bg-gray-700 bg-opacity-60 z-50 flex justify-center items-center text-black shadow-lg">
            <div className="rounded-lg p-6 w-1/2 overflow-auto bg-white">
              <CustomAI onClose={handleOpenAIClose} onGenerate={handleGenerate} />
            </div>
          </div>
        )
      }
    </div>
  );
}

export default GetStartedPage;
