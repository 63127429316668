import React, { useEffect, useState } from "react";
import { GrCopy } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { generateLyrics, loadingForGenerateLyrics } from "../../redux/ReduxSlices/lyricsSlice";

const LyricsGenerator = () => {
  const [sunoLyrics, setSunoLyrics] = useState("");

  const dispatch = useDispatch();
  const isLoading = useSelector(loadingForGenerateLyrics);
  const { lyrics } = useSelector((state) => state.lyrics);
  console.log(lyrics, "lyrics")
  const [sunoGenerated, setSunoGenerated] = useState(lyrics);
  console.log(sunoGenerated, "sunoGenerated")

  useEffect(() => {
    if (lyrics) {
      setSunoGenerated(` ${lyrics}`);
    }
  }, [lyrics]);
  const handleSunoSubmit = async () => {
    if (sunoLyrics.trim() === "") {
      toast.error("Prompt required.");
      return;
    }
    try {
      await dispatch(generateLyrics(sunoLyrics));
    } catch (error) {
      console.error("Error generating lyrics:", error);
    }
  }

  const copyToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast.success("Copied to clipboard!");
  };

  return (


    <div className="min-h-screen flex items-center justify-center p-4">
      <div className="flex flex-col md:flex-row w-full max-w-5xl gap-6 md:gap-36">
        {/* Main Content */}
        <div className="flex-1 p-6 bg-slate-500 bg-opacity-15 rounded-lg shadow-lg relative">
          <h2
            className="text-4xl font-semibold text-center mb-6"
            style={{
              background: "linear-gradient(90deg, #a6a8a4, #b2e668, #52d6cf, #46cfb2, #398b87)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Generate Lyrics with Suno
          </h2>

          <textarea
            className="w-full p-4 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            placeholder="Enter lyrics prompt for Suno..."
            value={sunoLyrics}
            rows={6}
            onChange={(e) => setSunoLyrics(e.target.value)}
          />

          <div className="flex justify-center mb-4">
            <button
              className="w-[150px] p-3 custom-button text-white rounded-lg font-bold hover:bg-teal-600 transition duration-300"
              onClick={handleSunoSubmit}
            >
              {isLoading ? "Generating..." : "Generate Lyrics"}
            </button>
          </div>

          <textarea
            className="w-full p-4 mb-4 border border-gray-300 rounded-lg bg-gray-100 text-gray-600"
            value={sunoGenerated}
            readOnly
            rows={6}
          />

          <button
            className="p-3 rounded-full text-2xl text-gray-600 hover:bg-gray-400 transition duration-300 absolute right-10 mt-2"
            onClick={() => copyToClipboard(sunoGenerated)}
          >
            <GrCopy />
          </button>
        </div>

        {/* Info Box */}
        <div className="w-full md:w-1/3 p-6 bg-gray-200 rounded-lg shadow-lg h-auto md:h-[50vh]">
          <h3 className="text-2xl font-bold mb-4 text-center">How to Use</h3>
          <div className="text-black space-y-4">
            <p><span className="font-bold">Step 1:</span> Enter your prompt in the text box provided.</p>
            <p><span className="font-bold">Step 2:</span> Click the <span className="italic">"Generate Lyrics"</span> button to create lyrics based on your prompt.</p>
            <p><span className="font-bold">Step 3:</span> Wait while the lyrics are being generated.</p>
            <p><span className="font-bold">Step 4:</span> Once the lyrics are generated, they will appear in the text box below.</p>
            <p><span className="font-bold">Step 5:</span> Click the copy button to save the generated lyrics to your clipboard.</p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>


  );
};

export default LyricsGenerator;
