const moodOptions = {
  emotional:"Emotional",
  romantic: "Romantic",
  relaxed: "Relaxed",
  joyful: "Joyful",
  sentimental: "Sentimental",
  heartfelt: "Heartfelt",
  thankful: "Thankful",
  grateful: "Grateful",
  // uplifting: "Uplifting",

  // melancholic: "Melancholic",
  // energetic: "Energetic",
  // hopeful: "Hopeful",
  // mysterious: "Mysterious",
  // dark: "Dark",
  // nostalgic: "Nostalgic",
  // dramatic: "Dramatic",
  // suspenseful: "Suspenseful",
  // playful: "Playful",
  // peaceful: "Peaceful",
  // epic: "Epic",
  // adventurous: "Adventurous",
  // reflective: "Reflective",
  // optimistic: "Optimistic",
};

export const getMoodOptions = () => {
  return Object.entries(moodOptions).map(([key, value]) => ({
    key,
    value,
  }));
};
