

import axios from "axios";
import React, { useState } from "react";
import SongList from "./SongList";

function CustomePage({ handleBack, sendDataToParent }) {
  const [lyrics, setLyrics] = useState("");
  const [styleInput, setStyleInput] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [songs, setSongs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [chatGptResponse, setChatGptResponse] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const styles = ["bandroom", "medieval", "teen pop", "castle rock"];
  const sampleLyrics = [
    "[Verse]\nI found a penny at the candy store\nBought a world made of bubblegum lore\nSpinning tops and sparkling lights\nIn a land where soda pop fights",
    "[Verse 2]\nJumped on a cloud of lemon fizz\nMelted into a citrus bliss\nFloated through a cola stream\nLife is sweeter in a dream",
    "[Chorus]\nSoda pop dreams in a candy sky\nSugar rush feels make me fly\nGummies dance in licorice lanes\nIn this world\nThere are no pains",
    "[Verse 3]\nPeppermint trees and chocolate roads\nJellybeans in every code\nFizzy sherbet skies\nIce cream suns\nChasing joy where no one runs",
    "[Bridge]\nGolden tickets hold the key\nTo a realm of fizzy glee\nSkittles rain and laughing sprites\nMaking wishes under gumdrop nights",
    "[Chorus]\nSoda pop dreams in a candy sky\nSugar rush feels make me fly\nGummies dance in licorice lanes\nIn this world\nThere are no pains",
  ];

  const handleLyricsChange = (e) => {
    setLyrics(e.target.value);
  };

  const handleTagClick = (style) => {
    setStyleInput((prev) => (prev ? `${prev}, ${style}` : style));
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleSurpriseClick = () => {
    const combinedLyrics = sampleLyrics.join("\n\n");
    setLyrics(combinedLyrics);
  };

  const handleClear = () => {
    setLyrics("");
    setStyleInput("");
    setTitle("");
  };

  const handleCreate = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/api/custom_generate",
        {
          prompt: lyrics,
          tags: styleInput,
          negative_tags: "female edm techno",
          title: title || "Untitled",
          make_instrumental: false,
          model: "chirp-v3-5|chirp-v3-0",
          wait_audio: false,
        },
        {
          withCredentials: true,
          credentials: "include",
        });

      // setSongs(response.data);
      sendDataToParent(response.data)
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setUserInput("");
    setChatGptResponse("");
  };
  const handleSendMessageToOpenAI = async (message) => {

    const gptMagicKey = process.env.REACT_APP_OPENAI_API_KEY;
    const apiKey = gptMagicKey;

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          // model: 'gpt-3.5-turbo',
          model: 'gpt-4-turbo',
          messages: [{ role: 'user', content: message }],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      return response.data.choices[0].message.content;
    } catch (error) {
      console.error('Error communicating with OpenAI:', error);
      return 'Our system encountered an unexpected error while generating your music. We are on it!';
    }
  };
  const handleSendMessage = async () => {
    if (input.trim() === '') return;

    setIsLoading(true);

    try {
      const openAIResponse = await handleSendMessageToOpenAI(input);
      setChatGptResponse(openAIResponse);

      // Update the lyrics state with the ChatGPT response
      setLyrics(openAIResponse); // This will update the textarea's value
    } catch (error) {
      console.error(error);
      setChatGptResponse('An error occurred while generating the response.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-start min-h-screen text-white ">
      <button className="upgrade-btn" onClick={handleBack}>
        Back
      </button>
      <div className="w-full max-w-xl p-2 rounded-lg shadow-md " >
        <div className="flex justify-between items-center mb-4">
          <span className="text-2xl font-semibold text-center">Lyrics</span>
        </div>

        {/* Modal for ChatGPT */}

        {/* Existing code for input and buttons */}
        <div className="relative mb-4">
          <textarea
            placeholder="Enter your own lyrics or describe a song and click Write About..."
            className="w-full p-4 text-black rounded-lg"
            maxLength="3000"
            value={lyrics}
            rows={10}
            onChange={handleLyricsChange}
          />
          <div className="absolute bottom-2 right-3 text-gray-400 text-sm">
            {lyrics.length} / 3000
          </div>
        </div>

        <div className="flex justify-between items-center text-gray-400 text-sm mb-4">
          <button
            onClick={handleSurpriseClick}
            className="py-1 px-3 border border-white bg-gray-700 rounded-full"
          >
            🎲 Surprise Me
          </button>

          <button
            onClick={handleModalOpen}
            className="py-1 px-3 border border-white bg-gray-700 rounded-full"
          >
            📝 Write Lyric with OpenAI
          </button>
        </div>

        {/* Existing code for styles and other components */}
        <div className="flex justify-between items-center mb-4">
          <span className="text-lg font-semibold">Style of Music</span>
        </div>
        <input
          type="text"
          placeholder="Enter style of music"
          className="w-full h-16 p-2 text-black rounded-lg mb-4"
          value={styleInput}
          onChange={(e) => setStyleInput(e.target.value)}
        />

        <div className="flex space-x-2 overflow-x-auto scrollbar-hide mb-4">
          {styles.map((style, index) => (
            <span
              key={index}
              onClick={() => handleTagClick(style)}
              className="cursor-pointer px-3 py-1 bg-gray-700 rounded-full text-sm hover:bg-gray-600 transition-colors"
            >
              {style}
            </span>
          ))}
        </div>

        <div className="mb-4">
          <input
            type="text"
            placeholder="Title"
            className="w-full p-2 text-black rounded-lg h-20"
            maxLength="80"
            value={title}
            onChange={handleTitleChange}
          />
          <div className="text-right text-gray-400 text-sm mt-1">
            {title.length} / 80
          </div>
        </div>

        <div className="flex justify-between items-center mt-6">
          <button
            onClick={handleClear}
            className="py-2 px-6 bg-gray-700 rounded-full text-white font-semibold"
          >
            Clear
          </button>
          <button
            onClick={handleCreate}
            disabled={loading}
            className={`py-2 px-6 w-1/2 ${loading
              ? "bg-gray-500"
              : "bg-teal-300"
              } rounded-full text-black bg-teal-300`}
          >
            {loading ? "Creating..." : " Create  🎶 "}
          </button>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
            <div className="bg-gray-400 text-black p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-lg font-bold mb-4">
                Write Lyric with OpenAI
              </h2>
              <textarea
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message here..."
                style={{ width: '100%', padding: '10px', marginRight: '10px', height: '200px' }}
                className="border border-teal-500 rounded-md"
              />
              <button
                onClick={handleSendMessage}
                style={{
                  padding: '10px 20px',

                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                className="bg-teal-300 border   text-black font-medium"
              >
                {isLoading ? 'AI is typing...' : 'Send'}
              </button>

              <button
                onClick={handleModalClose}
                className="mt-4  ml-10 py-2 px-3 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomePage;








