import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrl from "../../services/api";

// Async thunk for the API call
export const generateSlideshow = createAsyncThunk(
  "slideshow/generateSlideshow",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.setItem('token')
      const response = await axios.post(apiUrl.generateSlideShow, formData, {
        headers: { 
          Authorization: `Bearer ${token}`,
         },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

export const downloadVideo = createAsyncThunk(
  "slideshow/downloadVideo",
  async (payload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(apiUrl.downloadSlideShow, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data; // Return the API response data
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred while downloading the video."
      );
    }
  }
);



const slideshowSlice = createSlice({
  name: "slideshow",
  initialState: {
    data: null,
    videoData: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateSlideshow.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(generateSlideshow.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(generateSlideshow.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(downloadVideo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(downloadVideo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.videoData = action.payload;
      })
      .addCase(downloadVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default slideshowSlice.reducer;
