import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrl from "../../services/api";

// Define initial state
const initialState = {
  songData: null,
  loading: false,
  error: null,
};

export const customCreateSong = createAsyncThunk(
  "song/createSong",
  async (payload, { rejectWithValue }) => {

    const token = localStorage.getItem("token");
    // var gender = localStorage.getItem("selectedGender");

    try {
      const response = await axios.post(
        apiUrl.generateCustomSong,

        // "/api/custom_generate",
        {
          prompt: payload.textValue,
          tags: payload.tags || " ",
          negative_tags: payload.negativeTags || "",
          title: payload.titleValue,
          make_instrumental: payload.makeInstrumental || false,
          model: payload.model || "chirp-v3-5|chirp-v3-0",
          wait_audio: payload.waitAudio || true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      localStorage.setItem("generatedSongdata", JSON.stringify(response.data.data));

      // localStorage.removeItem("selectedGender");

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Create the slice
const CustomsongSlice = createSlice({
  name: "song",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(customCreateSong.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(customCreateSong.fulfilled, (state, action) => {
        state.loading = false;
        state.songData = action.payload;
      })
      .addCase(customCreateSong.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectLoading = (state) => state.song.loading;

// Export actions and reducer
export default CustomsongSlice.reducer;
