import React, { useEffect, useRef, useState } from "react";
import { MdOutlineInfo } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorImage from "../../assets/song/alert.webp";
import HowToUsePlatform from "../../components/HowToUsePlatform";
import { fetchCreditBalance } from "../../redux/ReduxSlices/creditSlice";
import { deductFreeTrialCount } from "../../redux/ReduxSlices/freeTrialSlice";
import { fetchOpenAIResponse } from "../../redux/ReduxSlices/openAISlice";
import { getGenderOptions } from "../../utils/gender";
import { getGenresOption } from "../../utils/genres";
import { getLanguageOptions } from "../../utils/language";
import { getMoodOptions } from "../../utils/moodOptions";
import { getOccassionOption } from "../../utils/occassion";

function CustomeAI({ onGenerate, onClose, setLoader }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectMood, setSelectMood] = useState("");
  const [selectStyle, setSelectStyle] = useState("");
  const [selectGender, setSelectGender] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedOccasion, setSelectedOccasion] = useState("");
  const [selectInstrument, setSelectInstrument] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [isAnimating, setIsAnimating] = useState(false);
  const { isLoading, error } = useSelector((state) => state.openAi);
  const { balance } = useSelector((state) => state.credits);
  const { freeTrailData } = useSelector((state) => state.freeTrial);
  const [isModalVisible, setModalVisible] = useState(false);

  const modalRef = useRef(null);
  const resetSelections = () => {
    setSelectMood("");
    setSelectGender("");
    setSelectStyle("");
    setSelectedLanguage("");
    setSelectedOccasion("");
    setSelectInstrument("");
  };

  const handleSendMessage = async (e) => {

    e.preventDefault();


    if (freeTrailData.free_trial_count === 0 && balance.credit_point === 0) {

      setTimeout(() => {
        navigate("/upgrade-plan");
      }, 2000);
      return toast.error("You need to subscribe to generate more songs!");
    }

    if (
      !selectStyle ||
      !selectedLanguage ||
      !selectedOccasion ||
      !selectMood ||
      !selectGender
    ) {
      toast.info("Please choose all fields to generate better lyrics");
      return;
    }

    const selectedValues = [
      selectedLanguage,
      selectStyle,
      selectedOccasion,
      selectMood,
      selectInstrument,
      selectGender,
    ];

    localStorage.setItem("selectedValues", JSON.stringify(selectedValues));

    let message = `Compose a ${selectStyle} song in ${selectedLanguage}, for ${selectedOccasion} occasion. Utilize ${selectGender} to evoke ${selectMood} emotions, ensuring the melody perfectly complements the essence of the occasion.`;

    try {
      setLoader(true);
      let response1 = await dispatch(fetchOpenAIResponse(message));

      let showConditionalBox = response1?.payload?.message

      setTimeout(() => {
        setLoader(false);
      }, 1000);

      if (
        // typeof showConditionalBox === "object" &&
        showConditionalBox === "No matching entries found."

      ) {
        setDialogMessage(
          "Oops... It seems that the combination didn’t generate a song. Please try a different one!"
        );
        setDialogVisible(true);

      } else if (showConditionalBox?.length > 0 && freeTrailData.free_trial_count > 0) {

        if (window.innerWidth <= 768 || /iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          setModalVisible(true); // Show the modal
        } else {
          // 
        }

        dispatch(deductFreeTrialCount());
      } else {

        if (window.innerWidth <= 768 || /iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          setModalVisible(true); // Show the modal
        } else {
          // 
        }

        dispatch(fetchCreditBalance());
      }

      onClose();
    } catch (err) {
      console.error(err);

      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
    resetSelections();
  };

  const closeDialog = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setDialogVisible(false);
      setIsAnimating(false);
    }, 300);
  };

  const handleInfoClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <>
      <div className="relative mb-2 lg:mb-4 text-center mt-4 lg:mt-0">
        <div className="flex  ">
          <h2 className="text-l lg:text-xl font-bold text-white">
            Create Your Song{" "}
          </h2>
          <h2
            className="text-white text-start text-2xl font-bold absolute right-0 top-1"
            onClick={handleInfoClick}
          >
            <MdOutlineInfo className="cursor-pointer" />{" "}
          </h2>
        </div>
        <p className="text-gray-400 mt-2 text-start text-sm">
          Customize every detail to create the perfect melody
        </p>
      </div>
      <div className="inset-0 bg-opacity-50 flex items-center justify-center">
        <div className="text-black border-6 px-2 shadow-xl w-full bg-gradient-to-br from-teal-900 via-gray-800 to-black-300 backdrop-blur-md rounded py-2 font-medium max-w-lg sm:max-w-4xl">
          <div className="space-y-3">
            <div>
              <label className="text-white block mb-1 pl-2">Occasion</label>
              <div className="flex flex-wrap gap-2 border border-[#7fd7a373] rounded-md  p-2">
                {getOccassionOption().map((option, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedOccasion(option.value)}
                    className={`py-1 px-2 rounded-md shadow-sm text-sm max-md:text-base max-md:font-semibold text-white border border-teal-800 ${selectedOccasion === option.value
                      ? "bg-teal-600 border border-none"
                      : "bg-gray-800"
                      } hover:bg-teal-500 focus:ring focus:ring-teal-600`}
                  >
                    {option.value}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <label className="text-white block mb-1 pl-2">Language</label>
              <div className="flex flex-wrap gap-2  border border-[#7fd7a373] rounded-md p-2">
                {getLanguageOptions().map((option, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedLanguage(option.value)}
                    className={`py-1 px-2 rounded-md shadow-sm text-sm max-md:text-base max-md:font-semibold text-white border border-teal-800 ${selectedLanguage === option.value
                      ? "bg-teal-600 border border-none"
                      : "bg-gray-800"
                      } hover:bg-teal-500 focus:ring focus:ring-teal-600`}
                  >
                    {option.value}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <label className="text-white block mb-1 pl-2">Music Style</label>
              <div className="flex flex-wrap gap-2 border border-[#7fd7a373] rounded-md p-2">
                {getGenresOption().map((option, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectStyle(option.value)}
                    className={`py-1 px-2 rounded-md shadow-sm text-sm max-md:text-base max-md:font-semibold text-white border border-teal-800 ${selectStyle === option.value
                      ? "bg-teal-600 border border-none"
                      : "bg-gray-800"
                      } hover:bg-teal-500 focus:ring focus:ring-teal-600`}
                  >
                    {option.value}
                  </button>
                ))}
              </div>
            </div>


            <div>
              <label className="text-white block mb-1 pl-2">Mood</label>
              <div className="flex flex-wrap gap-1 border  border-[#7fd7a373] rounded-md p-2">
                {getMoodOptions().map((option, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectMood(option.value)}
                    className={`py-1 px-2 rounded-md shadow-sm text-sm max-md:text-base max-md:font-semibold text-white border border-teal-800 ${selectMood === option.value
                      ? "bg-teal-600 border border-none"
                      : "bg-gray-800"
                      } hover:bg-teal-500 focus:ring focus:ring-teal-600`}
                  >
                    {option.value}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <label className="text-white block mb-1 pl-2">Voice</label>
              <div className="flex flex-wrap gap-2 border   border-[#7fd7a373] rounded-md p-2">
                {getGenderOptions().map((option, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectGender(option.value)}
                    className={`py-1 px-2 rounded-md shadow-sm text-sm max-md:text-base max-md:font-semibold text-white border border-teal-800 ${selectGender === option.value
                      ? "bg-teal-600 border border-none"
                      : "bg-gray-800"
                      } hover:bg-teal-500 focus:ring focus:ring-teal-600`}
                  >
                    {option.value}
                  </button>
                ))}
              </div>
            </div>
          </div>
          {/* {error && <p className="text-red-500 text-center mt-4">{error}</p>} */}
        </div>
      </div>

      <div className="relative flex justify-center w-full mt-4 lg:mt-4">
        <button
          onClick={handleSendMessage}
          className="custom-button text-black bg-teal-600 font-medium py-3 w-full px-5 rounded-md hover:bg-teal-500 shadow-lg transition duration-300"
        >
          {isLoading ? "Generating Song" : "Generate Song"}
        </button>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex justify-center items-center text-black shadow-lg ">
          <div
            className="rounded-lg p-4 model-margin sm:p-6 w-full sm:w-3/4 md:w-1/2 overflow-auto bg-black border border-gray-700"
            ref={modalRef}
          >
            <HowToUsePlatform onClose={closeModal} />
          </div>
        </div>
      )}

      {/* Dialog */}
      {dialogVisible && (
        <div className="dialog">
          <div
            className={`dialog-content ${isAnimating ? "dialog-close" : "dialog-open"
              }`}
          >
            <div className="flex justify-center">
              <img src={ErrorImage} alt="error-image" className="h-20" />
            </div>
            <p className="text-xl mt-2 font-semibold">{dialogMessage}</p>
            <button
              onClick={closeDialog}
              className="mt-8 clg-btn font-semibold cursor-pointer"
            >
              Close
            </button>
          </div>
        </div>


      )}

      {isModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <p>Your song has been successfully generated. Please scroll down.</p>
            <button onClick={() => setModalVisible(false)} className="clg-btn">Close</button>
          </div>
        </div>
      )}

      <style jsx>{`
        .modal {
          display: flex;
          align-items: center;
          justify-content: center;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          z-index: 1000;
        }
        .modal-content {
          background: #000;
          color: #fff;
          padding: 20px;
          border-radius: 6px;
          text-align: center;
        }
        .clg-btn{
          margin-top:20px;
          font-size:14px;
          background: linear-gradient(90deg, #a7e373, #57d0c1, #2c726c);
        }
      `}</style>
    </>
  );
}

export default CustomeAI;
