import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrl from "../../services/api";
import { header } from "framer-motion/client";


export const createWatermarkAudio = createAsyncThunk(
  "audio/createWatermarkAudio",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        apiUrl.watermarkAudio, 
        { id }, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob", 
        }
      );

      // Convert the blob into a playable URL
      const audioBlob = response.data;
      const audioUrl = window.URL.createObjectURL(audioBlob);

      return audioUrl; 
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error creating watermark audio"
      );
    }
  }
);

export const getStreamBlob = createAsyncThunk(
  "audio/getStreamBlob",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        apiUrl.getStreamBlob, 
        { id }, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );

      const streamBlob = response.data;
      const streamUrl = window.URL.createObjectURL(streamBlob);

      return streamUrl; // Return the stream URL
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error fetching stream blob"
      );
    }
  }
);



const audioSlice = createSlice({
  name: "audio",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    result: null,
    streamResult: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createWatermarkAudio.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createWatermarkAudio.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.result = action.payload;
      })
      .addCase(createWatermarkAudio.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
       // getStreamBlob cases
      .addCase(getStreamBlob.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getStreamBlob.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.streamResult = action.payload;
      })
      .addCase(getStreamBlob.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default audioSlice.reducer;
