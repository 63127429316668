import React from "react";
import styled, { keyframes } from "styled-components";



const bounce = keyframes`
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
`;

// Style the container div that holds the loader
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: black;
    position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8); /* Adds a background overlay */
  z-index: 9999;
`;

// Style the music loader (the flex container for the bars)
const MusicLoader = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
`;

// Style individual bars (the "divs" inside the music loader)
const Bar = styled.div`
  width: 10px;
  height: 30px;
  background: linear-gradient(180deg, #00d2ff, #3ae374);
  border-radius: 10%; /* Makes the ends of the lines rounded */
  animation: ${bounce} 1s infinite ease-in-out;

  /* Delayed animations for each bar */
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
  &:nth-child(4) {
    animation-delay: 0.6s;
  }
  &:nth-child(5) {
    animation-delay: 0.8s;
  }
  &:nth-child(6) {
    animation-delay: 1s;
  }
  &:nth-child(7) {
    animation-delay: 1.2s;
  }
`;
const PageLoader = () => {


  return (

      // <StyledWrapper>
      //    <div className="loading">
      //   <div className="d1" />
      //    <div className="d2" />
      //  </div>
      // </StyledWrapper>
      <LoaderContainer>
      <MusicLoader>
        <Bar />
        <Bar />
        <Bar />


      </MusicLoader>
    </LoaderContainer>


  );
};

// const StyledWrapper = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: rgba(0, 0, 0, 0.8); /* Optional: adds a background overlay */
//   z-index: 9999;

//   .loading {
//     width: 60px;
//     height: 60px;
//     position: relative;
//   }

//   .d1,
//   .d2 {
//     border: 5px solid #64dfdf;
//     border-radius: 50px;
//   }

//   .loading .d1 {
//     width: 55px;
//     height: 55px;
//     position: absolute;
//     top: -9px;
//     border-left-color: transparent;
//     border-right-color: transparent;
//     animation: load161 1s linear infinite;
//   }

//   .loading .d2 {
//     width: 40px;
//     height: 40px;
//     border-top-color: transparent;
//     border-bottom-color: transparent;
//     margin: 7.5px;
//     animation: load2812 2s linear infinite;
//   }

//   @keyframes load161 {
//     0% {
//       transform: rotate(0);
//     }

//     100% {
//       transform: rotate(360deg);
//     }
//   }

//   @keyframes load2812 {
//     0% {
//       transform: rotate(0);
//     }

//     100% {
//       transform: rotate(-360deg);
//     }
//   }
// `;

export default PageLoader;
