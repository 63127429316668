import React, { useEffect } from 'react';
import PaymentMethod from './PaymentMethod';
import PaymentForm from './PaymentForm';
import SubscriptionDetails from './SubscriptionDetails';

function PaymentPopup({ selectedPlan, closeModal }) {

    return (
        <section className="flex flex-col pb-2 rounded-none" aria-label="Payment Popup">
            <div className="flex flex-col pt-2 pb-16 pl-16 w-full rounded-2xl bg-neutral-800 max-md:px-5 max-md:max-w-full">
                <div className="max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col">
                        <SubscriptionDetails selectedPlan={selectedPlan} />
                        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col grow sm:mt-0 lg:mt-0">
                                <PaymentMethod />
                                <PaymentForm selectedPlan={selectedPlan} closeModalSubscription={closeModal} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PaymentPopup;